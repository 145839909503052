var Reflux = require("reflux");
var BoxLinesActions = require("./BoxLinesActions");

var data = {
    lines: [],
    selectedTransportLines: [],
    transportTypes:[
    	{ "name": "all", "short":"boxlines.all"},
        { "name": "metro", "short": "boxlines.metro" },
        { "name": "tram", "short": "boxlines.tram" },
        { "name" : "bus", "short": "boxlines.bus" }
    ],
    selectedLine: undefined,
    selectedTransport: 'all'
}

var BoxLinesStore = Reflux.createStore({
    getInitialState:function(){
        return data
    },
    init: function() {
        var scope = this;

        this.listenToMany(BoxLinesActions);
        
        $.getJSON({url:"/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build/data/lines.json",async:false},function(lines){
            data.lines = lines
            data.selectedTransportLines = lines
            scope.trigger(data);
        });
        
    },
    onSelectTransport: function(name){
        data.selectedTransport = name;

        if(name !== 'all'){
            data.selectedTransportLines = data.lines.filter(function(line,index){
                return line.type === name
            })
        } else {
            data.selectedTransportLines = data.lines
        }
        this.trigger(data);
    },
    onSelectLine: function(line){
        data.selectedLine = line;
        this.trigger(data);
    },
    onClose: function(){
        data.selectedLine = undefined;
        // data.selectedTransport = [];
        // data.selectedTransportLines = data.lines;
        this.trigger(data);
    }
});

module.exports = BoxLinesStore
