var api = {
    init: function(selector) {
        var self = this;

        this.el = $(selector);

        this.input = this.el.find('.js-clearableinput-input');
        this.clear = this.el.find('.js-clearableinput-clear');

        this.clear.hide();

        this.input.on('change keyup', $.proxy(this.manageClearVisibility, this));
        this.clear.on('click', function() {
            self.input.val('');
            self.manageClearVisibility();
            self.input.focus();
        })
    },
    manageClearVisibility: function() {
        if (this.input.val().length) {
            this.clear.show();
        } else {
            this.clear.hide();
        }
    }
};

module.exports = function(selector) {
    return Object.create(api).init(selector);
};