'use strict';

var Utils = require("../Utils");
var GetI18n = require("./I18n");

$(document).ready(function(){
	var boxlines = $('.box.box--lines.header__box')[0];
	var linkParent = $(boxlines).children('.box__content')[0];
	var link = $('<a>')
	
	link.css({
		position: 'absolute',
		top: '10px',
		right: '10px',
		color: '#fff',
		'text-decoration': 'underline'
	});
	link.attr('href','occupancy');
	link.attr('id','Occupancy-BoxLines')
	
	link.html(GetI18n('boxlines.occupancyLink'));
	$(linkParent).prepend($(link));
});

