global.jQuery =global.$ = require("jquery");
require("matchmedia-polyfill");
require("matchmedia-polyfill/matchMedia.addListener");
require("es5-shim");
//window.utils = require("./Utils");
global.GATracker = require("./general/GATracker.js");
global.OutlineOffOnClick = require("./general/OutlineOffOnClick")

var Gallery = require("./general/Gallery.js");
var FAQ = require("./general/FAQ.js");
var Lang = require("./general/Lang.js");

var Forms = require("./general/Forms.js");
var BoxLines = require("./BoxLines.jsx");

var Header = require('./partials/Header.js');
var Footer = require('./partials/Footer.js');
var ChatBot = require("./ChatBot/Chatbot.js");
var Alert = require('./partials/Alert.js');
var Search = require('./partials/Search.js');
var NotificationsBox = require('./partials/NotificationsBox.js');
var Share = require('./partials/Share.js');
//var Cookie = require('./partials/Cookie.js'); // eu cookie message
var ProductSelector = require("./ProductSelector/ProductSelector.jsx");
var TokyoSearch = require("./TokyoSearch/TokyoSearch.jsx");
var TicketsGrid = require("./TicketsGrid/TicketsGrid.jsx");
require("./partials/collapse.js");
var cookies = require('./general/cookies.js');
// Web messages
var View = require('./general/WebMessages');

// Using the html way
var View = require('./lines/View.jsx');

// Widget GIRO at the end cookies problems changing the lang
var View = require('./general/WidgetPlan.js');

// Search Autocomplete stuff
var View = require('./search/Search.jsx');
var SearchPillars = require('./search/SearchPillars.js');

// The store locator
var View = require('./general/storelocator.js');

// The Info javascript
var Info = require('./general/info.js');

// The Navigation.js javascript
var Navigation = require('./general/Navigation.js');

var BoxlinesCovid = require('./general/BoxLinesOccupancy.js');
