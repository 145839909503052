module.exports = new function Footer(){
    var self = this;
    var _toggle;
    var _optional;
    var rules_url = globalUrlPrefixUri + "";
	var prices_url = globalUrlPrefixUri + "";
	var legal_url = globalUrlPrefixUri + "";
	var privacy_url= globalUrlPrefixUri + ""; 
	var translations="{\"footer_links\":{\"en\":[{\"displayName\":\"Transport regulations\",\"href\":\"" + globalUrlPrefixUri + "rules?l=en\"},{\"displayName\":\"Prices\",\"href\":\"" + globalUrlPrefixUri + "tarif?l=en\"},{\"displayName\":\"Legal notice\",\"href\":\"" + globalUrlPrefixUri + "legal-terms.html?l=en\"},{\"displayName\":\"Privacy\",\"href\":\"" + globalUrlPrefixUri + "privacy?l=en\"}],\"fr\":[{\"displayName\":\"Règles de transport\",\"href\":\"" + globalUrlPrefixUri + "rules?l=fr\"},{\"displayName\":\"Tarifs\",\"href\":\"" + globalUrlPrefixUri + "tarif?l=fr\"},{\"displayName\":\"Mentions légales\",\"href\":\"" + globalUrlPrefixUri + "legal-terms.html?l=fr\"},{\"displayName\":\"Vie privée\",\"href\":\"" + globalUrlPrefixUri + "privacy?l=fr\"}],\"nl\":[{\"displayName\":\"Vervoersregels\",\"href\":\"" + globalUrlPrefixUri + "rules?l=nl\"},{\"displayName\":\"Tarieven\",\"href\":\"" + globalUrlPrefixUri + "tarif?l=nl\"},{\"displayName\":\"Wettelijke vermeldingen\",\"href\":\"" + globalUrlPrefixUri + "legal-terms.html?l=nl\"},{\"displayName\":\"Privacy\",\"href\":\"" + globalUrlPrefixUri + "privacy?l=nl\"}]}}";

    function _init(){
        _toggle = $(".footer__toggle a");
        _optional = $(".footer__optional");
        _delegate();
    }
    function _delegate(){
        _toggle.on("click",function(e){
            e.preventDefault();
            self.toggle();
        })
    }

    function _fixes(){
    	
		language = $("html").attr("lang");
		
    	// rename/change url of footer links
    	var footer_links = translations["footer_links"];
    	footer_links = footer_links[$("html").attr("lang").toLowerCase()];
    	
    	$("ul.footer__nav-bottom > li > a").each(function(i, obj){
    		//console.log("Current link Name:"+$(obj).text() + " will be replaced by "+footer_links[i].displayName +" | "+ "href:"+$(obj).attr("href") + " will be replaced by "+footer_links[i].href);
    		$(obj).text(footer_links[i].displayName);
    		$(obj).attr("href",footer_links[i].href);
    		
    	});
    	
    	// rename/change url of footer links	
    	if($("html").attr("lang").toLowerCase() =="nl"){
    		// Correct some typo in NL (realtime)
    		$("footer.footer--pattern > div.container > div.footer__mobile-app > div.footer__col-inner > p").text("Altijd en overal! Uw reisweg uitstippelen,uurroosters van de haltes raadplegen, de exacte locatie van de voertuigen in realtime opvragen...");	
    		$($("footer.footer--pattern > div.container > div.footer__optional > div")[2]).find("div.footer__col-inner > p").text("Realtime informatie over ons net, nieuws, evenementen... Deel het allemaal met ons op jouw favoriete netwerken!");
    		// Correct some typo		
    	}	
    	
        $("span.icon-apple").parent("a").attr("href",new_app_url_ios);
    	$("span.icon-android").parent("a").attr("href",new_app_url_android);
    	
    	// Instagram new account
		var new_instagram_url = JSON.parse('{"en":"https://www.instagram.com/mivbstib/","nl":"https://www.instagram.com/mivbstib/","fr":"https://www.instagram.com/stibmivb/"}');	
		$("span.icon-instagram").parent("a").attr("href",new_instagram_url[language]);
		
		// Stibstories new account
		if(language && language=='nl'){
			var new_stories = JSON.parse('{"nl":"https://mivbstories.be/"}');
			$("span.icon-blog").parent("a").attr("href",new_stories[language]);	
		}
		
		
		// Newsletter migration
		var new_news_transl= JSON.parse('{"en":"Subscribe here","nl":"Schrijf u in","fr":"Inscrivez-vous"}');
		
		// Final redirect article for news letter (not visible yet ==> 404 so testing with a random page)
		var new_news_url=globalUrlPrefixUri +"Newsletter.html";
		
		var $old_newsParent = $("footer input[name='email']").parent();
		$old_newsParent.hide();
		
		// hide unsubscribe
		$("footer a[name='btn_newsletter'][news_action='unsubscribe']").hide();
		
		var new_news_htmlToInject = '<a href="'+new_news_url+'" role="button" class="btn btn--primary btn--fill-width btn--icon-right">'+new_news_transl[language]+'<span class="icon-arrow" aria-hidden="true"></span></a>';
		$(new_news_htmlToInject).insertAfter($old_newsParent);
    }
    
    self.toggle = function(){
        _optional.toggleClass("footer__optional--expanded");

        $('html, body').animate({
            scrollTop: _toggle.offset().top - 75
        });
    }	
	
	translations = JSON.parse(translations);

	//Footer mobile app
	var new_app_url_android = globalUrlPrefixUri +"Android.html";
	var new_app_url_ios = globalUrlPrefixUri +"iOS.html";

    //$(document).ready(_init);
    $(document).ready(function(){
        try {
            _init();
            _fixes();
        }catch(err){
        }
    });
    
}
