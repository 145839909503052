var React = require('react')
var ReactDOM = require('react-dom')
const ReactAnim = require('react-reveal')
var Utils= require("../Utils");
var GetI18n = require ("../general/I18n")

// will act as a storage for input as the hassle of implementing redux is not worth it for a that small app
// we will still use state 
var PSContext = {
	scripts: {},
	answers: {},
	questions: {},
	products: {},
	isBackMode: false
}

var ProductSelector = React.createClass({
	getInitialState: function(){
		PSContext.scripts = this.props.data.scripts
		PSContext.questions = this.props.data.questions
		PSContext.answers = this.props.data.answers
		PSContext.products = this.props.data.products
		PSContext.maxProductPerRow = 3
		PSContext.lang = Utils.getLanguage()
		
		let startingQuestionId
		$.each(PSContext.questions, function(key, question){
		    if (question.startingQuestion){
		    	startingQuestionId = question.id
		    }
		});
		let possibleScripts = []
		let availableAnswers
		for (var script in PSContext.scripts){
			availableAnswers = PSContext.scripts[script].steps[0].availableAnswers
			break;
		}
		
		for(var script in PSContext.scripts){
			possibleScripts.push(script)
		}

		return {
			currentPath: [],
			currentStep: 0,
			possibleScripts: possibleScripts,
			questionId: startingQuestionId,
			availableAnswers: availableAnswers ? availableAnswers : []
		}
	},
	renderPreviousButton: function(){
		if(this.state.currentStep != 0){
			return <PSButton title={GetI18n('productSelector.button.back')} cb={this.backToPreviousStep} />
		}
	},
	handleClick: function(answerId){
		this.updateCurrentStep(answerId)		
	},
	backToPreviousStep: function(){
		this.updateCurrentStep(null,true);
	},
	updateCurrentStep: function(answerId, back){
		let currentPath = []
		
		for(let c = 0; c < this.state.currentPath.length; c++){
			currentPath.push(this.state.currentPath[c])
		}

		let newPossibleScripts = []
		let newCurrentStep 
		let nextStep
		let toProducts
		if (!back){
			currentPath.push(answerId);
			newCurrentStep = this.state.currentStep + 1
			
			newPossibleScripts = filteringPossibleScripts(this.state.possibleScripts, currentPath)					
				
			if(newPossibleScripts.length == 1 && PSContext.scripts[newPossibleScripts[0]].path.length === currentPath.length){
				// only 1 script left and the path equals to the last script length, we reached result step
				toProducts = true		
			} else if(newPossibleScripts.length === 0){
				// no script left, we're stuck. freeze everything
				newCurrentStep = this.state.currentStep
				currentPath.splice(currentPath.length -1, 1);
				newPossibleScripts = this.state.possibleScripts
			} else {
				nextStep = PSContext.scripts[newPossibleScripts[0]].steps[newCurrentStep]
			}		
		} else {
			
			newCurrentStep = this.state.currentStep - 1
			currentPath.splice(currentPath.length -1, 1);
			answerId = currentPath[currentPath.length-1]
			
			let scriptsPool = []
			// refilling the pool with all the script as we cannot trust state.possibleScript anymore
			$.each(PSContext.scripts, function(key, script){		
				scriptsPool.push(key)
			})
			
			newPossibleScripts = filteringPossibleScripts(scriptsPool, currentPath)		
			
			nextStep = PSContext.scripts[newPossibleScripts[0]].steps[newCurrentStep]
		}

		this.setState({
			toProducts: toProducts,
			currentPath: currentPath,
			currentStep: newCurrentStep,
			possibleScripts: newPossibleScripts,
			questionId: nextStep ? nextStep.question : undefined,
			availableAnswers: nextStep ? nextStep.availableAnswers : undefined,
			isBackMode: back
		})
	},
	renderQuestion: function(){
		if (this.state.toProducts){
			return <Result 
				productsPerRow={PSContext.scripts[this.state.possibleScripts[0]].products.length < PSContext.maxProductPerRow ? PSContext.scripts[this.state.possibleScripts[0]].products.length : PSContext.maxProductPerRow}
				products={PSContext.scripts[this.state.possibleScripts[0]].products}
				/>
		} else {
			return <Question key={this.state.currentStep} index={this.state.currentStep} handleClick={this.handleClick} question={PSContext.questions[this.state.questionId]} availableAnswers={sortByPosition(this.state.availableAnswers)}></Question>
		}
	},
	render: function(){
		return (
		<div>	
			{this.renderQuestion()}
			{this.renderPreviousButton()}		
		</div>
		)
	}
})

function filteringPossibleScripts(scriptPool, path){
	let possibleScripts = []
	let isPossibleScript
	
	for(let i = 0; i < scriptPool.length; i++){
		isPossibleScript = true
		for(let j = 0; j < path.length; j++){								
			if (PSContext.scripts[scriptPool[i]].path.indexOf(path[j]) != j){
				isPossibleScript = false
				j = path.length
			}
		}
		
		if(isPossibleScript){
			possibleScripts.push(scriptPool[i]);
		}
	}
	
	return possibleScripts
}

function sortByPosition(items){
	// sort asc 
	items.sort(function(a,b){
		return a.position > b.position ? 1 : a.position === b.position ? 0 : -1;
	})
	return items
}

function Result(props){
	return <div>
			<div className="result-title">
				{GetI18n('productSelector.ourSelection')}
			</div>
			<div className="result-list">
			{
				props.products.map(function(product){
					return <ProductResult product={PSContext.products[product]}/> 
				}, this)
			}
			</div>
		</div>
}

function getProductPrice(price){
	price = (Number(price)/100).toFixed(2)
	if(PSContext.lang.toUpperCase() == 'EN'){
		return '€' + price
	}	
	return (price + ' €').replace('.',',')
}

function ProductResult(props){
	return <div className="result-item">
		<div className="result-item-title">{props.product.name}</div>
		<div className="result-item-price">{GetI18n('productSelector.tarif')} : {props.product.priceStartingFrom ? GetI18n('productSelector.priceStartingFrom') : null} {getProductPrice(props.product.price)}</div>
		<div className="result-item-image"><img src={props.product.image}/></div>
		<div className="result-item-links">
			{ props.product.moreinfo ? <a className="left" href={props.product.moreinfo} target="_blank">{GetI18n('productSelector.moreinfo')}</a> : null }
			{ props.product.mybootik ? <a className="right" href={props.product.mybootik} target="_blank">{GetI18n('productSelector.buyonline')}</a> : null }
		</div>
	</div>
}

function PSButton(props){
	return <div id="backButton" onClick={props.cb}>
		{props.title} 
	</div>
}


function Question(props){
	return <div className="list-container">
		<div className="list-title">{props.question.text}</div>				
			<Answers>
			{			
				props.availableAnswers.map(function(availableAnswer, index){
					return <Answer key={index} index={index} handleClick={props.handleClick} text={PSContext.answers[availableAnswer.answerId].text} id={availableAnswer.id}/> 					
				}, this)
			}
			</Answers>		
	</div>
}


function Answers(props){
	return <ul className="list-root">
			{props.children}
	</ul>	
}

var Answer = React.createClass({
	handleClick: function(){
		this.props.handleClick(this.props.id)
	},
	render: function(){
		return <li key={this.props.id} onClick={this.handleClick} className='list-item-container'>
			<div className="list-item">
				{this.props.text}
			</div>
		</li>
	}
})


$(document).ready(function(){   
	var dataContainer = $('#productSelectorData');
	
	if(dataContainer.length){
		var data = JSON.parse(dataContainer.text());
		dataContainer.remove();
	
		ReactDOM.render(
		  React.createElement(ProductSelector, {data: data}),
		  document.getElementById('productSelector')
		);
		module.exports = ProductSelector;
	}
})

