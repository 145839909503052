var React = require('react');
var ReactDOM = require('react-dom');
var Utils= require("../Utils");
var Ticket = require("./Ticket.jsx");
var Slider = require("react-slick").default;
var Loader = require("../ReactComponents/Loader.jsx");

var TicketsGrid = React.createClass({
	getInitialState: function(){
        return {
            i18n: undefined,
            isResponsive: window.innerWidth < 960,
            tickets: []
        }
    },
    isLoaded: function(){
        return this.state.i18n !== undefined && this.state.tickets.length > 0
        
    },
    updateIsReponsive: function(){      
        if(window.innerWidth < 960 & !this.state.isResponsive){
            this.setState({
                isResponsive: true
            })
        } else {
            if(window.innerWidth > 960 & this.state.isResponsive){
                this.setState({
                    isResponsive: false
                })
            }
        }
    },
    seti18n: function(data){
        this.setState({
            i18n: data
        })
    },
    geti18n: function(){

        var seti18n = this.seti18n;

        $.getJSON({url:"/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build/data/pricecomparator/"+this.props.folder+"/" + Utils.getLanguage() + ".json",async:false},function(data){
            seti18n(data)
        });


        
    },
    setTickets: function(data){
        this.setState({
            tickets: data
        })

    },
    getTickets: function(){

        var setTickets = this.setTickets;


        $.getJSON({url:"/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build/data/pricecomparator/"+this.props.folder+"/tickets.json",async:false},function(data){
            setTickets(data)
        });

    },
    componentDidMount: function() {
        if(!this.state.isLoaded){
            this.geti18n();
            this.getTickets();
        }
        window.addEventListener('resize', this.updateIsReponsive);
        
    },
    componentWillUnmount: function() {
        window.removeEventListener('resize', this.updateIsReponsive);
    },
    render: function(){
        if(this.isLoaded()){
            return <Container isResponsive={this.state.isResponsive} tickets={fillTicketWithText(this.state.tickets, this.state.i18n)}/>
        } else {
            return <Loader/>
        }
    }
})

function fillTicketWithText(tickets, i18n){
    var result = tickets.map(function(ticket){
        return {
            header: {
                flagText: i18n.header.flagText[ticket.header.flagText],
                flagBackgroundColor: ticket.header.flagBackgroundColor,
                flagTextColor: ticket.header.flagTextColor,
                title: i18n.header.title[ticket.header.title],
                backgroundImage: ticket.header.backgroundImage
            },
            body: {
                line1: i18n.body.line1[ticket.body.line1],
                line2: i18n.body.line2[ticket.body.line2],
                line3: i18n.body.line3[ticket.body.line3],
                line4: i18n.body.line4[ticket.body.line4],
                line5: i18n.body.line5[ticket.body.line5],
            },
            button: {
                text: i18n.button[ticket.button.text],
                link: ticket.button.link
            }
        }
    })
    return result;
}

function Container(props){

    var rows = []

    if(props.isResponsive){
        var settings ={
            accessibility: true,
            swipe: true,
            infinite: false,
            slidesToShow: 2.2,
            arrows: false,
            edgeFriction: 1,
            responsive :[ 
                {
                    breakpoint:745,
                    settings: {
                        slidesToShow: 1.2,
                    }
                }
            ]
        }
        rows = <Slider {...settings}>
            {
                props.tickets.map(function(ticket,index){
                    return <div key={index}><Ticket ticket={ticket} isResponsive={props.isResponsive}/></div>
                })
            }
        </Slider>
    } else {
        var rowCount = Math.ceil(props.tickets.length / 3)
    
        for(var i = 0; i <= rowCount; i++){
            
            rows.push( <ul className={'desktop-list'} key={i}>
                {
                    props.tickets.slice(i*3, (i*3)+3).map(function(ticket,index){
                        return <li key={index}><Ticket ticket={ticket} isResponsive={props.isResponsive}/></li>
                    })
                }
            </ul>)
        }   
    }

    return (
    <div id={"PriceComparator"}>
        {rows}
    </div>
    )
}

$(document).ready(function(){   
	
	var priceComparatorRoot = $('#TicketsGrid');
	if(priceComparatorRoot.length){
        ReactDOM.render( 
            React.createElement(TicketsGrid,{folder:priceComparatorRoot.attr('folder')}), 
            priceComparatorRoot.get(0)
        );
        module.exports = TicketsGrid;
    }
        
	
})