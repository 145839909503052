var React = require("react");
var ReactDOM = require("react-dom");

var Reflux = require("reflux");

var Utils = require("../Utils");

var Store = require("./Store");
var Actions = require("./Actions");


var settings = {
    error:null
}


var ViewError = React.createClass({
    mixins: [Reflux.connect(Store, 'store')],
    componentDidMount: function() {
        var scope = this;

        this.listenToMany(Actions);

    },
    onShowError: function(error){
        // TODO
        if (!error) {
            settings.error=null;
        } else if (typeof error === 'string'){
            settings.error={title:'',message:error};
        } else {
            settings.error=error;
        }
        this.forceUpdate();
        //  this.render();
    },

    close: function(e){
        e.preventDefault();
        e.stopPropagation();
        
        settings.error=null;
        this.forceUpdate();
        //this.render();
    },

    render:function(){
        if (settings.error && (settings.error.title || settings.error.message) ){
            return (
<div className="alert alert--warning">
   <div className="alert__icon"><span className="icon-alert" aria-hidden="true"></span></div>
   <div className="alert__title">{settings.error.title} </div>
   <div className="alert__message">/ {settings.error.message}</div>
   <button className="alert__close" onClick={ this.close }><span className="icon-close" aria-hidden="true"></span></button>
</div>
           );
        } else {
            return null;
        }
    },
});

module.exports = ViewError;
