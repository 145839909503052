/**
 * Store locator code
 */
"use strict";

var moment = require('moment');
var moment = require('../../../node_modules/moment/locale/fr.js');
var moment = require('../../../node_modules/moment/locale/nl.js');
var moment = require('moment');

var Utils= require("../Utils");

var MESSAGES_TTL=1000*60*1.5; // 15 minutes

/*********************************************************************
*  #### Twitter Post Fetcher v17.0.0 ####
*  Coded by Jason Mayes 2015. A present to all the developers out there.
*  www.jasonmayes.com
*  Please keep this disclaimer with my code if you use it. Thanks. :-)
*  Got feedback or questions, ask here:
*  http://www.jasonmayes.com/projects/twitterApi/
*  Github: https://github.com/jasonmayes/Twitter-Post-Fetcher
*  Updates will be posted to this site.
*********************************************************************/
(function(root,factory){if(typeof define==='function'&&define.amd){define([],factory);}else if(typeof exports==='object'){module.exports=factory();}else{factory();}}(this,function(){var domNode='';var maxTweets=20;var parseLinks=true;var queue=[];var inProgress=false;var printTime=true;var printUser=true;var formatterFunction=null;var supportsClassName=true;var showRts=true;var customCallbackFunction=null;var showInteractionLinks=true;var showImages=false;var targetBlank=true;var lang='en';var permalinks=true;var dataOnly=false;var script=null;var scriptAdded=false;function handleTweets(tweets){if(customCallbackFunction===null){var x=tweets.length;var n=0;var element=document.getElementById(domNode);var html='<ul>';while(n<x){html+='<li>'+tweets[n]+'</li>';n++;}
html+='</ul>';element.innerHTML=html;}else{customCallbackFunction(tweets);}}
function strip(data){return data.replace(/<b[^>]*>(.*?)<\/b>/gi,function(a,s){return s;}).replace(/class="(?!(tco-hidden|tco-display|tco-ellipsis))+.*?"|data-query-source=".*?"|dir=".*?"|rel=".*?"/gi,'');}
function targetLinksToNewWindow(el){var links=el.getElementsByTagName('a');for(var i=links.length-1;i>=0;i--){links[i].setAttribute('target','_blank');}}
function getElementsByClassName(node,classname){var a=[];var regex=new RegExp('(^| )'+classname+'( |$)');var elems=node.getElementsByTagName('*');for(var i=0,j=elems.length;i<j;i++){if(regex.test(elems[i].className)){a.push(elems[i]);}}
return a;}
function extractImageUrl(image_data){if(image_data!==undefined&&image_data.innerHTML.indexOf('data-srcset')>=0){var data_src=image_data.innerHTML.match(/data-srcset="([A-z0-9%_\.-]+)/i)[0];return decodeURIComponent(data_src).split('"')[1];}}
var twitterFetcher={fetch:function(config){if(config.maxTweets===undefined){config.maxTweets=20;}
if(config.enableLinks===undefined){config.enableLinks=true;}
if(config.showUser===undefined){config.showUser=true;}
if(config.showTime===undefined){config.showTime=true;}
if(config.dateFunction===undefined){config.dateFunction='default';}
if(config.showRetweet===undefined){config.showRetweet=true;}
if(config.customCallback===undefined){config.customCallback=null;}
if(config.showInteraction===undefined){config.showInteraction=true;}
if(config.showImages===undefined){config.showImages=false;}
if(config.linksInNewWindow===undefined){config.linksInNewWindow=true;}
if(config.showPermalinks===undefined){config.showPermalinks=true;}
if(config.dataOnly===undefined){config.dataOnly=false;}
if(inProgress){queue.push(config);}else{inProgress=true;domNode=config.domId;maxTweets=config.maxTweets;parseLinks=config.enableLinks;printUser=config.showUser;printTime=config.showTime;showRts=config.showRetweet;formatterFunction=config.dateFunction;customCallbackFunction=config.customCallback;showInteractionLinks=config.showInteraction;showImages=config.showImages;targetBlank=config.linksInNewWindow;permalinks=config.showPermalinks;dataOnly=config.dataOnly;var head=document.getElementsByTagName('head')[0];if(script!==null){head.removeChild(script);}
script=document.createElement('script');script.type='text/javascript';if(config.list!==undefined){script.src='https://syndication.twitter.com/timeline/list?'+'callback=__twttrf.callback&dnt=false&list_slug='+
config.list.listSlug+'&screen_name='+config.list.screenName+'&suppress_response_codes=true&lang='+(config.lang||lang)+'&rnd='+Math.random();}else if(config.profile!==undefined){script.src='https://syndication.twitter.com/timeline/profile?'+'callback=__twttrf.callback&dnt=false'+'&screen_name='+config.profile.screenName+'&suppress_response_codes=true&lang='+(config.lang||lang)+'&rnd='+Math.random();}else if(config.likes!==undefined){script.src='https://syndication.twitter.com/timeline/likes?'+'callback=__twttrf.callback&dnt=false'+'&screen_name='+config.likes.screenName+'&suppress_response_codes=true&lang='+(config.lang||lang)+'&rnd='+Math.random();}else{script.src='https://cdn.syndication.twimg.com/widgets/timelines/'+
config.id+'?&lang='+(config.lang||lang)+'&callback=__twttrf.callback&'+'suppress_response_codes=true&rnd='+Math.random();}
head.appendChild(script);}},callback:function(data){if(data===undefined||data.body===undefined){inProgress=false;if(queue.length>0){twitterFetcher.fetch(queue[0]);queue.splice(0,1);}
return;}
data.body=data.body.replace(/(<img[^c]*class="Emoji[^>]*>)|(<img[^c]*class="u-block[^>]*>)/g,'');if(!showImages){data.body=data.body.replace(/(<img[^c]*class="NaturalImage-image[^>]*>|(<img[^c]*class="CroppedImage-image[^>]*>))/g,'');}
if(!printUser){data.body=data.body.replace(/(<img[^c]*class="Avatar"[^>]*>)/g,'');}
var div=document.createElement('div');div.innerHTML=data.body;if(typeof(div.getElementsByClassName)==='undefined'){supportsClassName=false;}
function swapDataSrc(element){var avatarImg=element.getElementsByTagName('img')[0];avatarImg.src=avatarImg.getAttribute('data-src-2x');return element;}
var tweets=[];var authors=[];var times=[];var images=[];var rts=[];var tids=[];var permalinksURL=[];var x=0;if(supportsClassName){var tmp=div.getElementsByClassName('timeline-Tweet');while(x<tmp.length){if(tmp[x].getElementsByClassName('timeline-Tweet-retweetCredit').length>0){rts.push(true);}else{rts.push(false);}
if(!rts[x]||rts[x]&&showRts){tweets.push(tmp[x].getElementsByClassName('timeline-Tweet-text')[0]);tids.push(tmp[x].getAttribute('data-tweet-id'));if(printUser){authors.push(swapDataSrc(tmp[x].getElementsByClassName('timeline-Tweet-author')[0]));}
times.push(tmp[x].getElementsByClassName('dt-updated')[0]);permalinksURL.push(tmp[x].getElementsByClassName('timeline-Tweet-timestamp')[0]);if(tmp[x].getElementsByClassName('timeline-Tweet-media')[0]!==undefined){images.push(tmp[x].getElementsByClassName('timeline-Tweet-media')[0]);}else{images.push(undefined);}}
x++;}}else{var tmp=getElementsByClassName(div,'timeline-Tweet');while(x<tmp.length){if(getElementsByClassName(tmp[x],'timeline-Tweet-retweetCredit').length>0){rts.push(true);}else{rts.push(false);}
if(!rts[x]||rts[x]&&showRts){tweets.push(getElementsByClassName(tmp[x],'timeline-Tweet-text')[0]);tids.push(tmp[x].getAttribute('data-tweet-id'));if(printUser){authors.push(swapDataSrc(getElementsByClassName(tmp[x],'timeline-Tweet-author')[0]));}
times.push(getElementsByClassName(tmp[x],'dt-updated')[0]);permalinksURL.push(getElementsByClassName(tmp[x],'timeline-Tweet-timestamp')[0]);if(getElementsByClassName(tmp[x],'timeline-Tweet-media')[0]!==undefined){images.push(getElementsByClassName(tmp[x],'timeline-Tweet-media')[0]);}else{images.push(undefined);}}
x++;}}
if(tweets.length>maxTweets){tweets.splice(maxTweets,(tweets.length-maxTweets));authors.splice(maxTweets,(authors.length-maxTweets));times.splice(maxTweets,(times.length-maxTweets));rts.splice(maxTweets,(rts.length-maxTweets));images.splice(maxTweets,(images.length-maxTweets));permalinksURL.splice(maxTweets,(permalinksURL.length-maxTweets));}
var arrayTweets=[];var x=tweets.length;var n=0;if(dataOnly){while(n<x){arrayTweets.push({tweet:tweets[n].innerHTML,author:authors[n]?authors[n].innerHTML:'Unknown Author',time:times[n].textContent,timestamp:times[n].getAttribute('datetime').replace('+0000','Z').replace(/([\+\-])(\d\d)(\d\d)/,'$1$2:$3'),image:extractImageUrl(images[n]),rt:rts[n],tid:tids[n],permalinkURL:(permalinksURL[n]===undefined)?'':permalinksURL[n].href});n++;}}else{while(n<x){if(typeof(formatterFunction)!=='string'){var datetimeText=times[n].getAttribute('datetime');var newDate=new Date(times[n].getAttribute('datetime').replace(/-/g,'/').replace('T',' ').split('+')[0]);var dateString=formatterFunction(newDate,datetimeText);times[n].setAttribute('aria-label',dateString);if(tweets[n].textContent){if(supportsClassName){times[n].textContent=dateString;}else{var h=document.createElement('p');var t=document.createTextNode(dateString);h.appendChild(t);h.setAttribute('aria-label',dateString);times[n]=h;}}else{times[n].textContent=dateString;}}
var op='';if(parseLinks){if(targetBlank){targetLinksToNewWindow(tweets[n]);if(printUser){targetLinksToNewWindow(authors[n]);}}
if(printUser){op+='<div class="user">'+strip(authors[n].innerHTML)+'</div>';}
op+='<p class="tweet">'+strip(tweets[n].innerHTML)+'</p>';if(printTime){if(permalinks){op+='<p class="timePosted"><a data-time="' + times[n].getAttribute('datetime') + '" href="'+permalinksURL[n]+'">'+times[n].getAttribute('aria-label')+'</a></p>';}else{op+='<p class="timePosted">'+
times[n].getAttribute('aria-label')+'</p>';}}}else{if(tweets[n].textContent){if(printUser){op+='<p class="user">'+authors[n].textContent+'</p>';}
op+='<p class="tweet">'+tweets[n].textContent+'</p>';if(printTime){op+='<p class="timePosted">'+times[n].textContent+'</p>';}}else{if(printUser){op+='<p class="user">'+authors[n].textContent+'</p>';}
op+='<p class="tweet">'+tweets[n].textContent+'</p>';if(printTime){op+='<p class="timePosted">'+times[n].textContent+'</p>';}}}
if(showInteractionLinks){op+='<p class="interact"><a href="https://twitter.com/intent/'+'tweet?in_reply_to='+tids[n]+'" class="twitter_reply_icon"'+
(targetBlank?' target="_blank">':'>')+'Reply</a><a href="https://twitter.com/intent/retweet?'+'tweet_id='+tids[n]+'" class="twitter_retweet_icon"'+
(targetBlank?' target="_blank">':'>')+'Retweet</a>'+'<a href="https://twitter.com/intent/favorite?tweet_id='+
tids[n]+'" class="twitter_fav_icon"'+
(targetBlank?' target="_blank">':'>')+'Favorite</a></p>';}
if(showImages&&images[n]!==undefined&&extractImageUrl(images[n])!==undefined){op+='<div class="media">'+'<img src="'+extractImageUrl(images[n])+'" alt="Image from tweet" />'+'</div>';}
if(showImages){arrayTweets.push(op);}else if(!showImages&&tweets[n].textContent.length){arrayTweets.push(op);}
n++;}}
handleTweets(arrayTweets);inProgress=false;if(queue.length>0){twitterFetcher.fetch(queue[0]);queue.splice(0,1);}}};window.__twttrf=twitterFetcher;window.twitterFetcher=twitterFetcher;return twitterFetcher;}));





/*********************************************************************
*  Begin of info related script (custom code)
*********************************************************************/

	function _get524NewsItems(d0, attrMaxitems, lang){
	
		var $secondnotifcontainer = $(".box--notifications__notifications--more");
        var time=((new Date()).getTime()/MESSAGES_TTL);
		var noCache=Math.floor(time);
		var url=Utils.getNetworkMessagesUri();

		$.getJSON( url, {language: lang, type: 524, by: "all","_nocache": noCache} )
		.done(function( allMessagesBeanObj ) {
			
			var addedItems = [], itemOptions = [];
			if(allMessagesBeanObj && allMessagesBeanObj.messages && allMessagesBeanObj.messages.messages524List && allMessagesBeanObj.messages.messages524List.length > 0){
								
				$(allMessagesBeanObj.messages.messages524List).each(function( index, message) {								
						if (attrMaxitems == index) {
							return false;
						}

						if (message.title.trim && message.title.trim() !== '') {
							var options = {
								"dataType" : "DELAYS",
								"dataPosition" : "",
								"icon" : "icon-clock",
								"text" : message.title,
								"priority" : message.priority
							};
							itemOptions.push(options);
						}				
				});
				
				//Sort items based upon priority
				itemOptions.sort(function(a,b){
				   return a.priority - b.priority;
				});
				
				//Add rendered item to the list
				var alreadyDisplayedItem = []

				for(var i=0;i<itemOptions.length;i++){
					if(!alreadyDisplayedItem.includes(itemOptions[i].text)){	
						addedItems.push(_createListItem(itemOptions[i]));
						alreadyDisplayedItem.push(itemOptions[i].text)
					}
				}
				$secondnotifcontainer.prepend(addedItems);
				
			}
			d0.resolve( true );
			
		}).fail(function( jqxhr, textStatus, error ) {
			if(console != null && typeof console.error==="object"){
				console.error("524 items not available : " + (error) ? error:"");
			}
			d0.resolve( true );
		});
		
	}

	function _getNewsItems(){
		var lang = $("html").attr("lang");
		if(lang == undefined){
			lang = "fr";
		}
		//CREATE DEFFERED OBJECTS
		var d0 = $.Deferred(); //524 deffered
		var d1 = $.Deferred(); //Twitter deffered
		//var d2 = $.Deferred(); //Instagram deffered
		
		$.when(d0, d1 ).done(function (v0, v1 ) {
			if(v0 && v1){
				_orderNewsItems(); //REORDER NEWS ITEMS
				_renderNewsItems(); //RENDER NEWS ITEMS
			}
			
		});
		
		//GET LIST LIMIT
		var $firstnotifcontainer = $(".box--notifications__notifications").first(),
		$secondnotifcontainer = $(".box--notifications__notifications--more"),
		attrMaxitems = Number(getMaxItemsToDisplayed($secondnotifcontainer, 15)) + Number(getMaxItemsToDisplayed($firstnotifcontainer, 3));

		var twitter_widget_id = $secondnotifcontainer.attr("data-twitter-widget-id"),
		twitter_screen_name = $secondnotifcontainer.attr("data-twitter-screen-name");
		
		
		//GET 524 ITEMS
		_get524NewsItems(d0, attrMaxitems, lang);
		
		//GET TWITTER ITEMS
		var langu = $("html").attr("lang");
        var hashtagSTIBMIVB = "";
        if (langu == "fr"){
            hashtagSTIBMIVB = "#stib";
        } else if (langu == "nl"){
            hashtagSTIBMIVB = "#mivb";
        }

		var configProfile = {
		  "maxTweets": attrMaxitems,
		  "enableLinks": true, 
		  "showUser": false,
		  "showTime": true,
		  "showImages": false,
		  "showInteraction": false,
		  
		  "lang": lang,
		  "customCallback": function(data){
			  var addedItems = [];
			  for(var i=0; i<data.length;i++){
				var $elem = $.parseHTML( data[i] );
				if($elem[0].innerText.length != 0){
					var ms = convertToMilliseconds($elem[1].firstElementChild.getAttribute("data-time"), "yyyy-MM-dd'T'HH:mm:ss.SSSz");
					// eTime = ellapsedTime(ms);

                    var dateString=$elem[1].firstElementChild.getAttribute("data-time");
                    var eTime=moment.parseZone(dateString).local().locale(lang).fromNow();
					
                    var text = $elem[0].innerText;
                    var href = $elem[1].firstElementChild.href;
                    
					var options = {
						"dataType" : "SOCIAL",
						"dataPosition" : "",
						"dataTime" : ms,
						"icon" : "icon-twitter",
						"href" : href,
						"text" : text,
						
//						"posted" : eTime
						"posted" : dateString
						
					}
					if ( (hashtagSTIBMIVB.length == 0) || (text.toLowerCase().indexOf(hashtagSTIBMIVB.toLowerCase()) != -1) ) {
                        addedItems.push(_createListItem(options));
                    }
				}
			  }
			  $secondnotifcontainer.append(addedItems);
			  d1.resolve( true );
		  }
		};
		
		/*
		var langu = $("html").attr("lang");
		if(langu == "fr" || langu == "nl"){
			configProfile["id"] = twitter_widget_id;
		}else{
			configProfile["profile"] = {"screenName": twitter_screen_name};
		}
		*/
		
		configProfile["profile"] = {"screenName": twitter_screen_name};
		twitterFetcher.fetch(configProfile);
		
		/*
		//GET INSTAGRAM ITEMS
		var instagram_access_token = $secondnotifcontainer.attr("data-instagram-token");

		$.ajax({
			url: 'https://api.instagram.com/v1/users/self/media/recent',
			dataType: 'jsonp',
			type: 'GET',
			data: {access_token: instagram_access_token, count: attrMaxitems},
			success: function(result){
				var addedItems = [];
				for(var x in result.data ){
					if(result.data[x].caption && result.data[x].caption.text.length != 0){
                        
                        var dateString=result.data[x].created_time;
                        //var eTime=moment.unix(dateString).locale(lang).fromNow();
                        //var eTime=moment.parseZone("unix_timestamp").local().locale(lang).fromNow();
                        var eTime=moment(dateString * 1000).local().locale(lang).fromNow();
                        
						var options = {
							"dataType" : "SOCIAL",
							"dataPosition" : "",
							"dataTime" : convertToMilliseconds(result.data[x].created_time, "unix_timestamp"),
							"icon" : "icon-instagram",
							"href" : result.data[x].link,
							"text" : result.data[x].caption.text,
							"image": result.data[x].images.thumbnail,
                            "posted" : eTime

						}
						addedItems.push(_createListItem(options));
					}
				}
				$secondnotifcontainer.append(addedItems);
				d2.resolve( true );
			},
			error: function(data){
				if(console != null && typeof console.error==="object"){
					console.error("Instagram API not available : " + (data.error) ? data.error:"");
				}
				d2.resolve( true );
			}
		});
		*/
	}
	
	
	function returnTextFromString(myString){	
		if((myString == null)||(myString == undefined)||(myString.indexOf('undefined')>-1) ){
			//console.log("String null or undefined or 'undefined'");
			return "";
		}else{
			if(myString.length<1){
				//console.log("String Not null but empty");
				return "";
			}else return myString;
		}
	}

	function _createListItem(options){
		var result = '<li class="box--notifications__item" data-type="'+options.dataType+'"';
		result += (options.hasOwnProperty("dataTime"))? ' data-time="' +options.dataTime + '"' : "";
		result += ' data-position="'+options.dataPosition+'"><span class="'+ options.icon +'"></span><blockquote class="box--notifications__item__content">';
		if(options.href && options.href.length > 0){
			result += '<a href="'+options.href+'" target="'+(options.dataType === "SOCIAL" ? "_blank" : "_top")+'">';
			result += (options.hasOwnProperty("image"))? '<img class="_icyx7" id="pImage_0" src="'+options.image.url+'" style="border-radius: 3px;"><br>'+options.text : options.text;
//			result += (options.hasOwnProperty("posted"))? '<br><span class="social-date">'+options.posted+'</span>': "";
			result += '</a>';
		}else{
			result += '<a tabindex="0">'+options.text+'</a>';
		} 
		
		if (options.posted){
			var lang = Utils.getLanguage();
			var postedDate = new Date(splitDateFromString(options.posted));
			result += '<span class="box--notifications__item__content__date">'+ postedDate.toLocaleDateString('fr') + ' - ' + postedDate.toLocaleTimeString('fr', {hour: '2-digit', minute:'2-digit'}) + '</span>';
		}
		
		result += '</blockquote></li>';
		return result;
	}

	function _orderNewsItems(){
		//Assign data-position to delays items
		var delaysCounter = 2;
		$(".box--notifications__notifications--more li.box--notifications__item[data-type=DELAYS]").each(function(index){
			$(this).attr( "data-position", delaysCounter );
			delaysCounter = delaysCounter + 2;
		});
		
		//First sort social items based upon the timestamp
		$(".box--notifications__notifications--more li.box--notifications__item").sort(sort_social).appendTo('.box--notifications__notifications--more');
		
		//Assign data-position to social items
		var socialCounter = 2.5;
		$(".box--notifications__notifications--more li.box--notifications__item[data-type=SOCIAL]").each(function(index){
			$(this).attr( "data-position", socialCounter );
			socialCounter = socialCounter + 2;
		});
		
	   //Reorder items based upon the attribute position
	   $(".box--notifications__notifications--more li.box--notifications__item").sort(sort_notif).appendTo('.box--notifications__notifications--more');
	}

	function convertToMilliseconds(value, format){
		var date = "";
		if(format == "yyyy-MM-dd'T'HH:mm:ss.SSSz"){
			var datePart = value.substring(0, value.indexOf('T'));
			var timePart = value.substring(value.indexOf('T')+1, value.indexOf('+'));
			date = new Date(Date.UTC((Number(datePart.split("-")[0])), (Number(datePart.split("-")[1]) - 1), (Number(datePart.split("-")[2])), (Number(timePart.split(":")[0])), (Number(timePart.split(":")[1])), (Number(timePart.split(":")[2]))));
		}else if(format == "unix_timestamp"){
			date = new Date(value*1000);
		}else{
			return "";
		}

		if(date instanceof Date){
			return date.getTime();
		}else{
			return "";
		}
	}

	function sort_notif(a, b){
	    return ($(b).data('position')) < ($(a).data('position')) ? 1 : -1;
	}
	function sort_social(a, b){
	    return ($(b).data('time')) < ($(a).data('time')) ? -1 : 1; 	
	}

	function _renderNewsItems(attrType){
		
		var $firstnotifcontainer = $(".box--notifications__notifications").first(),
		$secondnotifcontainer = $(".box--notifications__notifications--more"),
		attrMaxitems,
		addedItems = [];
		
		if (typeof attrType !== typeof undefined && attrType !== false) {
		  $( "li.box--notifications__item").hide();
		  $( "li.box--notifications__item[data-type='"+attrType+"']" ).each(function( index ) {
			$( this ).show();
		  });
		}else{
		  $( "li.box--notifications__item").show();
		}

		//ADD ITEMS TO FIRST CONTAINER
		attrMaxitems = getMaxItemsToDisplayed($firstnotifcontainer, 3);
		addedItems = $(".box--notifications__notifications--more li.box--notifications__item").filter(function() { return $(this).css("display") != "none" }).slice(0, attrMaxitems).hide().clone();
		$firstnotifcontainer.empty().append(addedItems);
		$firstnotifcontainer.children().show();
		
		//HIDE ADDITIONEL LINES
		attrMaxitems = getMaxItemsToDisplayed($secondnotifcontainer, 15) - attrMaxitems;
		$(".box--notifications__notifications--more li.box--notifications__item").filter(function() { return $(this).css("display") != "none" }).slice(attrMaxitems).hide();
		
	}

	function getMaxItemsToDisplayed($elem, fallback){
		var attrMaxitems = $elem.attr('data-maxitems');
		if (typeof attrMaxitems === typeof undefined && attrMaxitems === false) {
			attrMaxitems = fallback; //Fallback default
		}
		return attrMaxitems;
	}

	function _setupFilterButtons(){

		//ON NOTICATION FILTER BUTTON CLICKED
		$(".box--notifications__filters .box--notifications__filter").click(function(e) {
		  
		  e.preventDefault();
		  var $elem = $(this);
		  
		  //SET ACTIVE STATE ON THE SELECTED NOTICATION FILTER BUTTON
		  $(".box--notifications__filters .is-active").toggleClass( "is-active" );
		  $elem.toggleClass( "is-active" );
		  
		  //RENDER ITEMS
		  _renderNewsItems($elem.attr('data-type'));
		  
		});
		
	}
	
	// "2019-04-28T11:27:44+0000"
	// format: YYYY-MM-DDTHH:MM:SS+MS
	function splitDateFromString(date){
		var datePart = date.substring(0, date.indexOf('T'));
		var timePart = date.substring(date.indexOf('T')+1, date.indexOf('+'));
		return Date.UTC((Number(datePart.split("-")[0])), (Number(datePart.split("-")[1]) - 1), (Number(datePart.split("-")[2])), (Number(timePart.split(":")[0])), (Number(timePart.split(":")[1])), (Number(timePart.split(":")[2])));
	}
	
$( document ).ready(function() {
    var el=$("#info-notifications");
    
    if (el.length>0){
	    _getNewsItems(); //GET NEWS ITEMS (524, TWITTER AND INSTAGRAM)
	    _setupFilterButtons(); //SETUP ON CLICK EVENT WHEN A FILTER BUTTON IS PRESSED        
    };
});


