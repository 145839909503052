function getTargetFromTrigger($trigger) {
    var href,
        target = $trigger.attr('data-target') || (href = $trigger.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '');

    return $(target)
  }

$(document).on('click', '[data-toggle="collapse"]', function (e) {
    var $this   = $(this);

    if (!$this.attr('data-target')) {
        e.preventDefault();
    }

    var $target = $($this.attr('data-target') || (href = $this.attr('href')));

    $target.slideToggle();
    $this.toggleClass('is-toggled');
})