/**
 * The goal of this module is to hadle the call of several ajax call, 
 * Killing the previous call that are on the way and not finished yet.
 */
"use strict";
var SINGLETON={};

var AJAX_CALLS={};

function startAjaxCall(type,ajax){
    AJAX_CALLS[type]=ajax;    
}

function setFinished(ajax){
    if (ajax){
        //console.log("setFinished");
        ajax.finished=true;
    } else {
        //console.log("setFinished is null");
    }
}

function kill(type,ajax){
    setFinished(ajax)
    if ($.isFunction(ajax.restore)){
        try {
            ajax.restore(type,ajax);
        }catch(err){ }    
    }
    AJAX_CALLS[type]=null;
    try {
        call.abort();        
    }catch(err){ }    
}

SINGLETON.isFinished=function(ajax){
    if (ajax && ajax.finished === true){
        //console.log("isFinished true");
        return true;
    } else {
        //console.log("isFinished false");
        return false;
    }
}

SINGLETON.finishAjax=function(type,ajax){
    //console.log("finishAjax:"+type);
    setFinished(ajax)
    AJAX_CALLS[type]=null;
};

SINGLETON.killAllAjax=function(type,ajax){
    // alert("Kill All:"+type);

    for (var type in AJAX_CALLS) {
        var call=AJAX_CALLS[type];
        if (call){
            kill(type,call);
        }
    }

    startAjaxCall(type,ajax);
};

module.exports = SINGLETON;
