/**
 * Store locator code
 */
"use strict";

// function replaceUrlParam(url, locationSearch,paramName, paramValue){
// 	if(paramValue == null){
// 	    paramValue = '';
//     }
// 	var pattern = new RegExp('\\b('+paramName+'=).*?(&|$)')
// 	if(url.search(pattern)>=0){
// 	    return url.replace(pattern,'$1' + paramValue + '$2');
// 	} else {
//         // Problem width: https://webtest.stib-mivb.be/mystib/?showmodal=error-overlay&HasMyBootikFeedback=True#mybootik&l=en
//         if (locationSearch && locationSearch.indexOf('#') !== -1){
//             int index=locationSearch.indexOf('#');

//             if (index>0){
//                 var currentUrl=locationSearch.substring(0,index);

//             } else {
//                 https://webtest.stib-mivb.be/mystib/#mybootik
//                 return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue
//             }
            
            
//         } else {
// 	        return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue
//         }
//     }
// }

// http://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter
function replaceUrlParam(url,key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
        hash;

    if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null) {
            return url.replace(re, '$1' + key + "=" + value + '$2$3');
        } else {
            hash = url.split('#');
            url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                url += '#' + hash[1];
            }
            return url;
        }
    } else {
        if (typeof value !== 'undefined' && value !== null) {
            var separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator + key + '=' + value;
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                url += '#' + hash[1];
            }
            return url;
        } else {
            return url;
        }
    }
}

$( document ).ready(function() {
    
	//Submit search form
	$( "form.search-form" ).submit(function( event ) {
		var sVal = $( "form input.search-form__input" ).val();
		if (sVal !== "" && sVal !== "*") {
			return;
		}
		event.preventDefault();
	});

	//Language change
	$(".languSwitch").click(function( event ){
		var langu = $(this).find(".language-switcher__short-name").text();
		location.href = replaceUrlParam(location.href, "l", langu.toLowerCase());
		event.preventDefault();
	});
	
	$(".language-switcher__current").click(function(e){
		e.preventDefault();
	})
	
	$(".language-switcher__current").focus(function(e){
		$(".language-switcher__selector").show();
	})
	
	$(".language-switcher").hover(function(){
		$(".language-switcher__selector").show();
	},
	function(){
		$(".language-switcher__selector").hide();
	})
	
});

