var React = require("react");
var ReactDOM = require("react-dom");
var Line = require("./BoxLines/Line.jsx");
var Reflux = require("reflux");
var _ = require("underscore");
const Slider = require("react-slick").default;
var TransportType = require("./BoxLines/transportType.jsx");
var BoxLinesStore = require("./BoxLines/BoxLinesStore.js");
var BoxLinesActions = require("./BoxLines/BoxLinesActions");
var getI18n = require("./general/i18n");
var Utils = require("./Utils");

var SlickArrowButton = React.createClass({
	render: function(){
		return (<button {...this.props}></button>)
	}
})

var settings = {
  accessibility: true,
  slidesToShow: 10,
  slidesToScroll: 10,
  infinite:false,
  prevArrow: <SlickArrowButton aria-label={getI18n('sliderButton.previous')}/>,
  nextArrow: <SlickArrowButton aria-label={getI18n('sliderButton.next')}/>,
  dots: true,
  responsive: [
    {
      breakpoint:960,
      settings: {
        infinite:false,
        slidesToShow: 7,
        slidesToScroll: 7
      }
    },
    {
      breakpoint:745,
      settings: {
        infinite:false,
        slidesToShow: 10,
        slidesToScroll: 10
      }
    },
    {
      breakpoint: 450,
      settings: {
        infinite:false,
        slidesToShow: 6,
        slidesToScroll: 6
      }
    }
  ]
}



var BoxLines = React.createClass({
    mixins: [Reflux.connect(BoxLinesStore, 'store')],
    componentDidMount: function() {
        var scope = this;
    },
    transportTypes: function(){
        return this.state.store.transportTypes.map(function(item,index){
            return (
                <TransportType name={item.name} short={getI18n(item.short)} key={index}/>
            );
        })
    },
    lines: function(){

        var count = 0;

        var grouped = _.groupBy(this.state.store.selectedTransportLines,function(line,index){
            if((index%2) === 0){
                count++;
            }
            return count;
        })

        grouped = _.map(grouped, function(items,index){
            var lineItems = items.map(function(item,index){
                return (
                    <Line {...item} key={index} href={Utils.getHoraireUri()+"&_line="+item.number+"&_directioncode=V"}/>
                );
            });
            return (
                <div key={index}>
                    <div className="box--lines__col">
                    { lineItems }
                    </div>
                </div>
            )
       })

        return (
            <Slider { ...settings } key={this.state.store.selectedTransport}>
                { grouped }
            </Slider>
        );
    },
    linesSelector: function(){
        return (
            <div>
                <div className="col--12 col--12@mobile box--lines__intro">
                    <div className="box--lines__label">{getI18n('boxlines.filter')}</div>
                    <ul className="box--lines__transport-type">
                        { this.transportTypes() }
                    </ul>
                </div>
                <div className="col--12 col--12@mobile">
                    <div className="box--lines__carousel">
                        { this.lines() }
                    </div>
                </div>
            </div>
        );
    },
    selectedLine: function(){
        return (
            <div>
                <button className="col--1 col--2@mobile box--lines__close" onClick={ BoxLinesActions.close }><span className="icon-arrow-left" aria-hidden="true"></span></button>
                <div className="col--3 col--10@mobile box--lines__intro">
                <span className="box--lines__number-label">{ i18n("You have") } <br/>{ i18n("chosen line: ") }</span>
                    <span className="box--lines__number"><Line { ...this.state.store.selectedLine } /></span>
                </div>
                <div className="col--2 col--12@mobile box--lines__direction-label">
                    { i18n("Choose direction: ") }
                </div>
                <div className="col--6 col--12@mobile">
                    <div className="box--lines__from">
                <a className="btn btn--fill-width btn--secondary btn--icon-pull-out btn--small"  href={Utils.getHoraireUri()+"&_line="+this.state.store.selectedLine.number+"&_directioncode=V"}><span className="icon-long-arrow-right" aria-hidden="true"></span><span className="sr-only">{getI18n('boxlines.direction')}</span> { this.state.store.selectedLine.route.from[globalLanguage] }</a>
                    </div>
                    <div className="box--lines__to">
                        <a className="btn btn--fill-width btn--secondary btn--icon-pull-out btn--small"  href={Utils.getHoraireUri()+"&_line="+this.state.store.selectedLine.number+"&_directioncode=F"}><span className="icon-long-arrow-right" aria-hidden="true"></span><span className="sr-only">{getI18n('boxlines.direction')}</span> { this.state.store.selectedLine.route.to[globalLanguage] }</a>
                    </div>
                </div>
            </div>
        );
    },
    render:function(){
        return (
            <div className="container">
                { (this.state.store.selectedLine) ? this.selectedLine() : this.linesSelector() }
            </div>
        );
    }
})



/**
 * END CODE
 */
        
$(document).ready(function(){    
    var boxlines = $(".box--lines .box__content");
    if(boxlines.length){

        ReactDOM.render(<BoxLines />, boxlines.get(0));
        module.exports = BoxLines;
    }
})

