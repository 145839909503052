var React = require("react");
var ReactDOM = require("react-dom");

var Reflux = require("reflux");
var Utils = require("../Utils");
var Store = require("./Store");
//var Actions = require("./Actions");


//var BoxLinesStore = require("./BoxLinesStore");
//var BoxLinesActions = require("./BoxLinesActions");

var settings = {
}

var LinesList = React.createClass({
    //mixins: [Reflux.connect(BoxLinesStore, 'store')],
    
    // componentDidMount: function() {
    //     var scope = this;
    // },
    // componentWillUnmount: function() {
    //     this.unsubscribe();
    // },

    selectLine: function(item){
        return function(e){
            e.preventDefault();
            e.stopPropagation();
            Store.selectedLine(item.number);
        }
    },

    // line: function(item){
    //     return (
    //             <a href="#" key={item.number} onClick={ this.selectLine } className={"line line--"+item.number}>{item.number}</a>
    //     );        
    // },

    line: function(item){
        // return (
        //         <a href={"./Horaire.jsp?n="+item.number} key={item.number} className={"line line--"+item.number}>{ item.number}</a>
        // );

        if (item.type === "noctis") {
            var id=item.number.substring(1,item.number.length);
            return (
                    <a href={"/horaires-dienstregeling2.html?l="+ Utils.getLanguage() +"&_line=" + item.number + "&_directioncode=V"} onClick={ this.selectLine(item) } key={item.number} className={"line line--"+item.number}><span>N</span>{id}</a>
            );
        } else {
            return (
                    <a href={"/horaires-dienstregeling2.html?l="+ Utils.getLanguage() +"&_line="+ item.number +"&_directioncode=V"} onClick={ this.selectLine(item) } key={item.number} className={"line line--"+item.number}>{ item.number}</a>
            );
        }
    },

    transportLines: function(name){
        var that=this;

        // {
        //     "type": "tram",    
        //     "bg":"#c8d100",
        //     "color":"rgb(0, 0, 0)",
        //     "number":"3",
        //     "route":{
        //         "from": "Herrman-debroux",
        //         "to": "Erasmus"
        //     }
        // },

        var lines=Store.getLinesForTransportType(name);

        return lines.map(function(item,index){
            return (
                that.line(item)
            );
        });
    },
    transportTypes: function(){
        var that=this;

        var transportTypesArray=Store.getTransportTypes();
        return transportTypesArray.map(function(item,index){
            if (item.short === 'N'){  
                return (
                    <div key={item.name} className="all-lines__item">
                        <h2><img src="/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build//images/logo--noctis.png" alt="Noctis" /></h2>
                      { that.transportLines(item.name) }
                    </div>
                );
            } else {
                return (
                    <div key={item.name} className="all-lines__item">
                      <h2><span className="mode"><span className="mode__inner">{item.short}</span></span>{item.name}</h2>
                      { that.transportLines(item.name) }
                    </div>

                );
            }
        })
    },

    render:function(){
        return (
<div className="box all-lines">
    <div className="container">
       <div className="col--12">
                { this.transportTypes() }
       </div>
     </div>
</div>
        );
    }

})

// $(document).ready(function(){
//     var el = $("#lineslist");
//     if(el.length){
//         ReactDOM.render(<LinesList />, el.get(0));
//     }    
// })

module.exports = LinesList;
