'use strict';

var Request=require("../lines/request.js");

var cookieLanguage = "cookie_language";
var cookieGiro = "GIRO_PersistedCulture";
var cookieLanguageValidity = 200;
var language_param_name = "l";


function readACookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function createACookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}


$(document).ready(function() {
    // read the lang parameter
    var l=Request.getParameter("l",null);
    if (l){
        var cookieLangValue = readACookie(cookieLanguage);

        // if the lang of the url different from the cookie, set the cookie one
        if (l !== cookieLangValue){
            createACookie(cookieLanguage, l, cookieLanguageValidity);
        }
        // forces the cookie for giro
        // document.cookie="GIRO_PersistedCulture=fr; expires=; path=/";
        createACookie(cookieGiro, l, cookieLanguageValidity);        
    }		
});

