module.exports = new function CompactModal(){
    var self = this;
    var _modal;
    var _modalContainer;
    var _modalContent;
    var _modalClose;
    var _attached;
    var _open = false;

    function _init(){
        _creatModal();
        _delegate();
    }

    function _creatModal(){
        _modal = $("<div class='modal'></div>");
        _modalContainer = $("<div class='modal__container'></div>");
        _modalContent = $("<div class='modal__content'></div>");
        _modalClose = $("<a href='#' class='modal__close'>x</a>");
        _modal.appendTo($("body"));
        _modalContainer.appendTo(_modal);
        _modalContent.appendTo(_modalContainer);
        _modalClose.appendTo(_modalContainer);
    }

    function _delegate(){
        _modalClose.on("click",function(e){
            e.preventDefault();
            self.close();
        })

        $(window).on("resize", self.updatePosition);
    }

    self.updatePosition = function(){
        if(_attached){
            var attachedAnchor = {
                v: "left",
                h: "bottom",
                vOffset:0,
                hOffset:0
            }
            var top = 0;
            var left = 0;

            if(_attached.attr("data-modal-v") !== undefined){
                attachedAnchor.v = _attached.attr("data-modal-v")
            }

            if(_attached.attr("data-modal-h") !== undefined){
                attachedAnchor.h = _attached.attr("data-modal-h")
            }
            if(_attached.attr("data-modal-v-offset") !== undefined){
                attachedAnchor.vOffset = parseFloat(_attached.attr("data-modal-v-offset"))
            }

            if(_attached.attr("data-modal-h-offset") !== undefined){
                attachedAnchor.hOffset = parseFloat(_attached.attr("data-modal-h-offset"))
            }



            switch(attachedAnchor.v){
                case "left":
                    left = _attached.offset().left + attachedAnchor.vOffset;
                break;
                case "center":
                    left = (_attached.offset().left + (_attached.outerWidth()/2)) - (_modalContainer.outerWidth()/2) + attachedAnchor.vOffset;
                break;
                case "right":
                    left = (_attached.offset().left + _attached.outerWidth()) - _modalContainer.outerWidth() + attachedAnchor.vOffset;
                break;
            }

            switch(attachedAnchor.h){
                case "top":
                    top = _attached.offset().top + attachedAnchor.hOffset;
                break;
                case "middle":
                    top = (_attached.offset().top + (_attached.height()/2)) + attachedAnchor.hOffset;
                break;
                case "bottom":
                    top = _attached.offset().top + _attached.height() + attachedAnchor.hOffset;
                break;
            }

            _modalContainer.css({
                top: top,
                left: left
            })
        }
    }

    self.open = function(url){
        _open = true;
        $.get(url,function(result){
            _modal.addClass("is-active");
            self.updatePosition();
            _modalContent.html(result);
        })
    }

    self.close = function (){
        _open = false;
        _modal.removeClass("is-active");
        _modalContent.html("");
        _attached = undefined;
    }

    self.attachTo = function(link){

        link.on("click",function(e){
            e.preventDefault();
            _attached = $(this);
            var path = $(this).attr("hrefValue");
            self.open(path, $(this));
        })
    }

    //$(document).ready(_init);
    $(document).ready(function(){
        try {
            _init();
        }catch(err){
        }
    });
    
    return self;
}
