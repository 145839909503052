/**
 * The pillar list bar of the search
 */
"use strict";

function checkAllArticles(value){
    // check all buttons
    $(".--check-buttons").prop('checked', value);
    $(".--check-buttons").each(function(){
        hideShowArticles(this);            
    });
}
    
function hideShowArticles(that){
    var id=$(that).prop('id');
    var value=$(that).is(":checked");
    
    if (value){
        $("."+id).fadeIn();
    } else {
        $("."+id).fadeOut();            
    }
}


$(document).ready(function(){
    // initial situation
    $("#All").prop('checked', true);
    $(".--check-buttons").prop('checked', true);


    $("#All").change(function(event){
        checkAllArticles($(this).is(":checked"));        
    });

    $(".--check-buttons").change(function(event){

        var $inactiveFilters = $(".--check-buttons:not(:checked)"),
        $activeFilters = $(".--check-buttons:checked"),
        $allFilters = $("#All");

        //Hide all items
        $(".block-grid__item").hide();

    	//Show items where the filter is active
    	$activeFilters.each(function(){
            hideShowArticles(this);            
        });

    	//Check/Uncheck all button filter
    	if ($inactiveFilters.length > 0){
    		$allFilters.prop('checked', false);
    	}else{
    		$allFilters.prop('checked', true);
        }
    	
    });

	//Submit search form
	$( "#searchForm" ).submit(function( event ) {
	  var sVal = $( "form input.search__input__query" ).val();
	  if (sVal !== "" && sVal !== "*") {
	    return;
	  }
	  event.preventDefault();
	});
  		    
});
