/*
 */
"use strict";
var Utils= require("../Utils");

var getScript = function(url,cb,sync) {
    //document.write('<' + 'script src="' + url + '"><' + '/script>');

    var fileref=document.createElement('script');
    fileref.setAttribute("type","text/javascript");
    fileref.setAttribute("src", url);
    fileref.onload=cb;
    if (sync){
        fileref.async = false; 
    } else {
        fileref.async = true;
    }
    document.getElementsByTagName("head")[0].appendChild(fileref);
    
};

var getScriptText = function(text) {
    var fileref=document.createElement('script');
    fileref.setAttribute("type","text/javascript");
    fileref.text=text
    document.getElementsByTagName("head")[0].appendChild(fileref);
    
};

// function initWidget_old(){
//     getScript("http://www.stib-mivb.be/tripplanner/api/js?l="+Utils.getLanguage(),function(){
//         hastinfo.api.travelPlansWidget({ element: "TravelPlansWidget" });
// //        hastinfo.api.routeTimetableWidget({ element: "RouteTimetableWidget" });
// //        hastinfo.api.nextPassingTimesWidget({ element: "NextPassingTimesWidget" });
// //        hastinfo.api.stopTimetableWidget({ element: "StopTimetableWidget" });
//     });
// }

function initWidget(url,key,lang){
    var hastinfo = hastinfo || {};        

    var crt;
    if (lang === 'fr'){
        crt="FR"
    } else if (lang === 'nl'){
        crt='NL';
    } else {
        lang='en';
        crt='US';
    }
    
    window.hastinfo=hastinfo;
    hastinfo.configuration = hastinfo.configuration || {};
    hastinfo.configuration.applicationPath = url;
//    hastinfo.configuration.applicationPath = "https://www.stib-mivbTOTO.be/tripplanner/";
    hastinfo.configuration.currentCulture = lang+'-'+crt;

    //getScript(url+'api/_js?v=UmTZrRVA5D43KZM21fZfzaG6fznZsV-impUHr5rfFUY1',function(){
    getScript(url+'api/_js?v='+key,function(){
        var googleUrl='https://maps.googleapis.com/maps/api/js?region=ca&key=AIzaSyC7IazJSjaBWIXDXZl2RLJ9ji581YRQKvk&v=3&libraries=places&language='+lang;
        getScript(googleUrl,function(){

            if (typeof hastinfo !== 'undefined' && typeof hastinfo.api !== 'undefined' && typeof hastinfo.api.travelPlansWidget !== 'undefined'){
                hastinfo.api.travelPlansWidget({ element: "TravelPlansWidget" });
            }
//            hastinfo.api.routeTimetableWidget({ element: "RouteTimetableWidget" });
//            hastinfo.api.nextPassingTimesWidget({ element: "NextPassingTimesWidget" });
//            hastinfo.api.stopTimetableWidget({ element: "StopTimetableWidget" });            
        });
    });
};


$(document).ready(function(){
    var widget = $("#TravelPlansWidget");
    if(widget.length && typeof globalGiroUrl !== 'undefined' ){
        // Option 1
        // var url='http://webtest.stib-mivb.be/tripplanner/';
        // //var url='http://www.stib-mivb.be/tripplanner/';

        if (window.location.protocol === "https:") {
            globalGiroUrl=globalGiroUrl.replace("http:","https:");
        }
        
        initWidget(globalGiroUrl,globalGiroKey,Utils.getLanguage());

        // OPtion 2
        //getScript("http://www.stib-mivb.be/tripplanner/api/js?l="+Utils.getLanguage(),function(){
        //     hastinfo.api.travelPlansWidget({ element: "TravelPlansWidget" });
        // },true);
        
    }
});
