var React = require("react");
var Reflux = require("Reflux");
var BoxLinesActions = require("./BoxLinesActions");
var BoxLinesStore = require("./BoxLinesStore.js");

var TransportType = React.createClass({
    mixins: [Reflux.connect(BoxLinesStore, 'store')],
    getInitialState:function(){
        return {};
    },
    select:function(e){
        e.preventDefault();
        BoxLinesActions.selectTransport(this.props.name);
    },
    classes:function(){
        var classes =  "btn btn--rect";

        if(this.state.store.selectedTransport === this.props.name){
            classes += " btn--secondary-dark btn--active";
        } else {
            classes += " btn--grey-light btn--hl-secondary";
        }

        return classes;
    },
    render:function(){
        return (
            <li>
                <a href="#" className={ this.classes() } onClick={ this.select }>
                    { this.props.short }
                </a>
            </li>
        );
    }
})

module.exports = TransportType;
