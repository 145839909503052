var React = require('React');

function Ticket(props){
    return <div className={'ticket'}>
        <Header header={props.ticket.header} isResponsive={props.isResponsive}/>
        <Body lines={props.ticket.body} button={props.ticket.button}/>
    </div>
}

function Header(props){
    var flag = props.header.flagText ? <Flag text={props.header.flagText} backgroundColor={props.header.flagBackgroundColor} textColor={props.header.flagTextColor}/> : null;
    return <div className={'header'} style={{backgroundImage: 'url("/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build//images/pricecomparator/'+ (props.isResponsive ? "m-" : "") + props.header.backgroundImage+'")'}}>
        {flag}
        <Title text={props.header.title}/>
    </div>
}

function Flag(props){
    return <div className={'flag'} style={{backgroundColor: props.backgroundColor, color: props.textColor}}>
        <span>{props.text}</span>
        <div className={"flag-tip"} style={{backgroundColor: props.backgroundColor}}></div>
    </div>
}

function Title(props){
    return <h2 className={"title"} >{props.text}</h2>
}

function Body(props){
    return <div className={"center-area"}>
        <Info text={props.lines.line1}/>
        <Info text={props.lines.line2}/>
        <Info text={props.lines.line3}/>
        <Info text={props.lines.line4}/>
        <Info text={props.lines.line5}/>
        <Button text={props.button.text} link={props.button.link}/>
    </div>
}

function Info(props){
    return <div className={"info"} dangerouslySetInnerHTML={{__html:props.text}}></div>
}

function Button(props){
    return <a className={"button"} href={props.link} dangerouslySetInnerHTML={{__html:props.text}}></a>
}

module.exports = Ticket;

