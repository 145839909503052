var slick = require("slick-carousel");

module.exports = new function Alerts(){
    var self = this;
    var _alert;
    var _alertClose;
    var _alertCarousel;
    function _init(){
        _alert = $(".alert");
        _alertClose = $(".alert__close");
        _alertCarousel = $(".alert__carousel");

        _delegate();
        _carousel();
    }

    function _delegate(){
        _alertClose.on("click",function(e){
            e.preventDefault();
            $(this).closest(".alert").hide();
        })
    }

    function _carousel(){
        _alertCarousel.slick({
            dots:true,
            arrows:false,
            autoplay: true
        });
    }

    // $(document).ready(_init);
    $(document).ready(function(){
        try {
            _init();
        }catch(err){
        }
    });


    self.init=function(el){
        if (el && el.length){
            var elements=el.find(".alert__carousel");
            elements.slick({
                dots:true,
                arrows:false,
                autoplay: true
            });
        }
    };
    
    return self;
}
