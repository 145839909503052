var Utils= require("../Utils");

var isTracketSet = false;
var alreadyClickedItem = [];
var gaTrackerId = "UA-54353245-4";

var GA_EVENT_TYPE = {
	faq: 'faq',
	download: 'download'
}

function ifGaExist(){
	return typeof ga !== 'undefined'
}

function createTracker(){
	if (ifGaExist() && !isTracketSet){		
		ga('create', gaTrackerId, 'auto', 'gaTracker');
		isTracketSet = true;
	}
}



function track(eventCategory, eventAction, eventLabel, itemId){
	if(ifGaExist()){
		eventLabel = Utils.getLanguage().toUpperCase() + "__" + eventLabel
		ga('gaTracker.send', {
			'hitType': 'event',         
			'eventCategory': eventCategory, 
			'eventAction': eventAction,     
			'eventLabel': eventLabel,
			hitCallback: function(){
				alreadyClickedItem.push(itemId)
			}
		});
	}
}

$(document).on('GATrack', {},function(e, type, id, label){
	if ($.inArray(id,alreadyClickedItem) === -1){
		
		createTracker();
		
		switch(type){
			case GA_EVENT_TYPE.faq: 
				track('click-faq', 'open question', label, id)
				break;
			case GA_EVENT_TYPE.download:
				track('download', 'download-file', label, id)
				break;
			default:
				break;
		}		
	}
})

$(document).ready(function(){
	$('[question-id^="faqQuestion-"]').click(function(e){ 
		var question = $(this);
		$(document).trigger('GATrack', [GA_EVENT_TYPE.faq, question.attr('id'), question.attr('id')]);
	});
	
	$(".ga-track-download").click(function(e){
		var download = $(this);
		$(document).trigger('GATrack',[GA_EVENT_TYPE.download, download.attr('id'), download.attr('type')])
	})
});
