require("jquery");
require("select2");
require("jquery-placeholder");
require("jquery-ui/ui/widgets/datepicker");

var clearableInput = require('../partials/ClearableInput'),
    parsley = require("parsleyjs")
//    localization = {
//        nl: require('../localization/nl.js').validation,
//        fr: require('../localization/fr.js').validation
//    };



module.exports = new function Forms(){
    var self = this;
    var _select;
    var _datepicker;
    var _fileupload;
    var _inputText;
    var _inputIconRight;

    function _init(){
        $('.js-clearableinput').each(function(ndx, el) {
            clearableInput(el);
        });

        _inputIconRight = $(".form__input--icon-right span[class^='icon']");

        if ($("input[type^='text']").length>0){
            _inputText = $("input[type^='text']").placeholder();
        }
        
        _fileupload = $("input[type^='file']");
        _fileuploadChange();

        _datepicker= $(".form__input--datepicker input, .stib-form-application .date");
        _datepicker.datepicker();

        _select = $("select");
        _select.select2();

        _inputIconRight.on("click", function(){
            $(this).parent().find("input").focus();
        })

        for (var lang in localization) {
            if (localization.hasOwnProperty(lang)) {
                parsley.addMessages(lang, localization[lang]);
            }
        }

        parsley.setLocale($('html').attr('lang'));

        $('.js-form-validate').parsley({
            trigger: 'change',
            successClass: 'form__input--success',
            errorClass: 'form__input--error',
            errorsWrapper: '<div class="form__input__error"></div>',
            errorTemplate: '<span></span>',
            classHandler: function(el) {
                return el.$element.closest('.form__input');
            },
            errorsContainer: function(el) {
                return el.$element.closest('.form__input');
            },
            excluded: 'input.select2-search__field',
            triggerAfterFailure: 'input change'
        });
    }

    function _fileuploadChange(){
        _fileupload.on("change",function(){
            $(this).closest(".form__input").find(".form__input__file").text( $(this).val() );
        })
    }


    $(document).ready(function(){
        try {
            _init();
        }catch(err){
        }
    });
    return self;
}
