var slick = require("slick-carousel");
var compactModal = require("./compactModal.js");
var getI18n = require("../general/i18n");

module.exports = new function Header() {
    var self = this;
    var _header;
    var _headerNavigation;
    var _mainNavigationItems;
    var _topNavigation;
    var _topNavigationLinks;
    var _stickyOffset = 54;
    var _window = $(window);

    function _init() {
        _header = $(".header");
        _mainNavigationItems = $(".main-navigation__item");
        _headerNavigation = $(".header__navigation");
        _topNavigation = $(".top-navigation");
        _topNavigationLinks = $(".top-navigation a");

        var carousel = $(".carousel")
        if (carousel.length>0){
        	carousel.slick({
                dots: true,
                speed: 800,
                useTransform: true,
                cssEase: 'ease-in-out',
                prevArrow: '<button class="slick-prev slick-arrow" aria-label="'+getI18n('sliderButton.previous')+'" type="button" style="display: block;"></button>',
                nextArrow : '<button class="slick-next slick-arrow" aria-label="'+getI18n('sliderButton.next')+'" type="button" style="display: block;"></button>'
            });
        }
        var showUp = $(".show-up")
        showUp.fadeIn();
        showUp.each(function(){
            $(this).removeClass("show-up");
        });
        _delegate();
        _onFocusExpanded();
    }

    function _delegate() {
        _mainNavigationItems.on("mouseenter", self.expand);
        _mainNavigationItems.on("mouseleave", self.collapse);

        _window.scroll(self.scrollUpdate);

        compactModal.attachTo($(".my-stib a"));
    }

    function _onFocusExpanded() {
        $('.pilars a, .main-navigation__item a').on('focus', function(ev) {
            $(this).closest(".main-navigation__item").addClass("is-focused");
            self.expand();
        });
        $('.pilars a, .main-navigation__item a').on('blur', function(ev) {
            $(this).closest(".main-navigation__item").removeClass("is-focused");
            self.collapse();
        });
    }

    self.expand = function(e) {
        _header.addClass("header--navigation-expanded");
    }

    self.collapse = function(e) {
        _header.removeClass("header--navigation-expanded");
    }

    self.scrollUpdate = function() {
        if (_window.scrollTop() > _stickyOffset) {
            _headerNavigation.addClass("header__navigation--fixed");
        } else {
            _headerNavigation.removeClass("header__navigation--fixed");
        }
    }

    // $(document).ready(_init);
    $(document).ready(function(){
        try {
            _init();
        }catch(err){
        }
    });

    
}
