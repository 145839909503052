var Utils = require("../Utils")

function geti18n(key,params){
	var lang = Utils.getLanguage()
	
	var result = ''
		
	if(key){
		result = i18nmap[lang][key]
		if(params){
			for(var i = 0; i < params.length; i++){
				result = result.replace('${'+i+'}', params[i])
			}
		}
	}
	
	return result
}

var i18nmap = {
	fr: {
		'hour.short.lower':'h',
		'time.hh.mm':'${0}h${1}',
		'warning':'Attention',
		'realtime.vehicleInStation':'Vehicule en station',
		'line': 'Ligne',
		'boxlines.direction': 'Direction',
		'boxlines.occupancyLink':'Niveau d\'affluence',
		'boxlines.all':'Tous',
		'boxlines.bus':'Bus',
		'boxlines.metro': 'Métro',
		'boxlines.tram': 'Tram',
		'boxlines.filter': 'Filtrer par transport',
		'sliderButton.next':'Suivant',
		'sliderButton.previous': 'Précédent',
		'productSelector.ourSelection':'Notre sélection',
		'productSelector.moreinfo':'Infos',
		'productSelector.buyonline':'Acheter en ligne',
		'productSelector.priceStartingFrom' : 'à partir de',
		'productSelector.tarif': 'Tarif',
		'productSelector.button.back': 'Retour',
		'tokyo.search.no-data':'Désolé, nous ne disposons pas de suffisamment de données pour vous informer sur le niveau d\'affluence ${0}.',
		'tokyo.search.no-data.1':'du lundi, mardi, jeudi ou vendredi',
		'tokyo.search.no-data.1B':'de la semaine',
		'tokyo.search.no-data.3':'du mercredi',
		'tokyo.search.no-data.6':'du samedi',
		'tokyo.search.no-data.7':'du dimanche',
		'tokyo.status.level.0':'Aucune donnée disponible',
		'tokyo.status.level.1':'Faible',
		'tokyo.status.level.2':'Moyen',
		'tokyo.status.level.3':'Élevé',
		'tokyo.search.intro.title':'Evitez les heures d’affluence',
		'tokyo.search.intro.content':"Votre sécurité et votre confort sont notre priorité. Eviter les heures d’affluence en fait partie. Rien de plus simple aujourd’hui grâce à nos données de fréquentation. Recherchez votre ligne de tram/bus ou votre station de métro et découvrez les heures de plus faible affluence.",
		'tokyo.search.search.title':'Recherchez une ligne de tram/bus ou une station de métro (<u>exemple</u> : 53 ou Gare de l’Ouest).',
		'tokyo.search.search.input.placeholder':'Numéro de ligne ou nom d’une station',
		'tokyo.search.result.title.station': 'Niveau d\'affluence estimé pour la station ${0}',
		'tokyo.search.result.title.line': 'Niveau d\'affluence estimé pour la ligne ${0}',
		'tokyo.search.result.source': '<i><u>Source</u> : Les données sont issues des systèmes de comptage de voyageurs installés en station (portillons d’accès) et dans les bus et les trams (capteurs au-dessus des portes). Aucune information à caractère personnel n’est récoltée dans ce cadre. Les données affichées sont basées sur une moyenne des semaines précédentes. À ce jour, certaines lignes ne sont pas encore équipée de systèmes de comptage et ne disposent donc pas encore de données (c’est le cas du métro et des tram/bus les moins récents). Les pics d’affluence ne sont pas disponibles non plus pour les lignes de bus Noctis et les bus navettes (servant en cas de travaux/perturbations).</i>',
		'tokyo.search.evolution': 'Évolution au cours de la journée',
		'tokyo.daySelector.1':'Lu, Ma, Je, Ve',
		'tokyo.daySelector.1B':'Semaine',
		'tokyo.daySelector.3':'Mercredi',
		'tokyo.daySelector.6':'Samedi',
		'tokyo.daySelector.7':'Dimanche',
		'tokyo.dayTime.0':'Matin',
		'tokyo.dayTime.1':'Après-midi et soir',
		'title.rt':'Horaires STIB',
		'title.rt.line': 'Ligne ${0} - stib.be'
	},
	nl:{
		'hour.short.lower':'u',
		'time.hh.mm':'${0}.${1} u.',
		'warning':'Opgelet',
		'realtime.vehicleInStation':'Voertuig in station',
		'line': 'Lijn',
		'boxlines.direction': 'Richting',
		'boxlines.occupancyLink':'Bezettingsgraad',
		'boxlines.all':'Alles',
		'boxlines.bus':'Bus',
		'boxlines.metro': 'Metro',
		'boxlines.tram': 'Tram',
		'boxlines.filter': 'Filter op transport',
		'sliderButton.next':'Volgende',
		'sliderButton.previous': 'Vorige',
		'productSelector.ourSelection':'Onze selectie',
		'productSelector.moreinfo':'Info',
		'productSelector.buyonline':'Online kopen',
		'productSelector.priceStartingFrom' : 'vanaf',
		'productSelector.tarif': 'Tarief',
		'productSelector.button.back': 'Terug',
		'tokyo.search.no-data':'Sorry, we beschikken niet over voldoende gegevens om informatie over de bezettingsgraad op ${0} te bezorgen.',
		'tokyo.search.no-data.1':'maandag, dinsdag, donderdag of vrijdag',
		'tokyo.search.no-data.1B':'weekdagen',
		'tokyo.search.no-data.3':'woensdag',
		'tokyo.search.no-data.6':'zaterdag',
		'tokyo.search.no-data.7':'zondag',
		'tokyo.status.level.0':'Geen beschikbare gegevens',
		'tokyo.status.level.1':'Laag',
		'tokyo.status.level.2':'Middelmatig',
		'tokyo.status.level.3':'Hoog',
		'tokyo.search.intro.title':'Vermijd de drukste uren op ons net',
		'tokyo.search.intro.content':"Jouw veiligheid en comfort is voor ons de absolute prioriteit. Vandaag bestaat dit ook in het vermijden van de drukste uren. Niets is eenvoudiger dankzij onze bezettingsgegevens. Zoek naar je lijn of station om de verwachte bezettingsgraad te kennen alsook de beste uren om comfortabeler te reizen.",
		'tokyo.search.search.title':'Zoek op tram/buslijn of metrostation om de bezettingsgraad te kennen (<u>bv.</u>: 53 of Weststation).',
		'tokyo.search.search.input.placeholder':'Voer een lijnnummer of metrostation in',
		'tokyo.search.result.title.station': 'Geschatte bezettingsgraad op het station ${0}',
		'tokyo.search.result.title.line': 'Geschatte bezettingsgraad op de lijn ${0}',
		'tokyo.search.result.source': '<i><u>Bron</u>: De gegevens worden verzameld door doorgangsdetectiesystemen aan de poortjes in de stations en aan de deuren van de bussen en trams. Er wordt in dit kader geen enkele persoonsgegevens verzameld en de gegevens zijn helemaal anoniem (berekening van een aantal doorgangen). De weergegeven info is gebaseerd op een kruising van gegevens van de voorbije weken en seizoensgebonden gegevens. Sommige lijnen en stations zijn nog niet uitgerust met sensors.</i>',
		'tokyo.search.evolution': 'Evolutie in de loop van de dag',
		'tokyo.daySelector.1':'Ma, Di, Do, Vr',
		'tokyo.daySelector.1B':'Weekdagen',
		'tokyo.daySelector.3':'Woensdag',
		'tokyo.daySelector.6':'Zaterdag',
		'tokyo.daySelector.7':'Zondag',
		'tokyo.dayTime.0':'Voormiddag',
		'tokyo.dayTime.1':'Namiddag en avond',
		'title.rt':'Dienstregeling MIVB',
		'title.rt.line': 'Lijn ${0} - mivb.be'
	},
	en:{
		'hour.short.lower':'h',
		'time.hh.mm':'${0}:${1}',
		'warning':'Warning',
		'realtime.vehicleInStation':'Vehicle in station',
		'line': 'Line',
		'boxlines.direction': 'Direction',
		'boxlines.occupancyLink':'Occupancy rate',
		'boxlines.all':'All',
		'boxlines.bus':'Bus',
		'boxlines.metro': 'Metro',
		'boxlines.tram': 'Tram',
		'boxlines.filter': 'Filter by transport',
		'sliderButton.next':'Next',
		'sliderButton.previous': 'Previous',
		'productSelector.ourSelection':'Our selection',
		'productSelector.moreinfo': 'Info',
		'productSelector.buyonline':'Buy online',
		'productSelector.priceStartingFrom' : 'from',
		'productSelector.tarif': 'Tarif',
		'productSelector.button.back': 'Back',
		'tokyo.search.no-data':'Sorry, we do not have enough data to provide you with information about the estimated occupancy level on ${0}.',
		'tokyo.search.no-data.1':'Monday, Tuesday, Thursday or Friday',
		'tokyo.search.no-data.1B':'Weekdays',
		'tokyo.search.no-data.3':'Wednesday',
		'tokyo.search.no-data.6':'Saturday',
		'tokyo.search.no-data.7':'Sunday',
		'tokyo.status.level.0':'No data available',
		'tokyo.status.level.1':'Low',
		'tokyo.status.level.2':'Middle',
		'tokyo.status.level.3':'High',
		'tokyo.search.intro.title':'How to avoid the busiest hours on our network',
		'tokyo.search.intro.content':"Your safety and comfort is our top priority. Avoiding travel during the busiest hours currently helps us to remain safe. It couldn’t be easier thanks to our occupancy data! Search your tram or bus line or metro station and find out its expected occupancy. This will allow you to identify the least busy hours to travel more comfortably.",
		'tokyo.search.search.title':'Search a tram or bus line or metro station and find out its expected occupancy (<u>e.g.</u>: 53 or Weststation).',
		'tokyo.search.search.input.placeholder':'Enter a line number or station',
		'tokyo.search.result.title.station': 'Estimated occupancy level on the station ${0}',
		'tokyo.search.result.title.line': 'Estimated occupancy level on the line ${0}',
		'tokyo.search.result.source': '<i><u>Source</u>: The data is collected by passage detection systems installed at the station gates and on the bus and tram doors. No personal information is collected in this context, and the data is completely anonymous (count of passages). It is based on a cross-reference between the previous weeks’ data and the seasonal records. So far, some lines and stations are not yet equipped with sensors.</i>',
		'tokyo.search.evolution': 'Progress through the day',
		'tokyo.daySelector.1':'Mo, Tu, Th, Fr',
		'tokyo.daySelector.1B':'Weekdays',
		'tokyo.daySelector.3':'Wednesday',
		'tokyo.daySelector.6':'Saturday',
		'tokyo.daySelector.7':'Sunday',
		'tokyo.dayTime.0':'Morning',
		'tokyo.dayTime.1':'Afternoon and evening',
		'title.rt':'Timetables STIB-MIVB',
		'title.rt.line': 'Line ${0} - stib-mivb.be'
	}
}


module.exports = geti18n