var moment = require('moment');

var SINGLETON={};

SINGLETON.getParameter=function (name,defaultValue) {
	// http://stackoverflow.com/questions/19491336/get-url-parameter-jquery
	var sPageURL = decodeURIComponent(window.location.search.substring(1)),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;
	
	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');
		
		if (sParameterName[0] === name) {
			return sParameterName[1] === undefined ? defaultValue : sParameterName[1];
		}
	}
	
	return defaultValue;
};

SINGLETON.getParameters=function(){
	var qs=window.location.search.substring(1);
	qs = qs.split('+').join(' ');
	
	var params = {},
		tokens,
		re = /[?&]?([^=]+)=([^&]*)/g,
		arrayNameReg=/(.+)\[\]$/;
	var array;
	// https://regex101.com/
	
	while (tokens = re.exec(qs)) {
		var name=decodeURIComponent(tokens[1]);
		var value=decodeURIComponent(tokens[2]);
		
		if ((array=arrayNameReg.exec(name))!==null){
			name=array[1];
			if (params[name] === undefined ) {
				params[name]=[value];
			} else {
				params[name].push(value);
			}
			
		} else {
			params[name] = value;
		}
	}
	
	return params;
};

SINGLETON.getStringValue=function(params,name,defaultValue){
    if (!params){
        return defaultValue;
    } else {
        var value=params[name];
        if (value === undefined || '' === value ){
            return defaultValue;
        } else {
            return value;
        }
    }
};


/**
 * Id the content of the request match one of the properties of the enums object passed in enums object
 * The value of this propertie is returned.
 */
SINGLETON.getEnumValue=function(params,name,enums,defaultValue){
    var value=SINGLETON.getStringValue(params,name,null);

    if (value === null){
        return defaultValue;
    } else {
        value=enums[value];
        if (value === undefined){
            return defaultValue;
        } else {
            return value;
        }        
    }    
};

SINGLETON.getDateValue=function(params,name,format,defaultValue){
    var value=SINGLETON.getStringValue(params,name,null);

    if (value === null){
        return defaultValue;
    } else {
        try {
            //var date=moment().format(format);
            var date=moment(value, format);

            if (date.isValid()){
                return value;
            } else {
                return defaultValue;
            }
            
            if (date === undefined || date === null){
                return defaultValue;
            } else {
                return date;
            }
        }catch(err){
            return defaultValue;
        }        
    }    
};


SINGLETON.goTo=function(url){
	if (url) {
		window.location.assign(url);
	} else {
		log.error("The url is null");
	}
};

module.exports = SINGLETON;
