/*
 */
"use strict";
var React = require("react");
var ReactDOM = require("react-dom");

var Reflux = require("reflux");

var Utils = require("../Utils");

var moment = require('moment');

var Store = require("./Store");
var Actions = require("./Actions");

var LinesList = require("./LinesList.jsx");
var ViewLine = require("./ViewLine.jsx");
var ViewError = require("./ViewError.jsx");

var AjaxManager = require('./AjaxManager');
var Scroll = require('../general/Scroll');
var getI18n = require("../general/I18n")

require("jquery-ui/ui/widgets/datepicker");

var TIME_TO_EXPIRE=20000; // see Store.VEHICULE_TTL  

var FADE_TIME=200;

$.datepicker.regional.fr = {
	closeText: "Fermer",
	prevText: "Précédent",
	nextText: "Suivant",
	currentText: "Aujourd'hui",
	monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin",
		"juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
	monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
		"juil.", "août", "sept.", "oct.", "nov.", "déc." ],
	dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
	dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
	dayNamesMin: [ "D","L","M","M","J","V","S" ],
	weekHeader: "Sem.",
	dateFormat: "dd/mm/yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "",
    dateStart: function() { return moment().add('days', 0) },
    dateEnd: function() { return moment().add('days', 10) }
};

$.datepicker.regional.nl = {
	closeText: "Sluiten",
	prevText: "←",
	nextText: "→",
	currentText: "Vandaag",
	monthNames: [ "januari", "februari", "maart", "april", "mei", "juni",
	"juli", "augustus", "september", "oktober", "november", "december" ],
	monthNamesShort: [ "jan", "feb", "mrt", "apr", "mei", "jun",
	"jul", "aug", "sep", "okt", "nov", "dec" ],
	dayNames: [ "zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag" ],
	dayNamesShort: [ "zon", "maa", "din", "woe", "don", "vri", "zat" ],
	dayNamesMin: [ "zo", "ma", "di", "wo", "do", "vr", "za" ],
	weekHeader: "Wk",
	dateFormat: "dd/mm/yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "" };

$.datepicker.regional.en = {
	closeText: "Done",
	prevText: "Prev",
	nextText: "Next",
	currentText: "Today",
	monthNames: [ "January","February","March","April","May","June",
	"July","August","September","October","November","December" ],
	monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
	dayNames: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
	dayNamesShort: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
	dayNamesMin: [ "Su","Mo","Tu","We","Th","Fr","Sa" ],
	weekHeader: "Wk",
	dateFormat: "dd/mm/yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "" };


var settings = {
//    loaded:false,
//    showLineList:false,
    updating:false,
    expired:false,
    expirationTime:0
}


var View = React.createClass({
    mixins: [Reflux.connect(Store, 'store')],
    componentDidMount: function() {
        var scope = this;
		
        this.listenToMany(Actions);

    },
    onShowLineList: function(){
//        settings.showLineList=true;
//        settings.loaded=true;
        // settings.info=null;
		this.update();
    },
    onShowLine: function(info){
//        settings.showLineList=false;
//        settings.loaded=true;

       
        // settings.info=info;
        this.update();
        
        var showDetails = false;
        
        var days = [];
        var choosenDayPeriod = 0;
        var initialDayType = 0;

        function getDayPositionInArray(daysArray, dayType){
            var dayIndexInArray = 0;
            daysArray.forEach(function(day, index){
                if(day.dayType === dayType){
                    dayIndexInArray = index
                }
            })
            return dayIndexInArray
        }

        $('[id^=occupancy_]').each(function(){

            var occupancyObject = {
                dom: $(this),
                detail: $(this).find("[id^=occupancyDetails_]"),
                dayPeriod: $(this).find("[id^=occupancyPeriod_]"),
                dayType: $(this).attr('attr-day-type'),
                toggle: function(isNewDay){
                    if(this.dom.hasClass('hide')){
                        if(showDetails){
                            this.showDetails(isNewDay);
                        } else {
                            this.hideDetails(isNewDay);
                        }

                        this.dom.removeClass('hide');
                        this.dom.addClass('show')
                        
                    } else {

                        if(showDetails){
                            this.showDetails(isNewDay);
                        } else {
                            this.hideDetails(isNewDay);
                        }

                        this.dom.removeClass('show');
                        this.dom.addClass('hide')
                    }
                },
                hide: function(){
                    this.dom.removeClass('show');
                    this.dom.addClass('hide')
                },
                toggleDetails: function(){
                    showDetails = !showDetails
                    if(this.detail.hasClass('hide')){
                        this.showDetails();
                        $('[id^=occupancyMoreArrow_]').removeClass("icon-arrow-bottom");
                        $('[id^=occupancyMoreArrow_]').addClass("icon-arrow-top");
                        $('#occupandyDayPeriodSwitch').fadeIn()
                    } else {
                       this.hideDetails();
                        $('[id^=occupancyMoreArrow_]').removeClass("icon-arrow-top");
                        $('[id^=occupancyMoreArrow_]').addClass("icon-arrow-bottom");
                        $('#occupandyDayPeriodSwitch').fadeOut();
                    }
                },
                setTimePeriod: function(period){
                    if(period == 0){
                        this.dom.find("#occupancyPeriod_" + 1).hide();
                        $('#dayPeriod_'+ 1).hide();
                        this.dom.find("#occupancyPeriod_" + period).show();                                      
                    } else {
                        this.dom.find("#occupancyPeriod_" + 0).hide();
                        $('#dayPeriod_'+ 0).hide();
                        this.dom.find("#occupancyPeriod_" + period).show();
                    }
                    
                    $('#dayPeriod_'+period).show();
                },
                showDetails: function(isNewDay){
                    if(isNewDay === true){
                        this.setTimePeriod(0)

                        this.detail.removeClass('hide')
                        this.detail.addClass('show')
                        this.detail.show();
                    } else {
                        this.setTimePeriod(choosenDayPeriod)

                        this.detail.hide();       
                        this.detail.removeClass('hide');
                        var detail = this.detail
                        this.detail.slideDown({complete:detail.addClass('show')});
                    }

                    
                    
                },
                hideDetails: function(isNewDay){
                   
                    if(isNewDay){
                        this.detail.hide();
                        this.detail.removeClass('show');
                        this.detail.addClass('hide')
                    } else {
                        var detail = this.detail
                         this.detail.slideUp({complete:function(){
                            detail.removeClass('show');
                            detail.addClass('hide')
                        }
                        });
                    }
                }
            }
            days.push(occupancyObject);

            // we need to check which period time we are in to highlight/show all the necessary stuff
            if($(this).attr('attr-initial')){
                initialDayType = $(this).attr("attr-day-type")
                var today = new Date();
                var hourNow = today.getHours();
                var timePeriod = $(this).find("[attr-hour="+hourNow+"]")
                if (timePeriod.length > 0){
                    timePeriod.addClass("hour__current");
                    var parent = timePeriod.parents('[id^=occupancyPeriod_]')
                    choosenDayPeriod = parent.attr("attr-period")
                    parent.show();                  
                }
                $('#dayPeriod_'+choosenDayPeriod).show();
            }
        })

        var occChange = $('[id^=occupancyDayTypeSelect_]');

        var occPreviousValue
        var indexDay
        occChange.each(function(){
        	if($(this).attr('attr-initial')){
                occPreviousValue = $(this).attr('attr-value')
                indexDay = getDayPositionInArray(days, occPreviousValue);
            }

        	$(this).click(function(e){
                var occValue = $(this).attr('attr-value')

                if(occValue != occPreviousValue){
                    
                    indexDay = getDayPositionInArray(days, occPreviousValue);

                    if(days.length > 0){
                        days[indexDay].toggle(true)
                    }
                    
                    $(occChange[indexDay]).removeClass("selected")

                    indexDay = getDayPositionInArray(days, occValue);
                    $(occChange[indexDay]).addClass("selected")

                    if(days.length > 0){
                        days[indexDay].toggle(true)
                    }                   

                    occPreviousValue = occValue ;
                }                                        
        	})
        })    		
        
        $('[id^=occupancyMore_]').each(function() {
            $(this).click(function(){
                var dayType = $(this).attr('attr-value');
                if(dayType != initialDayType){
                    choosenDayPeriod = 0;
                }
                days[getDayPositionInArray(days, dayType)].toggleDetails()
            })
        })

        var dayPeriodSwitchers = $('[id^=dayPeriodSwitcher_');

        dayPeriodSwitchers.each(function(){

            var toDayPeriod = $(this).attr("attr-to");

            $(this).click(function(){
                if(toDayPeriod == 0){
                    $('#dayPeriod_1').hide();                  
                    $('#dayPeriod_0').show();
                } else {
                    $('#dayPeriod_0').hide();
                    $('#dayPeriod_1').show()
                }

                days[indexDay].setTimePeriod(toDayPeriod)
            })
        })
        
        $('#occupancyTooltipTrigger').hover(function(){
            $('#occupancyTooltip').show();
        },
        function(){
            $('#occupancyTooltip').hide();
        })
		
    },
    setWtValue: function(id,value){
        console.error(value);
        
        if (value === '0' || value === '00' || value==0 ){
            $(id).html('<span class="icon-now icon--medium time" aria-hidden="true"></span>');
        } else {
            $(id).html('<span class="time">'+value+'\'</span>');
        }
    },
    onShowRealTime: function(rt){
        var style=Store.getTransportTypeCSS();
        // The vehicle position
        // Clean the old postion if any
        $(".thermometer__stop").each(function(){
            $(this).removeClass("thermometer__stop--realtime");
            $(this).removeClass(style);
        });
        // Add the new vehicle position
        for (var property in rt.vh) {
//            $("#"+property).addClass("thermometer__stop--realtime");
            var stop = $("[id^="+property+"]")

            stop.addClass("thermometer__stop--realtime "+style);
            stop.find("a").first().append('<span class="sr-only">'+ getI18n('realtime.vehicleInStation') + '</span>')
        }
        // The waiting time
        // for (var property in rt.wt) {
        //     var id="#wt-"+property+"-1";
        //     this.setWtValue(id,rt.wt[property].al);
        //     id="#wt-"+property+"-2";
        //     this.setWtValue(id,rt.wt[property].md);            
        // }
        $("#real-time-table").fadeOut(FADE_TIME,function(){
            $("#real-time-table").html(rt.wt);
            $("#real-time-table").fadeIn(FADE_TIME);
        })
        settings.expired=false;
        this.setExpirationTime();
    },
    setExpirationTime:function(){
        settings.expirationTime=(new Date()).getTime()+TIME_TO_EXPIRE;
        settings.expired=false;

        this.showExpired();
    },
    showExpired:function(){
        if (settings.expired === true){
            // expired shoe the message
            $("#expired-message").show();
            // Store.refreshRealTime();
            
        } else {
            // not expired hide the message
            $("#expired-message").hide();
             var now = new Date();
             var minutes =  (now.getMinutes() < 10 ? '0' : '') + now.getMinutes() 
            $('#last-updated-time').html(getI18n('time.hh.mm',[now.getHours(),minutes]))
            var that=this;
            // if need to launch the procss
            if (!settings.updating){
                settings.updating=true;
                setTimeout(function(){
                    try {
                        var currentTime=(new Date()).getTime();
                        if (currentTime>settings.expirationTime) {
                            settings.expired=true;
                        }
                        that.showExpired();
                    }finally {
                        settings.updating=false;
                    }
                },TIME_TO_EXPIRE);
                
            }            
        }
    },
    

    render:function(){

        if (!Store.isLoaded()){
            Actions.showError(i18n("The page is loading ..."));

            return (
                <div>
                <ViewError message={i18n("The page is loading ...")}/>
                </div>
            );
        } else if (Store.isShowLineList() ){

            return (
                <div>
                <ViewError />
                <LinesList />
                </div>
            );
        } else {
            return (
                <div>
                <ViewError />
                <ViewLine />
                </div>
            );
        }
    },
    update: function(showLineList){
        var el = $("#view");
        if(el.length){
            // ReactDOM.render(<View />, el.get(0));
            this.forceUpdate();
            
            // This render the lines messages
            Store.renderMesages();
            
            //            $.datepicker.setDefaults( $.datepicker.regional.fr );
            $.datepicker.setDefaults( $.datepicker.regional[Utils.getLanguage()] );

            // $.datepicker.setDefaults( $.datepicker.regional[ "fr" ] );
            
            var _datepicker= $(".form__input--datepicker input, .stib-form-application .date");
            _datepicker.datepicker({
                minDate: 0,
                maxDate: 10,
                onSelect: function(dateText, inst) {
                    var date = $(this).val();
                    Store.setMode('tt',date);                    
                }
            });

            // The calendar
            var _inputIconRight = $(".form__input--icon-right span[class^='icon']");
            
            _inputIconRight.on("click", function(){
                $(this).parent().find("input").focus();
            })

            // the scroll
            if (Store.hasSelectedStop()){
                // var windowHeight=$(window).height();
                
                Scroll.scrollToElement($("#more-info"),200);
            }

            // The selected Time Table hour
            if (Store.hasSelectedStop() && Store.isToday()){
                // Gets the current hour
                var currentHour= (new Date()).getHours();
                // The css has been added
                var className="#tt_hour_"+currentHour;
                // $("#tt_hour_"+currentHour).addClass("selected-tt-hour");
                $(className).attr('style', 'font-weight: bold;background-color: #fbfbfb;border-radius: 3px;'); // fbfbfb cfd0d0                
            }
            
            
        }    
    }
});

function wait(that,ajax,html){
    if (!ajax){
        return ;
    } else {
        var target=$(that).data("target");
        if ($(target).length>0){
            if (!AjaxManager.isFinished(ajax)) {
                //console.log("Fade out");
                // setting the restore function
                if (html) {
                    ajax.restore=function(){
                        $(target).html(html);
                    }
                }
                // starting the fade proc
                $(target).fadeOut(FADE_TIME,function(){
                    if (!AjaxManager.isFinished(ajax)) {
                        //console.log("set html");
                        $(target).html('<span class="icon-history" aria-hidden="true"></span>');
                    }
                    if (!AjaxManager.isFinished(ajax)) {
                        //console.log("set html fade in");
                        $(target).fadeIn(FADE_TIME);
                    }
                });
            }
        }
    }
}


$(document).ready(function(){
    var el = $("#view");
    
    if(el.length){
        ReactDOM.render(<View />, el.get(0));
    }
    
    $( "#view" ).on( "click", "button,a", function( e ) {
        var msg=$(this).data("msg");
        if (msg){
            var val=$(this).data("val");
            // alert(msg+"-"+val);
            
            if ("all" === msg){
                // No wait needed
                Store.showAllLines();                
            } else if ("ref" === msg){
                wait(this,Store.refreshRealTime());
            } else if ("stop" === msg){
                if (val){
                    wait(this,Store.showStop(null,null,val,null),'<span class="icon-long-arrow-right" aria-hidden="true"></span>');
                } else {
                    var line=$(this).data("line");
                    var direction=$(this).data("direction");
                    var stop=$(this).data("stop");
                    wait(this,Store.showStop(line,direction,stop,null),'<span class="icon-long-arrow-right" aria-hidden="true"></span>');
                }
            } else if ("chDir" === msg){
                wait(this,Store.changeDirection(val),'<span class="icon-long-arrow-right" aria-hidden="true"></span>');
            } else if ("tt" === msg){
                wait(this,Store.setMode('tt'),'<span className="icon-calendar"></span>');
            } else if ("rt" === msg){
                wait(this,Store.setMode('rt'),'<span className="icon-clock" aria-hidden="true"></span>');
            } else if ("rtc" === msg){
                wait(this,Store.setMode('rtc'),'<span class="icon--medium icon-subtract text--color-grey-default"></span>');
            } else {
                return;
            }

            e.preventDefault();
            e.stopPropagation();
        } else {
            var guid=$(this).data("article-guid");
            if (guid){
                var url=Utils.getArtigleUri(guid);
                $(this).attr("href",url);
            } else {
                var uri=$(this).data("uri");
                if (uri){
                    var url=Utils.getUri(uri);
                    $(this).attr("href",url);
                }
            }
        }
    });

})

module.exports = View;
    
