var React = require("react");
var BoxLinesActions = require("./BoxLinesActions");
var getI18n = require('../general/I18n')

var Line = React.createClass({
    getInitialState:function(){
        return {};
    },
    lineStyle: function(){
        return "line line--"+this.props.number;
    },
    // lineName: function(){
    //     if (this.props.number){
    //         if (this.props.number.firstIndexOf("N", 0)){
    //             var name=this.props.number.substring(1,this.props.number.length);
    //             return (<span>N<span>{name});
    //         }
    //     }
    //     return this.props.number;        
    // },
    selectLine: function(e){
        e.preventDefault();
        BoxLinesActions.selectLine(this.props);
    },
    render:function(){

        if (this.props.number.indexOf("N")===0){
            var name=this.props.number.substring(1,this.props.number.length);
            return (
                   <a href={this.props.href ? this.props.href : "#"} className={ this.lineStyle() }> 
                   		<span className="sr-only">{getI18n('line')}</span>
                   		<span>N</span>
                   		{name}
                   </a>
            );
        } else {
            return (
                <a href={this.props.href ? this.props.href : "#"} className={ this.lineStyle() }>
                    <span className="sr-only">{getI18n('line')}</span>
                    { this.props.number }
                </a>
            );
        }
    }
})

module.exports = Line;
