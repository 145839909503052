"use strict";
var Reflux = require("reflux");
var Actions= require("./Actions");
var Request= require("./request");
var moment = require('moment');

var AjaxManager = require('./AjaxManager');
var LinesMessages = require('../general/Messages');

var Utils= require("../Utils");
var getI18n = require("../general/I18n")

var EMPTY_ARRAY=[];

var DIRECTIONS={
    'V':'V',
    'v':'V',
    'F':'F',
    'f':'F'
};

var MODES={
    'rt':'rt',
    'RT':'rt',
    'rtc':'rtc',
    'RTC':'rtc',
    'tt':'tt',
    'TT':'tt'
};

var TIME_OUT=30000;
// vh mode time to live in mills
var VEHICULE_TTL=20000; // see View.TIME_TO_EXPIRE

var START="<html><body>";
var END="<\/body><\/html>";
var SECTIONS="<section>";
var SECTIONE="<\/section>";
var DIVS="<div>";
var DIVE="<\/div>";
var WS="[\\s]*";
var WD="[\\w]+";
var ALL="['\\S|\\s]*";


// var REGEXPR_DIV = new RegExp(WS+START+
//                              WS+DIVS+'('+WD+')'+DIVE+
//                              WS+DIVS+'('+WD+')'+DIVE+
//                              WS+SECTIONS+
//                                 WS+DIVS+'('+WD+')'+DIVE+
//                                 WS+DIVS+'('+WD+')'+DIVE+
//                              WS+SECTIONE+
//                              WS+SECTIONS+
//                                 WS+DIVS+'('+WD+')'+DIVE+
//                                 WS+DIVS+'('+WD+')'+DIVE+
//                              WS+SECTIONE+
//                              WS+END+WS,'gm');

var REGEXPR_DIV = new RegExp(WS+START+
                             WS+DIVS+'('+WD+')'+DIVE+
                             WS+DIVS+'('+WD+')'+DIVE+
                             WS+DIVS+'('+WD+')'+DIVE+
                             WS+SECTIONS+
                             '('+ALL+')'+
                             SECTIONE+
                             WS+SECTIONS+
                               '('+ALL+')'+
                             SECTIONE+
                             WS+END+WS,'gm');

var REGEXPR_DIV_2 = new RegExp(WS+DIVS+'('+WD+')'+DIVE+
                             WS+DIVS+'('+WD+')'+DIVE+
                             WS+DIVS+'('+WD+')'+DIVE+
                             WS+SECTIONS+
                             '('+ALL+')'+
                             SECTIONE+
                             WS,'gm');

var REGEXPR_VH = new RegExp(WS+DIVS+'('+WD+')'+DIVE+
                            WS+DIVS+'('+WD+')'+DIVE+
                            WS,'gm');

var REGEXPR_WT = new RegExp(WS+DIVS+'('+WD+')'+DIVE+
                            WS+DIVS+'('+WD+')'+DIVE+
                            WS+DIVS+'('+WD+')'+DIVE+
                            WS,'gm');


    
//var REGEXPR_DIV = new RegExp('^\s*<html><body>\s*<div>([\w]+)<\/div>\s*<div>([\w]+)<\/div>\s*<section>([^.|.]+)</section><section>([^.|.]+)</section><\/body><\/html>\s*$', 'gm');

var data = {
    linesLoaded:false,
    linesList:null, // the lines loaded
    lines:null, // Hash of lines
    
    loaded:false,  // The storage has been loaded
    showLineList:false,  // The storage has been loaded
    
    selectedLine:null, // the selected line id
    selectedDirection:null, // the selected direction : V | F
    selectedStop:null, // the selected direction : V | F
    selectedMode:null, // The stop mode, RealTime:rt, RealTimeCloseStop:rtc or TimeTable:tt, default rt
    selectedDate:null,   // The selected date for the TT
    messages:null,      // the messages
    request:{ // the parameters
    },
    additionalDownloads:'',  // The aditional dowload to use comminf from template
    // The transport types
    transportTypes:[
        { "name": "metro", "short": "M" },
        { "name": "tram", "short": "T" },
        { "name" : "bus", "short": "B" },
        { "name" : "noctis", "short": "N" }
    ],
    // the lines for each transport
    transportLines:{
        // "metro":[],
        // "tram":[],
        // "bus":[],
        // "noctis":[]        
    },

    // Contains the information for each line-direction.
    lineInfoCache:{
    }
};

var Store = Reflux.createStore({
    getInitialState:function(){
    },
    init: function() {
        var that = this;

        this.listenToMany(Actions);
        // Getting the list of lines
        $.getJSON("/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build/data/lines.json",
                  function(lines){
                      that.loadLines(lines);
                      that.displayPage();
                      that.sendHttpOk();
                  }).fail(function(err){
                      that.sendHttpError(err);
                  });

        // Single page application stuff
        var currentPathName=window.location.pathname;
        window.onpopstate = function(event){
            var url=window.location.pathname;

            if (url.indexOf(currentPathName) === 0){
                // alert("Pop to:"+window.location.href);

                that.displayPage();
                event.preventDefault();
                event.stopPropagation();
            }
        };
        
    },

    loadLines:function(lines){
        data.linesList = lines
        data.linesLoaded=true;
        data.selectedLine=null;
        data.lines={};
                  
        // parsing lines
        lines.forEach(function(line,index){
            var id=line.number;
            data.lines[id]=line;
            
            // // detect the selected line
            // if (data.request._line === id){
            //     data.selectedLine=id;
            // }

            // build the transport lines
            var transportType=line.type;
            if (transportType){
                if (data.transportLines[transportType] === undefined){
                    data.transportLines[transportType]=[];
                }
                if (line.number.substring(0,1)==='N'){
                    line.type="noctis";
                    if (data.transportLines["noctis"] === undefined){
                        data.transportLines["noctis"]=[];
                    }
                    data.transportLines["noctis"].push(line);
                } else {
                    data.transportLines[transportType].push(line);
                }
            } else {
                // the line has no transport type
            }
            
        });        
    },

    displayPage:function(){
        // first read the page parameters.
        this.readParameters();

        // It is asked to diosplay a line
        if ( this.isLineValid(data.request._line)){
            this.loadLine(data.request._line,
                          data.request._directioncode,
                          data.request._stop,
                          data.request._mode,                                   
                          data.request._date,
                          false
                         );
        } else {
            // Display the line list
            // Actions.showLineList();
            this.actionsShowLineList();
            if (data.request._line){
                Actions.showError({
                    title:"Line not found",
                    message:"/"
                });
            }            
        }
    },

    selectedLine:function(lineId){
        if ( this.isLineValid(lineId)){
            
            return this.loadLine(lineId,true,null,null,null,null,true);
        } else {
            Actions.showError({
                title:"Line not found",
                message:""
            });
            return null;
        }
    },
    // This returns null or the ajax object to verify when the call has been ended
    loadLine:function(lineId,direction,stop,mode,date,pushUrl,fromClick){
        var that=this;
        var direction=(direction==='F')?'F':'V';
        
        if (this.containsLineInfo(lineId,direction,stop,mode,date)) {
            this.showLineInfo(lineId,direction,stop,mode,date);
            if (pushUrl !== false){
                this.setUrl(this.getLocalUrl(lineId,direction,stop,mode,date));
            }
            if(fromClick){
                this.updateTitle(lineId);
            }
            this.loadRealTime();
            return null;
        } else {
            var url=this.getServiceUrl(lineId,direction,stop,mode,date);

            var ajaxLoadLine=$.ajax({
                url:url,
                timeout:TIME_OUT,
                success:function(lineInfo){
                    that.sendHttpOk();

                    if (AjaxManager.isFinished(ajaxLoadLine)){
                        // This has been aborted
                        return;
                    } else {
                        AjaxManager.finishAjax("LoadLine",ajaxLoadLine);

                        that.storeAndShowLineInfo(lineId,direction,stop,mode,date,lineInfo);                      
                        
                        if(fromClick){
                        	that.updateTitle(lineId)
                        }
                        
                        if (pushUrl !== false){
                            that.setUrl(that.getLocalUrl(lineId,direction,stop,mode,date));
                        }
                        that.loadRealTime();
                    }
                },
                error:function(err){
                    AjaxManager.finishAjax(ajaxLoadLine);
                    that.sendHttpError(err);
                }
            });
            // kill the ajax calls
            AjaxManager.killAllAjax("LoadLine",ajaxLoadLine);

            return ajaxLoadLine;
        }
    },
    updateTitle: function(lineId){
        window.document.title = getI18n('title.rt.line',[lineId])
    },
    getLocalUrl:function(lineId,direction,stop,mode,date){
        return this.getUrl(window.location.pathname,lineId,direction,stop,mode,date);
    },

    getUrl:function(pathname,lineId,direction,stop,mode,date){
//        var url=pathname+"?l="+Utils.getLanguage()+"&_line="+lineId+"&_directioncode="+direction+"&_stop="+((stop)?stop:'');
        var url=pathname+"?l="+Utils.getLanguage()+"&_line="+lineId+"&_directioncode="+direction;

        if (mode){
            url+="&_mode="+mode;

            // apend the date
            if (mode==='tt' && date!=null){
                url+="&_date="+date;
            }

            if (mode==='vh'){
                var time=((new Date()).getTime()/VEHICULE_TTL);
                url+="&_nocache="+Math.floor(time);                
            }
        }
        if (stop){
//            url+="&_stop="+stop+"#more-info";
            url+="&_stop="+stop;
        }


        return url;
    },
    getServiceUrl:function(lineId,direction,stop,mode,date){
//        return this.getUrl("/portal/Lineinfo.jsp",lineId,direction,stop,mode);
        return this.getUrl(Utils.getHoraireServiceUri(),lineId,direction,stop,mode,date);
    },

    onSendHttpError:function(httpError){
        if (httpError.statusText === "abort"){
            // aborted by the user no message
            return;
        } else if (httpError.status === 0){
            Actions.showError({
                title:"Error",
                message:"Timeout"
            });
        } else {
            Actions.showError({
                title:"Error",
                message:httpError.status+" "+httpError.statusText
            });
        }

    },

    sendHttpError:function(httpError){
        this.onSendHttpError(httpError);
    },
    
    sendHttpOk:function(httpError){
        Actions.showError(null);
    },
    
    isLineValid:function(lineId){
        if (lineId && data.lines && data.lines[lineId]!== undefined){
            return true;
        } else {
            return false;
        }
    },

    getLinesForTransportType: function(transportType){
        if (transportType && data.transportLines[transportType]){
            return data.transportLines[transportType];
        } else {
            return EMPTY_ARRAY;
        }
    },
    getTransportTypes: function(){
        return data.transportTypes;
    },

    readParameters:function(){
        var params=Request.getParameters();
        data.request._line=Request.getStringValue(params,'_line',null);
        data.request._stop=Request.getStringValue(params,'_stop',null);
        data.request._directioncode=Request.getEnumValue(params,'_directioncode',DIRECTIONS,'V');
        data.request._mode=Request.getEnumValue(params,'_mode',MODES,'rt');
        
        data.request._date=Request.getDateValue(params,'_date','DD/MM/YYYY',null);
        if (data.request._date === moment().format('DD/MM/YYYY')){
            data.request._date=null;
        }
    },

    /**
     * Lines Info
     */
    containsLineInfo:function(lineId,direction,stop,mode,date){
        var key=lineId+'-'+direction+'-'+stop+'-'+mode+'-'+date;
        if (data.lineInfoCache[key] === undefined){
            return false;
        } else {
            return true;
        }
    },
    showLineInfo:function(lineId,direction,stop,mode,date){
        var key=lineId+'-'+direction+'-'+stop+'-'+mode+'-'+date;

        data.selectedLine=lineId;
        data.selectedDirection=direction;
        data.selectedStop=stop;
        data.selectedMode=mode;
        data.selectedDate=date;
        
        data.messages=null;  // this clear the messages they have to be retrived again
        this.loadMessages();
        
        var info=data.lineInfoCache[key];
        
        // Actions.showLine(info);
        this.actionSshowLine(info);


    },
            
    getLineInfo:function(){
        var key=data.selectedLine
            +'-'+data.selectedDirection
            +'-'+data.selectedStop
            +'-'+data.selectedMode
            +'-'+data.selectedDate;
        
        return data.lineInfoCache[key];        
    },

    storeAndShowLineInfo:function(lineId,direction,stop,mode,date,info){
        // do not cache othe rdates that the current one (date==null)
        var key=lineId+'-'+direction+'-'+stop+'-'+mode+'-'+date;

        data.additionalDownloads=$("#additional-downloads").html();
        info=info.replace("<!--ADDITIONAL_DOWNLOADS -->",data.additionalDownloads);
        
        data.lineInfoCache[key]=info;
        
        data.selectedLine=lineId;
        data.selectedDirection=direction;
        data.selectedStop=stop;
        data.selectedMode=mode;
        data.selectedDate=date;

        data.messages=null;  // this clear the messages they have to be retrived again
        this.loadMessages();
        
        // Actions.showLine(info);
        this.actionSshowLine(info);

    },

    setUrl:function(url){
        // alert("Push:"+url);
        window.history.pushState(null,null, url);
    },

    
    /**
     * Actions
     */    
    showAllLines:function(){
        data.selectedLine=null;
        data.selectedDirection=null;
        data.selectedStop=null;
        data.selectedMode=null;
        data.selectedDate=null;
        
        // window.history.pushState(null,null, window.location.pathname+"?l="+Utils.getLanguage());
        // Actions.showLineList();
        
        window.document.title = getI18n('title.rt')
        this.actionsShowLineList();

        this.setUrl(window.location.pathname+"?l="+Utils.getLanguage());
    },
    changeDirection:function(direction){
        if (data.selectedDirection === direction){
            return null;
        } else {
            data.selectedDirection=direction;
            data.selectedStop=null;
            if (data.selectedMode === 'rtc'){
                data.selectedMode = 'rt'
            }

            return this.loadLine(data.selectedLine,
                                 data.selectedDirection,
                                 data.selectedStop,
                                 data.selectedMode,
                                 data.selectedDate,
                                 null,
                                 true
                                );
        }
    },
    setMode:function(mode,date){
        if (!data.selectedStop){
            data.selectedMode=null;
            data.selectedDate=null;
            return null;
        }
        
//        var modeFlag;
        if (mode === 'rt'){
//            modeFlag=false;
            date=null;
        } else if (mode === 'rtc'){
//            modeFlag=false;
            date=null;
        }
        if (mode === data.selectedMode && date === data.selectedDate){
            return null;
        } else {
            data.selectedMode=mode;

            // if today set date to null
            if (date === moment().format('DD/MM/YYYY')){
                data.selectedDate=null;
            } else {
                data.selectedDate=date;
            }
            
            return this.loadLine(data.selectedLine,
                                 data.selectedDirection,
                                 data.selectedStop,
                                 data.selectedMode,
                                 data.selectedDate
                                );
        }
    },
    showStop:function(line,direction,stop,mode,date){
        if (data.selectedMode === 'rtc'){
            data.selectedMode = 'rt'
        }
        return this.loadLine((line)?line:data.selectedLine,
                             (direction)?direction:data.selectedDirection,
                             (stop)?stop:data.selectedStop,
                             (mode)?mode:data.selectedMode,
                             (date)?date:data.selectedDate);
    },

    /**
     * REAL Time
     */
    loadRealTime:function(){
        if (!data.selectedLine){
            return null;
        } else {
            var that=this;
            var url=this.getServiceUrl(data.selectedLine,data.selectedDirection,data.selectedStop,"vh");
            
            var ajax=$.ajax({
                url:url,
                timeout:TIME_OUT,
                success:function(info){
                    that.sendHttpOk();

                    if (AjaxManager.isFinished(ajax)){
                        // This has been aborted
                        return;
                    } else {
                        AjaxManager.finishAjax("Realtime",ajax);

                        var ret=that.parseRealTime(info);
                        that.displayRealTime(ret);
                    }
                },
                error:function(err){
                    AjaxManager.finishAjax("Realtime",ajax);
                    that.sendHttpError(err);
                }
            });
            AjaxManager.killAllAjax("Realtime",ajax);

            return ajax;
        }
    },

    getMode:function(defaultValue){
        if (data.selectedLine){
            var line=data.lines[data.selectedLine];
            if (line){
                var type=line.type;
                if (type === 'metro'){
                    return 'M';
                } else if (type === 'tram'){
                    return 'T';
                } else if (type === 'bus' || type === 'noctis'){
                    return 'B';
                }
            }
        }

        return defaultValue;
    },

    loadMessages:function(){
        // load the messages anly when the line or the stop have been changed.
        // .. app/ejs/portlet/lineview.ejs
        // mostrar o ocultar en funcion de los resultados ....
        // 
        
        var selectedLine=data.selectedLine;
        var selectedStop=data.selectedStop;
        var mode=this.getMode('');
        
        if (selectedLine){
            LinesMessages.getMessage(function(messages){

                data.messages=messages;
                LinesMessages.render(data.messages);
                
            },selectedLine,selectedStop,mode);
        }
    },

    renderMesages:function(){
        if (data.messages){
            LinesMessages.render(data.messages);
        }
    },

    getVh:function(info){
        var ret={};
        var m;
            
        while ((m=REGEXPR_VH.exec(info)) !== null) {
            ret[m[1]]=m[2];
        }        
        return ret;
    },

    getWt:function(info){
        var ret={};
        var m;
            
        while ((m=REGEXPR_WT.exec(info)) !== null) {
            ret[m[1]]={al:m[2],md:m[3]};
        }        
        return ret;
    },

    parseRealTime:function(info){
        if (info){
            var f=info.indexOf("<html><body>"); 
            var l=info.indexOf("</body></html>");

            if (f <0 || l<0 || l<f){
                return null;
            }
            info=info.substring(f+12,l);
            var sf=info.lastIndexOf("<section>");
            var sl=info.lastIndexOf("</section>");

            if (sf <0 || sl<0 || sl<sf){
                return null;
            }
            var vh1=info.substring(0,sf);
            var vh2=info.substring(sf+10,sl);

            var m = REGEXPR_DIV_2.exec(vh1);

            var ret={};
            ret.wt=vh2;

            if (m){
                ret.codeLine=m[1];
                ret.stopId=m[2];
                ret.dest=m[3];
                ret.vh=this.getVh(m[4]);
                
                while ((REGEXPR_DIV_2.exec(info)) !== null) {
                    // just ignore
                }        
            } else {
               // console.log("Error on vh:"+vh1);
            }
            return ret;

        } else {
            return null;
        }
    },

    parseRealTime2:function(info){
        if (info){

            var m = REGEXPR_DIV.exec(info);

            if (m){
                var ret={};
                ret.codeLine=m[1];
                ret.stopId=m[2];
                ret.dest=m[3];
                ret.vh=this.getVh(m[4]);
                //ret.wt=this.getWt(m[5]);
                ret.wt=m[5];

                while ((m=REGEXPR_DIV.exec(info)) !== null) {
                    // just ignore
                }        

                return ret;
            } else {
                console.error(info);
                Actions.showError({
                    title:"Error",
                    message:"No real time available"
                });

                return null;
            }
        } else {
            return null;
        }
    },

    displayRealTime:function(rt){
        if (!rt || rt.codeLine != data.selectedLine){
            // debugger;
            return ;
        } else {
            // if this is not the selected stop not wating time
            if (data.selectedStop != rt.stopId){
                rt.wt={};
            }
            Actions.showRealTime(rt);
        }
    },

    getTransportTypeCSS: function(){
        var line=data.lines[data.selectedLine];
        var type=line.type;
        if (type === 'metro' ){
            return "thermometer__stop--realtime--train";
        } else if (type === 'tram' ){
            return "thermometer__stop--realtime--metro";
        } else {
            return "thermometer__stop--realtime--bus";
        }
    },

    actionsShowLineList:function(){
        data.loaded=true;
        data.showLineList=true;
        Actions.showLineList();
    },

    actionSshowLine:function(info){
        data.loaded=true;
        data.showLineList=false;
        Actions.showLine(info);
    },

    isLoaded:function(){
        return data.loaded;
    },

    isShowLineList:function(){
        return data.showLineList;
    },

    hasSelectedStop:function(){
        return (data.selectedStop);
    },

    isToday:function(){
        return !(data.selectedDate);
    },

    isTimeTableMode:function(){
        return (data.selectedMode === 'tt');
    },
    
    refreshRealTime:function(){
        return this.loadRealTime();
    }
});

module.exports = Store

