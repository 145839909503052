"use strict";
var Reflux = require("reflux");
var SearchActions= require("./SearchActions");
var Request= require("../lines/request");

var TIMEOUT=200;
var MAX_AUTOCOMPLETE_SIZE=5;

var data = {
    lastValueToAutocomplete:'', // The value to use into the autocomplete search

    searchString:'', // This is the the search string we have used to get values in the last search
    pillars:[],      // The pilars
    selectedPillars:[],  // The selected list to uses
    
    autocomplete:[],  // The autocomplete pillars comming from the user text
    timeRunning:false
};

var Store = Reflux.createStore({
    getInitialState:function(){
        var params=Request.getParameters();
        var searchString=Request.getStringValue(params,'search','');
        var pillars=Request.getStringValue(params,'pillars','');

        if (globalPilarList && $.isArray(globalPilarList) ){
            data.pillars=globalPilarList;
        }
        
        // data.value=searchString;
        SearchActions.setSearchTextFieldValue(searchString);

        if (pillars){
            data.selectedPillars=pillars.split(',');
        }
        return data;
    },
    init: function() {
        
    },

    // Clear Button pressed
    clear: function(value) {
        data.autocomplete=[];
        data.selectedPillars=[];
        // SearchActions.autocompletedChanged(data.autocomplete);
    },

    setValueFromAutocomplete: function(value,pillarId) {
        data.autocomplete=[];
        data.selectedPillars=[pillarId];
     },

    // This set the value from user typing
    setValue: function(value) {
        
        if (!value){
            // clearing autocomplete
            this.clear();
            // Trigger the Autocomplete changed
            SearchActions.autocompletedChanged(data.autocomplete);
        } else {
            // This is the value that the "Thread will use to find the pillar"
            // overwrited by sucessives call.
            data.lastValueToAutocomplete=value;
            
            // test if already waiting for autocomplete ...            
            if (data.timeRunning === false){
                data.timeRunning=true;
                var that=this;
                setTimeout(function(){
                    // initial values
                    var currentValue=data.lastValueToAutocomplete;
                    var matchString=currentValue.toLowerCase();
                    var currentLength=currentValue.length;
                    var newAutocomplete=[];
                    var newSelectedPillars=[];
                    
                    try {
                        // start loop on pillar
                        $.each(data.pillars,function(index,pillar){
                            var value=pillar.l;
                            var valueLowerCase=value.toLowerCase();

                            // retreive the automcplete list, find it max list size exeeced
                            if (newAutocomplete.length<=MAX_AUTOCOMPLETE_SIZE){
                                // testing if the search text is included in the pilar
                                var firstIndex=valueLowerCase.indexOf(matchString);
                                if (firstIndex!=-1){
                                    // push autocomplete value
                                    newAutocomplete.push({
                                        prefix:value.substring(0,firstIndex),
                                        value:value.substring(firstIndex,firstIndex+currentLength),
                                        sufix:value.substring(firstIndex+currentLength,value.length),
                                        fullText:value,
                                        id:pillar.id
                                    });
                                    
                                }
                            }
                            // // testing if the search string has the pillar
                            // // To add the pillar to the selected list of pillars
                            // var found=matchString.indexOf(valueLowerCase);
                            // if (found!=-1){
                            //     // The pillar has been found into the search string
                            //     newSelectedPillars.push(pillar.id);
                            //     console.log("Pillar found:"+pillar.l);
                            // }
                            
                        });
                        
                        data.autocomplete=newAutocomplete;
                        data.selectedPillars=newSelectedPillars;
                        // console.log("++ Triger:"+value);
                        // that.trigger(data);
                        SearchActions.autocompletedChanged(data.autocomplete);

                    }finally{
                        // be sure that always end here
                        data.timeRunning=false;
                    }
                    
                },TIMEOUT);
            }
        }

    },

    getSelectedPillars: function(value) {
        return data.selectedPillars;
    },

    getAutoComplete: function(value) {
        return data.autocomplete;
    }

});

module.exports = Store


