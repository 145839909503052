/**
 * Store locator code
 */
"use strict";
var Utils= require("../Utils");
var RequestModule= require("../lines/request");
var Handlebars= require("handlebars");


var map=null; // the google map
var popupResults = []; // the popup results
var openedwindow = null; // the opened info window if any
var latlngbounds=null; // the current gnmap bounds
var markers=[]; // al the info windows

// templates
var searchPopupContentTemplate=null;
var searchPopupNoresultTemplate=null;
var blockGridTemplate=null;
var blockGridTemplateInfoBox=null;

// the marker reference adderess
var markerReferenceAddress=null;

// the path name of the page
var currentPathName=null;

//var SERVER="http://dsal-wbptlb01.stib-mivb.be:50000"
var SERVER=""


function stripVowelAccent(str){
    var s=str;
	
	var rExps=[ /[\xC0-\xC2]/g, /[\xE0-\xE2]/g,
	            /[\xC8-\xCA]/g, /[\xE8-\xEB]/g,
	            /[\xCC-\xCE]/g, /[\xEC-\xEE]/g,
	            /[\xD2-\xD4]/g, /[\xF2-\xF4]/g,
	            /[\xD9-\xDB]/g, /[\xF9-\xFB]/g ];
	
	var repChar=['A','a','E','e','I','i','O','o','U','u'];
	
	for(var i=0; i<rExps.length; i++){
		s=s.replace(rExps[i],repChar[i]);
	}
	return s;        
}

function getShopTypes() {
    var shopTypes = $('input[name="pos"]:checked');
	var stps = "";
	$.each(shopTypes, function(i,item){
		if(i>0) stps += ',';
		stps += item.value;
	});
    
    return stps;
}

function getTicketTypes() {
	var ticketTypes = $('input[name="product"]:checked');
	var ttps = "";
	$.each(ticketTypes, function(i,item){
		if(i>0) ttps += ',';
		ttps += item.value;
	});
    
    return ttps;
}
    

function initGmaps(lat,long) {
    if(map==null){  	        
  	    var search_origin = new google.maps.LatLng(lat,long);  
        
  	    var myOptions = {       
  			zoom: 12,
  			minzoom:10,       
  			center: search_origin,       
  			mapTypeId: google.maps.MapTypeId.ROADMAP     
  	    };     
  	    map = new google.maps.Map(document.getElementById("map_canvas"), myOptions); 
    }
}

function markerClickListener(marker){
	if(openedwindow){
		openedwindow.close();
    }        
    
    openedwindow = new google.maps.InfoWindow({maxWidth:250,
                                               content:generateResultBoxInfoBox(marker.item)});
    openedwindow.open(map, marker);
}

function generateStore(item){
    var latlng = new google.maps.LatLng(item.store_latitude, item.store_longitude);		
	var icoMarker = new google.maps.MarkerImage("/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build//images/storelocator/ico-"+item.store_type+".gif");
    
    var marker = new google.maps.Marker({
		map: map,
		icon: icoMarker,
		zIndex:6,
        position: latlng,
        item:item
	});
    
    latlngbounds.extend(latlng);
    map.fitBounds( latlngbounds );
    
    // Adding click event to the marker
    google.maps.event.addListener(marker, 'click', function(event){
        markerClickListener(marker);
        event.preventDefault();
        event.stopPropagation();
    });
    
    return marker;
}


function generateResultBox(item){
    var rendered=blockGridTemplate(item);
    return rendered;
}

function generateResultBoxInfoBox(item){
    var rendered=blockGridTemplateInfoBox(item);
    return rendered;
}

function setMesageAddress(address){
    var message="<span class='text--color-secondary-deep'>"+i18n('STO_3')+"</span><br>"+address;
    
    $("#message").html(message);
}

function createGoogleReference(latitude, longitude){
	initGmaps(latitude,longitude);
    
    // clear markers
    $.each(markers, function(i,item){
        item.setMap(null);
    });
    
	var firstlng = new google.maps.LatLng(latitude, longitude); 

    if (markerReferenceAddress){
        markerReferenceAddress.setMap(null);
    }
    
	markerReferenceAddress = new google.maps.Marker({
		map: map,
		position:firstlng,
		zIndex:12
	});
    
    latlngbounds.extend(firstlng);
    // map.fitBounds( latlngbounds );
    
}

function showResults(latitude, longitude, formated){
	var shopTypes = getShopTypes();
	var ticketTypes = getTicketTypes();

	$.getJSON(SERVER+'/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWEB_F_Internet!2fWEB_F_iViews!2fcom.stib.pos_next',
              {
                  language:Utils.getLanguage(),
                  longitude:longitude,
                  latitude:latitude,
                  postypes:shopTypes,
                  ticket_types:ticketTypes
              }, function(data) {
                  
                  if (!data.poss || data.poss.length === 0) {
                      popupNoResultShops();
                  } else {
                      latlngbounds = new google.maps.LatLngBounds( );
                      setMesageAddress(formated);
                      
                      $("#form-panel").hide();	
                      $("#result-panel").show();                      
                      
                      createGoogleReference(latitude, longitude);
                      
                      
                      
                      // clear the result list
                      $('#result-list').children('li').each(function () {
                          $(this).remove();
                      });
                      
                      // clear the infoe window
		              markers.length=0;
                      
			          $.each(data.poss, function(i,item){
                          item.index=i;
                          var marker=generateStore(item);
                          markers.push(marker);
                          
                          var el=generateResultBox(item);
                          $('#result-list').append(el);
                          
			              
			          });
                  }
		      });
    
}

function popupSeveralResults(results){        
    $("#search-popup-title").html(i18n('STO_4'));
    
    var rendered=searchPopupContentTemplate(results);
    
    $("#search-popup-content").html(rendered);
    
    popupResults=results;
    
    $("#search-popup").addClass("is-active");
    
}

function popupNoResultShops(results){        
    $("#search-popup-title").html(i18n('STO_5'));
    
    var rendered=searchPopupContentTemplate(results);
    
    $("#search-popup-content").html(rendered);
    
    popupResults=results;
    
    $("#search-popup").addClass("is-active");
    
}


function popupNoResults(label){
    
    $("#search-popup-title").html(i18n('STO_6'));
    
    var rendered=searchPopupNoresultTemplate(label);
    $("#search-popup-content").html(rendered);
    
    popupResults=null;
    
    $("#search-popup").addClass("is-active");
}


function search(value){        
	//$.getJSON(SERVER+'/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWebsite!2fFrontend!2fPublic!2fiViews!2fcom.stib.ProxyServlet2016',
	$.getJSON('https://maps.googleapis.com/maps/api/geocode/json',
              {
                  //url:"https://maps.googleapis.com/maps/api/geocode/json",
                  address:value,
                  sensor:"false",
                  components:"country:BE",
                  region:"be",
                  bounds:"50.600,4.200|51.000,4.500",
                  language:Utils.getLanguage(),
                  key:'AIzaSyBgFiOXOGl9sGvxlAR-V42XtS8Vz5eIqJs'
              },
              function(data) {
                   //console.log("Search ok:");
                  //console.log(data);
                  
                  try {
                      if (data.results.length === 1){
                          var item=data.results[0];
                          showResults(item.geometry.location.lat, item.geometry.location.lng, item.formatted_address);	
                      } else if (data.results.length > 1){
                          popupSeveralResults(data.results);                              
                      } else {
                          popupNoResults(value);                                                            
                      }
                  }catch(err){
                      // console.error(err);
                  }
                  
		      }).fail(function(data){
                   //console.log("Search ERROR:");
                   //console.log(data);
		      });
    
}

function initTemplates(){
    // preload the templates
    var source;
    
    source=$("#search-popup-content-template").html();
    searchPopupContentTemplate = Handlebars.compile(source);
    
    source=$("#search-popup-noresult-template").html();
    searchPopupNoresultTemplate = Handlebars.compile(source);
    
    source=$("#block-grid-template").html();
    blockGridTemplate = Handlebars.compile(source);
    
    source=$("#block-grid-template-info-box").html();
    blockGridTemplateInfoBox = Handlebars.compile(source);
}

function pushUrl(searchValue){
    var url=window.location.pathname+"?l="+Utils.getLanguage();
    if (searchValue){
        url=url+"&_address="+searchValue;
    }
    
    window.history.pushState(null,null, url);
}

function displayPage(){
    var params=RequestModule.getParameters();
    var address=RequestModule.getStringValue(params,"_address",null);

    // Close popups:
    $("#search-popup").removeClass("is-active");

    if (!address) {
        $("#address").val(""); // clear the old search value

        // set the visibility and the focus just in case
        $("#result-panel").hide();	
        $("#form-panel").show();	
        $("#address").focus();
    } else {
        var value=stripVowelAccent(address);
        $("#address").val(value);
        search(value);
        // only push when search
        // pushUrl(value);
    }
}

function init(){
    currentPathName=window.location.pathname;

    // enter keydown
    $("#query").bind('keydown',function(event){
		if (event.which == '13') {
	    	$("#action_search").trigger('click');
	    	event.preventDefault();
	    }
	});
    
    // search
    $("#action_search").bind('click', function(event){
        
		if($("#address").val()){
            
            // remove the acent vowels from the search ?
            var value=stripVowelAccent($("#address").val());
            ///var value=$("#address").val();
            
            $("#address").blur();
            
            search(value);
            pushUrl(value);

            event.preventDefault();
            event.stopPropagation();
	    } 
	});
    
    // new search button on the result panel
    $("#new-search").bind('click', function(event){
        $("#result-panel").hide();	
        $("#form-panel").show();
        $("#address").val(""); // clear the old search value
        $("#address").focus();
        $("html, body").animate({ scrollTop: 0 }, "fast");
        pushUrl("");
        
    });
    
    // click on the store html to show on the map
    $("#result-list").on('click', 'a', function(event){
        var index=$(this).data("index");
        if (markers.length> index){
            var marker=markers[index];
            markerClickListener(marker);
            
            event.preventDefault();
            event.stopPropagation();
            
        }
    });

    
    window.onpopstate = function(event){
        var url=window.location.pathname;
        
        if (url.indexOf(currentPathName) === 0){
            displayPage();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    // popup elements lists
    $("#search-popup-content").on('click', 'a', function(){
        var index=$(this).data("index");
        if (index!=="" && popupResults && popupResults[index]){
            $("#search-popup").removeClass("is-active");
            var item=popupResults[index];
            
            showResults(item.geometry.location.lat, item.geometry.location.lng,item.formatted_address);
            event.preventDefault();
            event.stopPropagation();
        }
    });
    
    // close the popup
    $("#search-popup").on('click', '#search-popup-close', function(event){
        $("#search-popup").removeClass("is-active");
        event.preventDefault();
        event.stopPropagation();
    });

    initTemplates();        
   
 }

$(document).ready(function(){
    var el=$("form#query");
    
    if (el.length>0){
        init();
        displayPage();
    }
});
