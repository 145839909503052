/**
 * The goal of this call is to access to the network messages
 *
 * Test:  dateippt:'20161025'        
 * http://localhost:8080/portal/Horaire.jsp?l=fr&_line=2&_directioncode=V&_stop=8471&_mode=rt
 * http://localhost:8080/portal/Horaire.jsp?l=fr&_line=1
 * http://localhost:8080/portal/Horaire.jsp?l=fr&_line=44&_directioncode=V&_stop=&_mode=undefined
 */
"use strict";

var SINGLETON={};

var Utils = require("../Utils");
var Actions = require("../lines/Actions");
var moment = require('moment');
var getI18n = require('./I18n')
var Alert = require('../partials/Alert');

var TIME_OUT=10000;
var AJAX={}; // here whe store the curent call to be aborted

var MESSAGES_TTL=1000*60*1.5; // 15 minutes

function returnInfo(info){
    return info;
}

SINGLETON.getWebMessage=function(callBack){
    var data={
        language:Utils.getLanguage(),
        type:"web"
    };
    return SINGLETON.ajax(callBack,data,"web");    
};

/**
 * This gets the messages for a lien and stop, if denined
 */
SINGLETON.getMessage=function(callBack,idLine,idStop,mode){
    if (idStop!=null){ // Stop
        // create both in parallel
        return $.when(
            //SINGLETON.get5x24(returnInfo,idLine,null,mode)
            SINGLETON.irsppt(returnInfo,idLine,mode)
            ,SINGLETON.get5x24(returnInfo,idLine,idStop,mode)
        ).then(function(/*info1,*/info2,info3){
//            var m1=info1[0];
            var m2=info2[0];                        
            var m3=info3[0];                        

            return callBack({
//                line:m1.messages,
                irsppt:(m2)?m2.messages:null,
                stop:(m3)?m3.messages:null                    
            });
        })
    } else { // Ligne
        // create both in parallel
        return $.when(
            SINGLETON.irsppt(returnInfo,idLine,mode),
            SINGLETON.get5x24(returnInfo,idLine)
        ).then(function(/*info1,*/info2,info3){
            var m2=info2[0];                        
            var m3=info3[0];

            return callBack({
//                line:m1.messages,
                irsppt:(m2)?m2.messages:null,
                line:(m3)?m3.messages:null                    
            });

        })
    }    
};

// 5x24 par ligne or stop, idStop is optional
// http://xxwebs88.stib-mivb.be:53500/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWEB_F_Internet!2fWEB_F_iViews!2fcom.stib.networkmessages.NetworkMessages?language=fr&type=524&by=line&idline=2&idmode=M


// http://xxwebs88.stib-mivb.be:53500/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWEB_F_Internet!2fWEB_F_iViews!2fcom.stib.networkmessages.NetworkMessages?language=fr&type=524&by=stop&idline=2&idmode=M&idstop=8411
SINGLETON.get5x24=function(callBack,idLine,idStop,mode){
    var data={
        language:Utils.getLanguage(),
        type:"524",
        idline:idLine,
        idmode:mode
    };

    var type;
    
    if (idStop){
        data.idstop=idStop;
        data.by="stop";
        data.isStopMessage=true;
        type="stop-5x24";
    } else {
        data.by="line",
        
        type="line-5x24";
    }
    return SINGLETON.ajax(callBack,data,type);
};


// pour irsppt (seulement par ligne)
// http://xxwebs88.stib-mivb.be:53500/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWEB_F_Internet!2fWEB_F_iViews!2fcom.stib.networkmessages.NetworkMessages?language=fr&type=524&by=line&idline=44&idmode=T&withipptmessage=true&modeippt=T&lineippt=44&dateippt=20161025
SINGLETON.irsppt=function(callBack,idLine,mode){

    var data={
        language:Utils.getLanguage(),
        type:"524",
        by:'line',
        idline:idLine,
        idmode:mode,
        withipptmessage:'true',
        modeippt:mode,
        lineippt:idLine,
        dateippt:moment().format('YYYYMMDD') // 20161025
        // dateippt:'20161025'        
    };
    return SINGLETON.ajax(callBack,data,"irsppt");

};


SINGLETON.ajax=function(callBack,data,type){
    // fisrt abort previous call
    var prevCall=AJAX[type];
    if (prevCall){
        prevCall.abort();
    }

    var url=Utils.getNetworkMessagesUri();
    // then the ajax call

    var time = (new Date()).getTime()
    
   // var cacheTime=time/MESSAGES_TTL;
   // data["_nocache"]=Math.floor(time);
    
    // this is a workaround to cache the result in nscaler for ~9 minutes
    var cacheTokenTime = "" + time;
    time = time.toString().substring(0, 9);
    
    
    if(data.isStopMessage){
    	//  ~ 50 seconds
    	time = time.toString().substring(0, 9);
    	var numberToCheckForCache = time.charAt(8);
	    if(numberToCheckForCache < 5){
	    	cacheTokenTime = time.substring(0, 8).concat("00000")
	    }else {
	    	cacheTokenTime = time.substring(0, 8).concat("50000")
	    }
    } else {
    	// ~ 8 minutes
    	time = time.toString().substring(0, 8);
    	var numberToCheckForCache = time.charAt(7);
	    if(numberToCheckForCache < 5){
	    	cacheTokenTime = time.substring(0, 7).concat("000000")
	    }else {
	    	cacheTokenTime = time.substring(0, 7).concat("500000")
	    }
    }
    
    //data["cacheTokenTime"] = cacheTokenTime
    data["_nocache"] = cacheTokenTime
    var current=$.ajax({
        url:url,
        data:data,
        timeout:TIME_OUT,
//        method: "GET",
        success:function(info){
            // current call finished
            AJAX[type]=null;
            return callBack(info);
        },
        error:function(err){
            // current call finished
            AJAX[type]=null;
            Actions.sendHttpError(err);
        }
    });

    AJAX[type]=current;

    return current;
};

function generate524Line(msg,separator){
    if (msg){
        return msg+separator;
    } else {
        return "";
    }
}

function generate524IsEmpty(el,lang){
    if (!el){
        return true;
    } else {
        var line=
            generate524Line(el.msgL1[lang],"-")+
            generate524Line(el.msgL2[lang],"-")+
            generate524Line(el.msgL3[lang],"-")+
            generate524Line(el.msgL4[lang],"-")+
            generate524Line(el.msgL5[lang],"-");
        
        if ( line ) {
            return false;
        } else {
            return true;
        }
    }
}

function renderLine524List(messages524List){
    var ret="";
    var alertStructureStart = '<div class="alert alert--warning"><div class="alert__icon"><span class="icon-danger" aria-hidden="true"></span><span class="sr-only">'+ getI18n('warning')+'</span></div>'

    $.each(messages524List,function(index,element){
        if (element && element.title){      
            ret+=alertStructureStart;
            ret+='<div class="alert__message">'+
                element.title +
                '</div>';
            ret+='</div>';
        }       
    });
    return ret;
}

function renderLineMessages(messages){
    var ret="";
    var lang=Utils.getLanguage();
    var alertStructureStart = '<div class="alert alert--warning"><div class="alert__icon"><span class="icon-danger" aria-hidden="true"></span><span class="sr-only">'+ getI18n('warning')+'</span></div>'
    
    if (messages && messages.irsppt && messages.irsppt.messagesIris){
        $.each(messages.irsppt.messagesIris,function(index,element){

            // messages.irsppt.messagesIris[0].news_url['fr'];
            // fr:"?l=fr&news_rid=osppt130223"
            // var uri=(element.news_url)?element.news_url[lang]:null;
            // sonumber : osppt+ sonumber
            var sonumber=(element.sonumber)?element.sonumber:null;
            if (sonumber) {
                var url=Utils.getNewsUrl(sonumber,lang);
                ret+=alertStructureStart;
                ret+='<div class="alert__title"><a href="'+url+'">'+element.news_title[lang]+'</a></div>';
                ret+='<div class="alert__message">&nbsp;&nbsp;&nbsp;<a href="'+url+'">'+element.news_period[lang]+'</a></div>';
                ret+='</div>';
            } else {
                ret+=alertStructureStart;
                ret+='<div class="alert__title">'+element.news_title[lang]+'</div>';
                ret+='<div class="alert__message">&nbsp;&nbsp;&nbsp;'+element.news_period[lang]+'</div>';
                ret+='</div>';
            }
        });
    }

    if (messages && messages.irsppt && messages.irsppt.messages524List){
        ret+=renderLine524List(messages.irsppt.messages524List);
    }
    if (messages && messages.line && messages.line.messages524List){
        ret+=renderLine524List(messages.line.messages524List);
    }
    
    return ret;
}

function renderStopMessages(messages){
    var ret="";

    $.each(messages.messages524List,function(index,element){
        if (element && element.title){
            ret+="<div class='alert__carousel__message'>"
            ret+="<p>"+element.title +"</p>";
            ret+="</div>"
        }       
    });

    return ret;    
}

SINGLETON.render=function(messages){
    if (messages){
        if (messages.stop){
            SINGLETON.stop(messages.stop);
        }
        SINGLETON.line(messages);
    }
};

SINGLETON.line=function(messages){
    var el=$("#line-messages");

    if (el.length>0){        
        var html=renderLineMessages(messages);
        if (html) {
            el.html(html);
        
            el.fadeIn(400);
        }
    }
};

SINGLETON.stop=function(messages){
    var el=$("#stop-messages");
    var mountPoint=$("#stop-messages-mount-point");
    
    if (el.length>0 && mountPoint.length>0){
        var html=renderStopMessages(messages);

        if (html){
            mountPoint.html(html);
            el.fadeIn(200,function(){
                Alert.init(el);
            });
            
            //Alert.init(el);
            //el.show();
            // el.fadeIn(2000);
        }            
    }
};


module.exports = SINGLETON;
