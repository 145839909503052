
var Request= require("../lines/request");

module.exports = new function FAQ(){
	var iconOpen = 'icon-arrow-top'
	var iconClosed = 'icon-arrow-bottom'
	var displayedAnswer;
	var isAnchoring; 
	var contexts = []
	                
	function removeAccents(str) {
	  var accents    = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
	  var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
	  str = str.split('');
	  var strLen = str.length;
	  var i, x;
	  for (i = 0; i < strLen; i++) {
	    if ((x = accents.indexOf(str[i])) != -1) {
	      str[i] = accentsOut[x];
	    }
	  }
	  return str.join('');
	}
	
	function openArrow(element){
		element.removeClass(iconClosed);
		element.addClass(iconOpen);
	}
	
	function closeArrow(element){
		element.removeClass(iconOpen);
		element.addClass(iconClosed);	
	}
	
	function toggleIcon(element,doOpen){		
		var el = $(element);
		
		if (doOpen && doOpen === true){
			openArrow(el);	
		}else if(doOpen && doOpen === false){
			closeArrow(el);			
		}else {
			if(el.hasClass(iconOpen)){
				closeArrow(el);			
			} else {
				openArrow(el);								
			}
		}
	}
	
	function hideAnswerFromQuestion(item, animSpeed){
		item.hide(animSpeed);
		var icon = $($(item.closest('.faq-question-container')).children('.faq-question')[0]).children('span')[0];
		toggleIcon(icon, false);
		clearDisplayedAnswer()
	}
	
	function showAnswerFromQuestion(item, animSpeed){
		item.show(animSpeed);
		var icon = $($(item.closest('.faq-question-container')).children('.faq-question')[0]).children('span')[0];
		toggleIcon(icon, true);
	}
	
	function toggleCategory(theSpan, childrenCategories){
		toggleIcon(theSpan);
		childrenCategories.toggle('fast', function(){
			// check if there is a displayed answer when closed ( aka check if subcategory is hidden)
			// if so then toggle the question
			var firstSubCategory = childrenCategories[0];
			if(displayedAnswer && (firstSubCategory && $(firstSubCategory).is(':hidden'))){
				toggleQuestion({
					questionId: displayedAnswer.id
				})
			}
		});
		
	}
	
	function clearDisplayedAnswer(){
		displayedAnswer = undefined
	}
	
	function toggleQuestion(params){
		var previousId = displayedAnswer ? displayedAnswer.id : undefined;
		var questionId = params.questionElement ? params.questionElement.attr('id') : params.questionId
		var icon = params.questionElement ? params.questionElement.children('span')[0] : undefined; 
		
		// if there is a displayed answer, hide it
		if (displayedAnswer){
			hideAnswerFromQuestion(displayedAnswer.item, 'fast')							
		}
		
		if( previousId !== questionId){
			// fetch the new answer and display it
			var answer = $(params.questionElement.closest('.faq-question-container').children('.faq-answer')[0]);
			displayedAnswer = {
					item: answer,
					id: questionId
			}
			toggleIcon(icon, true);
			answer.show('fast', function(){
				if(params.scrollTo){
					scrollToId({id:questionId})
				}
			});
		} 
		
		
	}
	
	function scrollToId(params){
		document.getElementById(params.id).scrollIntoView({
			block: params.position ? params.position : 'center'
		})
	}
	
	function createFAQObject(faqDomParent){
		
		var searchQuestionContext = [];
		
		// Categories behaviour
		var faqContentContainer = $(faqDomParent.children("[id^='faqContent']")[0]);
		var categories = faqContentContainer.children("[id^='faqCategory']");
		 
		
		categories.each(function(index){
			var cat = $(this);
			cat.children("[id^='faqCategoryTrigger']").each(function(index){
				$(this).click(function(){
					toggleCategory($(this).children("span")[0], cat.children("[id^='faqSubCategory']"))
				});
			});
			
			var subCategories = $(this).children("[id^='faqSubCategory']");
			
			subCategories.each(function(){
				var questions = $(this).children("[id^='faqQuestionContainer']");
				
				questions.each(function(){
					var questionContainer = $(this);
					var question = $(questionContainer.children("[question-id^='faqQuestion']")[0]);
					var answer = $(questionContainer.children("[id^='faqAnswer']")[0]);
					
					var object = {
						question: removeAccents($(question.children("[id^='faqQuestionContent-']")[0]).text()).toUpperCase(),
						answer: removeAccents($(answer.children("[id^='faqAnswerContent-']")[0]).text()).toUpperCase(),
						parent: questionContainer,
						id: question.attr('id')
					}
					
					question.click(function(){					
						toggleQuestion({questionElement:$(this)})						
					});
					
					searchQuestionContext.push(object);
				});
			});	
		});
		
		// Search Behaviour
		var searchContainer = $(faqDomParent.children(".faq-search-box-container")[0]);
		var searchInputContainer = $(searchContainer.children(".faq-search-box-input-container")[0]);
		var searchInput = $(searchContainer.find(".faq-search-input")[0]);		
		var searchResult = $(searchContainer.children(".faq-search-box-result")[0]);		
		var noResult = $(searchContainer.children(".faq-search-box-no-result")[0]);
		var clearer = $(searchContainer.find("[id^='faqSearchClear']")[0]);	
		searchContainer = null;
		var displayedAnswerBeforeSearch;
		var isSearching = false
		
		function search(callback){			
			noResult.hide();
			var userInput = $.trim(searchInput.val())
			userInput = userInput === '' ? userInput : removeAccents(userInput).toUpperCase();
			
			if (userInput === ''){
				isSearching = false;
				
				searchResult.empty();
				
				// make sure that all the question flags are turned to false for next "real" search
				searchQuestionContext.forEach(function(question){
					question.displayed = false;
				});
				
				// if there was an answer, just hide it
				if (displayedAnswer){
					hideAnswerFromQuestion(displayedAnswer.item, 'fast');
				}

				// now that search is over, we check if we saved a displayed answer before the search to return the question list to the state it was before the search
				// then we show it
				// we set the save to null to reset it and be ready for the next search 
				if(!isAnchoring && displayedAnswerBeforeSearch){
					displayedAnswer = displayedAnswerBeforeSearch;
					showAnswerFromQuestion(displayedAnswer.item, 'slow')
					displayedAnswerBeforeSearch = undefined;
				}
				searchInputContainer.removeClass('has-result');
				faqContentContainer.show('fast', function(){
					if(callback){
						callback();
					}
				});
			} else {			
				isSearching = true;
				faqContentContainer.hide();
				searchResult.empty();
				searchInputContainer.addClass('has-result');
				
				// We check if there is a visible answer if so we hide it first ( to avoid it being displayed when appended to results
				// then we save it for the 'after search' 
				// then we set it to null because no more answer are displayed ;)
				if (displayedAnswer){
					displayedAnswerBeforeSearch = displayedAnswer;					
					hideAnswerFromQuestion(displayedAnswer.item);					
				}
				
				var hasResult = false;
				searchQuestionContext.forEach(function(question){
					if (question.question.indexOf(userInput) !== -1 || question.answer.indexOf(userInput) !== -1 ){
						hasResult = true;
						searchResult.append(question.parent.clone(true, true));
					} else {						
						question.displayed = false;
					}
				});
				
				if (!hasResult){
					noResult.show();
				} 
			}
		}

		function clearSearchInput(callback){
			searchInput.val('');
			//we fire a search with an empty input, then the search logic will "clear" the results
			search(callback);
		}

		clearer.click(function(){
			clearSearchInput();
		});
		
		searchInput.keyup(function(){						
			search();
		})
		
		return {
			isSearching: function(){
				return isSearching;
			},
			clearSearch: function(callback){
				clearSearchInput(callback)
			}
		}
	}
	
	
	//anchoring		
	function anchorTo(questionId){
		isAnchoring = true;			

		function doAnchoring(params){
			console.log('bound:',params)
			
			// find the target question
			var question = $($("#"+params.questionId)[0])		
			// find the parent category
			var category = $(question.closest("[id^='faqCategory-']")[0])

			if (!displayedAnswer || (displayedAnswer && displayedAnswer.id !== params.questionId)){
				// check if open
				// if not open it like a click would do				
				category.children("[id^='faqCategoryTrigger']").each(function(index){
					var subCategories = category.children("[id^='faqSubCategory']")
					var isChildrenVisible = $(subCategories[0]).is(':visible')
					if(!isChildrenVisible){
						toggleCategory($(this).children("span")[0], subCategories)
					}
				});				
				// open the question like a click would do
				toggleQuestion({questionElement:question,scrollTo: true})
			} else {
				scrollToId({id:questionId})
			}	
			
			isAnchoring = false;
		}
		// check if user is searching, if so go back to "normal" state
		
		for(var i = 0; i < contexts.length; i++){
			
			if(contexts[i].isSearching()){
				var anchoring = doAnchoring.bind(null,{questionId:questionId})
				
				// we need to clear the search and then doing the anchoring when it's done
				contexts[i].clearSearch(function(){		
					anchoring();
				});
			} else {
				doAnchoring({questionId: questionId});
			}
		}
	}
	


	
	$(document).ready(function(){
		var content = $("[id^='faqContainer']");
		content.each(function(index){
			contexts.push(createFAQObject($(this)));
		});
		
		var fistCategory = $("[id^='faqCategory-']").first();
		if (fistCategory.length > 0){
			toggleIcon(fistCategory.children("[id^='faqCategoryTrigger']").children("span")[0]);
			fistCategory.children("[id^='faqSubCategory']").toggle('fast');
		}
		
		$("[data-anchorto]").each(function(){
			$(this).click(function(e){
				e.stopImmediatePropagation();
				anchorTo($(this).attr('data-anchorto'))
			})
		})
		
		var incomingId = Request.getParameter("aId",undefined);
		
		if(incomingId){
			anchorTo(incomingId)
		}
		
	});
}
