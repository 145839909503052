/**
 * Web messages at the top of each page
 */
'use strict';

var SINGLETON={};

var Utils = require("../Utils");
var Messages = require("./Messages");
var getI18n = require('./I18n')


  
function _getNetworkMessages(){

    Messages.getWebMessage(function(data){
		if(data && (data.messages)&&(data.messages.messageWeb)){       
			var result = '<div class="col--12">'
			result += _createAlertMessage("warning", data.messages.messageWeb.title, data.messages.messageWeb.messageHtml);
			result += '</div>';
			$(".header__after-navigation__inner div.container").append(result);
		}		
	});	
}

function _createAlertMessage(type, title, text){    
	var result = '<div id="webmessage" class="alert alert--' + type + '">';
	result += '<div class="alert__icon"><span class="icon-danger" aria-hidden="true"></span><span class="sr-only">' + getI18n('warning') + '</span></div>';
	result += '<div class="alert__title">' + title + '</div>';
	result += '<div class="alert__message">' + text + '</div>';
	result += '<button class="alert__close"><span class="icon-close" aria-hidden="true"></span></button>';
	result += '</div>';
	return result;	
}

function _setupCloseButton(){
	$( document ).on( "click", ".header__after-navigation__inner div.container button.alert__close", function() {
		$(this).parent().slideUp();
		if(typeof(Storage) !== "undefined") {
            // Create the networkMessages object into the session storage.
			if (!sessionStorage.networkMessages) {
                // sessionStorage.networkMessages=JSON.stringify({});
				sessionStorage.setItem('networkMessages', JSON.stringify({}));
		    }
			// Retrieve the object from storage
			var networkMessages = JSON.parse(sessionStorage.getItem('networkMessages'));

            // mark this alert to not be shown again
            var title=$(this).parent().find(".alert__title").text();
			networkMessages[title] = true;
			
			// Put the object into storage
			sessionStorage.setItem('networkMessages', JSON.stringify(networkMessages));
		}
	});
}

$( document ).ready(function() {
    var el=$(".header__after-navigation__inner div.container");

    if (el && el.length >0){
	    _getNetworkMessages();
	    _setupCloseButton();
    }
	
});

module.exports = SINGLETON;
