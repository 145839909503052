/**
 * This is for the autocomplete stuff.
 *
 * THERE IS BUG IN IE: https://github.com/omcljs/om/issues/704
 *
 * Not controled components:
 * https://facebook.github.io/react/docs/uncontrolled-components.html
 * https://codepen.io/gaearon/pen/WooRWa?editors=0010
 *
 * Controled components:
 * https://facebook.github.io/react/docs/forms.html
 * https://codepen.io/gaearon/pen/VmmPgp?editors=0010
 *
 *
 * https://www.peterbe.com/plog/onchange-in-reactjs
 * https://facebook.github.io/react/docs/forms.html
 */
"use strict";
var React = require("react");
var ReactDOM = require("react-dom");

var Reflux = require("reflux");

var SearchStore = require("./SearchStore");
var SearchActions= require("./SearchActions.js");

var SELECTOR="#search-element";

var View = React.createClass({
    // For performance reasons avec IE 11 this is here ....
    //mixins: [Reflux.connect(SearchStore, 'store')],
    mixins: [Reflux.ListenerMixin],
    
    getInitialState:function(){
        this.handleChange = this.changeEvent;

        SearchStore.getInitialState();
        
        return {value: ''};
    },
    componentDidMount: function() {
        this.listenToMany(SearchActions);
    },
    componentWillUnmount: function() {
    },
    onSetSearchTextFieldValue:function(searchString){
        this.setState({value: searchString});
    },
    onAutocompletedChanged:function(array){
        this.setState({value: this.state.value});
        // this.forceUpdate();
    },


    changeEvent:function(event){
        this.setState({value: event.target.value});        
        
        // this.forceUpdate();
            
        SearchStore.setValue(event.target.value);
        // $("#search-message").html("");

        // if(event.keyCode == 13){
        //     debugger;
        
        //     alert('Adding....');
        // }
        
//        event.preventDefault();
//        event.stopPropagation();
    },

    click:function(value,id){
        var that=this;
        return function(event){
            SearchStore.setValueFromAutocomplete(value,id);
            that.setState({value: value});
            // that.props.searchString=value;
            // this.value=value;
            that.forceUpdate();
        };
    },
    clickClear:function(event){
        SearchStore.clear();
        this.setState({value: ''});
        // $("#search-message").html("");
    },

    writeLine:function(el,index,array){
        return (<li key={index} onClick={this.click(el.fullText,el.id)}>{el.prefix}<b>{el.value}</b>{el.sufix}</li>);
    },
    
    autocomplete:function(event){
        var array=SearchStore.getAutoComplete();

        if (array.length === 0){
            return null;
        } else {
            return (
            <div className="form__autocomplete">
                    <ul>
                    {array.map(this.writeLine)}
                    </ul>
            </div>
            )
        };
    },

    clearButton:function(){
        if (this.state.value){
            return (
            <button id="search-clear-button" className="search__input__clear btn btn--notext btn--unstyled js-clearableinput-clear" type="button" onClick={this.clickClear}>
              <span className="icon-close" aria-hidden="true"></span>
            </button>
            );
        } else {
            return null;
        }
    },


    render:function(){
        return (
        <div>
                <input type="text" title={i18n("Search")} placeholder={i18n("Search")} className="search__input__query js-clearableinput-input" onChange={this.changeEvent}  name="search" value={this.state.value} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"  />
            {this.clearButton()}
            {this.autocomplete()}
        </div>
        );
    }
});

//            <input type="text" name="pilars" value={SearchStore.getSelectedPillars().join(',')}  />

$(document).ready(function(){
    var widget = $(SELECTOR);
    
    if(widget.length){
        ReactDOM.render(<View />, widget.get(0));
        
    }
});

module.exports = View;
