var React = require("react");
var ReactDOM = require("react-dom");

var Reflux = require("reflux");

var Utils = require("../Utils");

var Store = require("./Store");
var Actions = require("./Actions");


var settings = {
}

var ViewLine = React.createClass({

    render:function(){
        var info=Store.getLineInfo();
        
        return (
                <div dangerouslySetInnerHTML={Utils.renderHTML(info)} />
        );        
    }
});

module.exports = ViewLine;
