var React = require('react')
var ReactDOM = require('react-dom')
var Utils= require("../Utils");
var GetI18n = require ("../general/I18n")
var Loader = require("../ReactComponents/Loader.jsx");

var lang = Utils.getLanguage();
var today = new Date();
var hourNow = today.getHours();
var hasWednesday = false

function hasWednesdayWorkaround(dayType){
    return dayType == "1" ? hasWednesday ? "" : "B" : ""
}

function updateCurrentTime(){
    today = new Date();
    hourNow = today.getHours(); 
}

function isLine(data){
    if(data.type){
        return false;
    }
    return true;
}

var TokyoSearch = React.createClass({

    getInitialState: function(){
        return {
            data: this.props.data
        }
    },
    ajaxCallBack: function(data){
        this.setState({
            isLoading: false,
            result: data.result,
            selectedItem: data.selectedItem
        })
    },
    getSelectedData: function(data){
        
        this.setState({
            isLoading: true
        })
        var result 
        var ajaxCB = this.ajaxCallBack
        var params = {};

        if(data.type){
            params.id = data.id
            params.mode = "s"
        } else {
            params.id = data.lineId
            params.mode = "l"
        }

        $.ajax({
            url: '/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWebsite!2fFrontend!2fPublic!2fiViews!2fOccupancySearchService',
            type: 'GET',
            data: params
        }).done(function(e){
            result = e && e.Occupancy ? e.Occupancy.DAYS : []
            updateCurrentTime();
        }).fail(function(e){
            result = []
        }).always(function(e){
            ajaxCB({result: result, selectedItem: data})
        })

    },
    renderResultContainer: function(){
        if(this.state.isLoading){
            return <Loader/>
        }

        if(this.state.selectedItem){
            return <ResultContainer selectedItem={this.state.selectedItem} result={this.state.result}/>
        }

        return <EmptyResultContainer/>
       
    },
    render: function(){
        return (
            <div>
                <Introduction/>
                <SearchContainer data={this.state.data} onSelect={this.getSelectedData}/>
               {this.renderResultContainer()}
            </div>
        )
    }
})

function stationFilterComparator(station, value){
    return station.name['nl'].indexOf(value.toUpperCase()) >= 0 || station.name['fr'].indexOf(value.toUpperCase()) >= 0
}
function lineFilterComparator(line, value){
    return line["lineId"].indexOf(value.toUpperCase()) >= 0
}

function Introduction(){
    return <div className="col--12 col--12@mobile">
        <h2>{GetI18n('tokyo.search.intro.title')}</h2>
        <FormattedText text={GetI18n('tokyo.search.intro.content')}/>
    </div>
}

function SearchContainer(props){
    return  <div id={"searchContainer"} className="col--12 col--12@mobile" >
        <h3><FormattedText text={GetI18n('tokyo.search.search.title')}/></h3>
        <SearchBar data={props.data} onSelect={props.onSelect}/>
    </div>
}

var SearchBar = React.createClass({
    getInitialState: function(){

        this.props.data.lines = this.props.data.lines.filter(function(line){
            return line.lineId.indexOf("N") === -1 && line.lineId.indexOf("T") === -1
        })

        return {
            filteredOptions: [],
            cursorIndex: -1,
            isInputInteractive: true,
            userInput: '',

            data: this.props.data
        }
    },
    handleChange: function(input){
        var userInput = input.target.value.trim();
        this.filterOptions(userInput)
    },
    filterOptions: function(userInput){
       

        var filteredOptions = []
        
        if(userInput){
           

            if(this.state.filteredOptions.lines && this.state.filteredOptions.lines.length > 0){
                this.state.filteredOptions.lines.forEach(function(line){
                    if(lineFilterComparator(line, userInput)){
                        filteredOptions.push(line)
                    } 
                 }) 
            } else {
                this.state.data.lines.forEach(function(line){
                    if(lineFilterComparator(line, userInput)){
                        filteredOptions.push(line)
                    }
                })
            }
            

            if(this.state.filteredOptions.stations && this.state.filteredOptions.stations.length > 0){
                this.state.filteredOptions.stations.forEach(function(station){
                    if(stationFilterComparator(station,userInput)){
                        filteredOptions.push(station)
                    }
                })
            } else {
                this.state.data.stations.forEach(function(station){
                    if(stationFilterComparator(station,userInput)){
                        filteredOptions.push(station)
                    }
                 })
            }
                
        }

        this.setState({
            filteredOptions: filteredOptions,
            showList: filteredOptions && filteredOptions.length > 0,
            cursorIndex: -1,
            selectedData: null,
            userInput: userInput,
            isInputInteractive: true
        })
    },
    handleKeyDown: function(e){
        if(this.state.showList ){
            if(e.keyCode === 38 && this.state.cursorIndex > 0){
                var prevIndex = this.state.cursorIndex
                this.setState({
                    cursorIndex: prevIndex - 1,
                    selectedData: this.state.filteredOptions[prevIndex - 1],
                    userInput:  this.state.filteredOptions[prevIndex - 1].type ? this.state.filteredOptions[prevIndex - 1].name[getCurrentLangProperty()] : this.state.filteredOptions[prevIndex - 1].lineId
                })
            } else if(e.keyCode === 40 && this.state.cursorIndex < this.state.filteredOptions.length - 1 ){

                var prevIndex = this.state.cursorIndex

                this.setState({
                    cursorIndex: prevIndex + 1,
                    selectedData: this.state.filteredOptions[prevIndex + 1],
                    userInput: this.state.filteredOptions[prevIndex + 1].type ? this.state.filteredOptions[prevIndex + 1].name[getCurrentLangProperty()] : this.state.filteredOptions[prevIndex + 1].lineId
                })
            } else if (e.keyCode === 13){
                this.selectListItem(this.state.filteredOptions[this.state.cursorIndex]); 
            } 
        }
    },
    switchToInteractiveInput: function(){
        this.filterOptions(this.state.userInput)
        
    },
    selectListItem: function(data){    
        this.setState({
            isInputInteractive: false,
            selectedData: data,
            showList: false,
            userInput: data.type ? data.name[getCurrentLangProperty()] : data.lineId
        })

        this.props.onSelect(data)
    },
    onBlur: function(){
        if(!this.state.ignoreBlur){
            this.setState({
                showList: false
            })
        }
    },
    setIgnoreBlur: function(){
        this.setState({
            ignoreBlur: true
        })
    },
    clearIgnoreBlur: function(){
        this.setState({
            ignoreBlur: false
        })
    },
    render: function(){

        
        return <div id={"searchBarContainer"} onBlur={this.onBlur} onMouseDown={this.setIgnoreBlur} onMouseUp={this.clearIgnoreBlur}>
            <div className={"input-container"}>
                <InteractiveInput onClick={this.switchToInteractiveInput} onChange={this.handleChange} onKeyDown={this.handleKeyDown} value={this.state.selectedData ? this.state.selectedData.type ?  this.state.selectedData.name[getCurrentLangProperty()] : this.state.selectedData.lineId : this.state.userInput}/>
                <span className="icon-search" aria-hidden="true" onClick={this.onClick}></span>
            </div>
            <InteractiveList show={this.state.showList} options={this.state.filteredOptions} cursorIndex={this.state.cursorIndex} onClick={this.selectListItem}/>
        </div>
    }
})

var InteractiveInput = React.createClass({
    handleFocus: function(e){
        e.target.selectionStart = this.props.value.length
    },
    render: function(){
        return <input placeholder={GetI18n('tokyo.search.search.input.placeholder')} tabIndex={"0"} onClick={this.props.onClick} onChange={this.props.onChange} onKeyDown={this.props.onKeyDown} value={this.props.value}  onFocus={this.handleFocus}/>
    }
})

function InteractiveList(props){
    if(props.show){
        
        return  <ul role="listbox" id={"searchResult"} tabIndex={"0"}>
            {props.options.map(function(data, index){
                var style={}
                var item = null;
                if(isLine(data)){
                    item = <ListItemLine key={index} line={data}/>
                    style.padding = '2px 5px'
                } else {
                    item = <ListItemStation key={index} station={data}/>
                    style.padding = '10px'
                }

                return <ListItemWrapper data={data} isSelected={index === props.cursorIndex} style={style} key={index} onClick={props.onClick}> 
                    {item}
                </ListItemWrapper>                      
            })}
        </ul>
    }
    return null
}

var ListItemWrapper = React.createClass({
    handleClick: function(){
        this.props.onClick(this.props.data)
    },
    render: function(){
        return <li className={"search-result-item" + (this.props.isSelected ? " selected" : "")} onClick={this.handleClick} style={this.props.style} role={"option"} aria-selected={this.props.isSelected}>
        {this.props.children}
    </li>
    }

})
function ListItemStation(props){
    return (
    <div className={"search-resul-item-station"}>
        <span className={"station-icon icon-metro"}></span>
        <div className={"station-name"}>{props.station.name[getCurrentLangProperty()] }</div>
    </div>
    )
}

function ListItemLine(props){
    return (
        <div className={"search-resul-item-line"}>
            <ModeLineIcon line={props.line}/>
            <div className={"line-name-container"}>
                <div className={"line-name"}>
                    <div className={"terminal-a"}>{props.line.terminalA.name }</div>
                    <div>{props.line.terminalB.name }</div>
                </div>
            </div>
        </div>
    )
}

function ModeLineIcon(props){
    return <div className={"line-number-mode-container"}>
        <ModeIcon mode={props.line.mode}/>
        <LineNumber lineId={props.line.lineId} frontColor={props.line.frontColor} backColor={props.line.backColor}/>
    </div>
}
function ModeIcon(props){
    return (
        <div className="btn btn--tertiary btn--round mode-container">
                <span className={"btn--round__inner"}>{props.mode === 'BUS' ? 'B' : props.mode === 'TRAM' ? "T" : "M"}</span>
        </div>
    )
}

function LineNumber(props){
    return <div className={"line-number"} style={{backgroundColor: props.backColor,color: props.frontColor}}>
        {props.lineId}
    </div>
}

var ResultContainer = React.createClass({
    getInitialState: function(){

        var dayType = 0;
        var availableDaysType = []
        hasWednesday = false;
        var results = this.props.result ? this.props.result : []
        results.forEach(function(day){
            availableDaysType.push(day.DAY)
            if(day.CURRENT_DAY){
                dayType = day.DAY;
            }

            if(day.DAY=="3"){
                hasWednesday = true
            }
        })

        return {
            data: this.props.result,
            dayType: dayType,
            initialDayType: dayType,
            selectedItem: this.props.selectedItem,
            isInitialDay: true,
            isMorning: isMorningDayTimeType(),
            availableDaysType: availableDaysType
        }
    },
    handleChangeDayType: function(dayType){
        this.setState({
            dayType: dayType,
            isInitialDay: dayType === this.state.initialDayType,
            isMorning: true
        })
    },
    getStatusGeneral: function(){
        var status = 0;

        this.props.result.forEach(function(res){
            if(res.DAY == this.state.dayType){
                status = res.STATUS_GENERAL ? res.STATUS_GENERAL : 0
            }
        },this)

        return status
    },
    switchDayTimeType: function(){
        this.setState({
            isMorning: !this.state.isMorning
        })
    },
    render: function(){
        var titleContent = "";
        if(this.props.selectedItem.type === 'STOP_AREA'){
            titleContent = GetI18n('tokyo.search.result.title.station', [this.props.selectedItem.name[getCurrentLangProperty()]])
        } else {       
            titleContent =  GetI18n('tokyo.search.result.title.line', [this.props.selectedItem.lineId])
        }

        var dayTypeIndex;

        for(var i = 0; i < this.props.result.length; i++){
            if(this.props.result[i].DAY === this.state.dayType){
                dayTypeIndex = i;
                i= this.props.result.length;
            }
        }            
     

        var currentDayData = this.props.result ? this.props.result[dayTypeIndex] : null

        var barsContainer = null
        var dayTimeTypeSwitcher = null       
        var title = null
        var sourceText = null

        if(currentDayData){
            var hours = currentDayData ? currentDayData.HOURS : []
            var hasData = currentDayData.OCC_GENERAL && currentDayData.STATUS_GENERAL
            
            dayTimeTypeSwitcher = <BarDayTimeTypeSwitchContainer isMorning={this.state.isMorning} onClick={this.switchDayTimeType}/>
            barsContainer =  <BarsContainer isInitialDay={this.state.isInitialDay} isStation={this.state.selectedItem.type ? true : false} data={hours} isMorning={this.state.isMorning} hasData={hasData} dayType={this.state.dayType}/>
            title = <Title text={GetI18n('tokyo.search.evolution')}/>
            sourceText = <FormattedText text={GetI18n('tokyo.search.result.source')}/>
        }
        

        return(
            <div id={"resultContainer"} className={"col--12 col--12@mobile"}>
                <Title text={titleContent}/>
                <DayTypeSelector onClick={this.handleChangeDayType} dayType={this.state.dayType} availableDaysType={this.state.availableDaysType}/>
                <OccupancyLevel level={this.getStatusGeneral()} />
                {title}
                {barsContainer}
                {dayTimeTypeSwitcher}
                {sourceText}
            </div>
        )
    }
})


function FormattedText(props){
    return <p dangerouslySetInnerHTML={{__html:props.text}}>

    </p>

}
function EmptyResultContainer(){
    return <div id={"occupancySearch"} className={"col--12 col--12@mobile"}>

    </div>
}

function getCurrentLangProperty(){
    return lang === 'nl' ? 'nl' : 'fr'
}

function Title(props){
    return <div className={"result-title"} >
        {props.text}
    </div>
}

function createBarContainerGroupForStation(data, isInitialDay){
    var result = []
    for(var i = 0; i < data.length; i++){
        var container = <BarContainer key={"m"+i}  widthDivider={data.length / 2}>
            <Bar data={data[i]}/>
            <Bar data={data[i + 1]}/>
            <Hour isCurrentDay={isInitialDay} hour={data[i].HOUR}/>           
        </BarContainer>
        result.push(container);
        i++
    }

    return result
}

function createBarContainerGroupForLine(data, isInitialDay){
    var result = []
    for(var i = 0; i < data.length; i++){
        var container = <BarContainer key={"m"+i} widthDivider={data.length}>
            <Bar data={data[i]}/>
            <Hour isCurrentDay={isInitialDay} hour={data[i].HOUR}/>           
        </BarContainer>
        result.push(container);
    }

    return result
}

function createEmptyBarContainer(data, isStation){
    var result = []
    for(var i = 0; i < data.length; i++){
        var container = <BarContainer key={"m"+i} widthDivider={isStation ? data.length / 2 : data.length}>
            <EmptyBar />
            <Hour isCurrentDay={false} hour={data[i].HOUR}/>           
        </BarContainer>
        result.push(container);
        
        if(isStation){
        	i++
    	}
    }
    return result
}


function EmptyDataMessage(props){
    return <div className={'no-data'}>
        {GetI18n('tokyo.search.no-data',[GetI18n('tokyo.search.no-data.'+props.dayType+hasWednesdayWorkaround(props.dayType))])}
    </div>
}

function BarsContainer(props){

    var morning
    var afternoon

    if(props.hasData){
       morning = props.isStation ? createBarContainerGroupForStation(props.data[0], props.isInitialDay) : createBarContainerGroupForLine(props.data[0], props.isInitialDay)
       afternoon = props.isStation ? createBarContainerGroupForStation(props.data[1], props.isInitialDay) : createBarContainerGroupForLine(props.data[1], props.isInitialDay)
    } else {
        morning = createEmptyBarContainer(props.data[0], props.isStation);
        afternoon = createEmptyBarContainer(props.data[1], props.isStation)
    }
    
    var noDataMessage = null
    if(!props.hasData) {
        noDataMessage = <EmptyDataMessage dayType={props.dayType}/>
    }
    
    return <div className={"bar-container" + (!props.isStation ? "__line" : "__station")}>
        {noDataMessage}
        <BarDayTimeType isCurrent={props.isMorning}>
        {morning}
        </BarDayTimeType>
        
        <BarDayTimeType isCurrent={!props.isMorning}>
        {afternoon}
        </BarDayTimeType>
        
    </div>
}

function isMorningDayTimeType(){
    return hourNow < 14 || hourNow > 22
}

// we need with css for responsive to set it to display none when daytimetype is not the current selected one (morning/afternoon)
function BarDayTimeType(props){
    var className = "day-time-type";
    if(props.isCurrent){
        className += " current"
    }

    return <div className={className}>
        <BottomHorizontalBar/>
        {props.children}
    </div>
}

function BarDayTimeTypeSwitchContainer(props){
    var dayTimeTypeIndex = props.isMorning ? 0 : 1;
    return <div id="occupancyUtils">
        <div id="occupancyDayPeriodSwitch" className="day-period-switch-container">           
        <BarDayTimeTypeSwitch onClick={props.onClick} index={dayTimeTypeIndex} text={GetI18n('tokyo.dayTime.'+ dayTimeTypeIndex)}/>
        </div>
    </div>
}

function BarDayTimeTypeSwitch(props){
    return  (
        <div id={"dayPeriod_" + props.index} className="day-period-container">	
            <div className={ "day-period-switch__left" + (props.index === 0 ? " inactive" : " active")}  onClick={props.index === 1 ? props.onClick : null}>
                <span className={"icon-arrow-left day-period-arrow" + (props.index === 0 ? "__inactive" : "__active")}></span>
            </div>																
            <div className="day-period-label">{props.text}</div>
            <div className={"day-period-switch__right"  + (props.index === 1 ? " inactive" : " active")} onClick={props.index === 0 ? props.onClick : null}>
                <span className={"icon-arrow day-period-arrow" + (props.index === 1 ? "__inactive" : "__active")}></span>
            </div>					
        </div>		
    )											
}

function BottomHorizontalBar(){
    return <div style={{borderBottom: '1px solid lightgrey',position:'absolute',width: '100%',bottom: '22px'}}>
        
    </div>
}
function BarContainer(props){
    return <div style={{width: (100/props.widthDivider) + '%'}} className={'bar-container'}>
        {props.children}
    </div>
}

function Hour(props){

    var hourClass = "hour"

    if(props.isCurrentDay && hourNow === props.hour){
        hourClass += " current"
    }

    return <div className={hourClass}>
        <div className={"hour-vertical-bar"} ></div>
        <span className={"hour-value"}>{props.hour + (lang === 'nl' ? 'u' : 'h')}</span>
    </div>

}

function Bar(props){
        var style = {
            height: props.data.DATA.OCC_HOUR ? props.data.DATA.OCC_HOUR * 100 + '%' : '0%',
        }

        return  (
            <div className={'bar-bg'}>
                <div style={style} className={'bar-fg'}></div>
            </div>
        )
}

function EmptyBar(){
    return <div className={'empty'}>

    </div>
}

function DayTypeSelector(props){
    
    return (
        <div className={"day-type-selector"}>
            {
                props.availableDaysType.map(function(dayType, index){
                    return <DayTypeButton key={index} day={dayType} isSelected={props.dayType == dayType} onClick={props.onClick}/>
                })
            }
        </div>
    )
}

var DayTypeButton = React.createClass({
    handleClick: function(){
        this.props.onClick(this.props.day)
    },
    render: function(){
        return  <button className={'day-type' + (this.props.isSelected ? " selected" : "")} onClick={this.handleClick} > {GetI18n('tokyo.daySelector.'+this.props.day+hasWednesdayWorkaround(this.props.day))} </button>
    }
})

function OccupancyLevel(props){
    return <div style={{marginBottom: '25px'}}>
        <img style={{width: '100px', height: '100px'}} className={"occupancy_image_status"} src={"/irj/go/km/docs/WEBSITE_RES/Webresources/Frontend/build//images/occupancy_status_" + props.level +".svg"} alt={GetI18n("tokyo.status.level." + props.level)}/>
        <div className={"occupancy_status_level_"+props.level}>{GetI18n("tokyo.status.level." + props.level)}</div>
    </div>
}

$(document).ready(function(){   
	var dataContainer = $('#occupancySearchData');
	
	if(dataContainer.length){
		var data = JSON.parse(dataContainer.text());
		dataContainer.remove();
	
		ReactDOM.render(
		  React.createElement(TokyoSearch, {data: data}),
		  document.getElementById('occupancySearch')
		);
		module.exports = TokyoSearch;
	}
})