//<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="75ab241c-dc77-4a8c-88c6-4521f1440210" data-blockingmode="auto" type="text/javascript"></script>

//var Utils= require("../Utils");
//$(document).ready(function(){
//	var script=document.createElement('script');
//	script.type='text/javascript';
//	script.src='https://consent.cookiebot.com/uc.js';
//	script.id="Cookiebot";
//	script.setAttribute('data-cbid',"75ab241c-dc77-4a8c-88c6-4521f1440210");
//	script.setAttribute('data-blockingmode',"auto");
//	script.setAttribute('data-culture', Utils.getLanguage());
//	$("head").prepend(script);
//})

//<script src="https://consent.cookiefirst.com/banner.js" data-cookiefirst-key="0fce855e-4bc0-418c-9bb5-205f795a1a97"></script>
//$(document).ready(function(){
//	var script=document.createElement('script');
//	script.type='text/javascript';
//	script.src='https://consent.cookiefirst.com/banner.js';
//	script.setAttribute('data-cookiefirst-key',"0fce855e-4bc0-418c-9bb5-205f795a1a97");
//	$("body").prepend(script);
//})

// CookiesInformation
//<script id="CookieConsent" src="https://policy.app.cookieinformation.com/uc.js" data-culture="FR" type="text/javascript"></script>
//$(document).ready(function(){
//	var script=document.createElement('script');
//	script.type='text/javascript';
//	script.id="CookieConsent";
//	script.src='https://policy.app.cookieinformation.com/uc.js';
//	script.setAttribute('data-culture',Utils.getLanguage().toUpperCase());
//	script.setAttribute('async', true);
//	$("head").prepend(script);
//})
