module.exports = new function NotifcationsBox(){
    var self = this;
    var _moreitems, _readmore, _content, _innerContent;
    var _more = false;
    function _init(){
        _moreitems = $(".box--notifications");
        _readmore = $(".js-notifications-readmore a");
        _content = $(".box--notifications .box__content");
        _innerContent = $(".box--notifications__inner-content");
        _delegate();
    }
    function _delegate(){
        _readmore.on("click", function(e){
            e.preventDefault();
            _more = ! _more;

            if(_more) {
                _moreitems.addClass("box--notifications--more")
            } else {
                _moreitems.removeClass("box--notifications--more")
            }

        })

        _content.on("scroll",function(){
            if(_content.scrollTop() >= (_innerContent.height() - _content.height()-30)){
                _moreitems.addClass("box--notifications--end")
            }else{
                _moreitems.removeClass("box--notifications--end")
            }
        })
    }
    $(document).ready(_init);
    return self;
}