var slick = require("slick-carousel");

module.exports = new function Search() {
    var self = this;
    var _searchTags;

    function _init() {
        _searchTags = $(".js-search-tags");
        _carousel();
    }

    function _carousel() {
        _searchTags.slick({
            slidesToShow: 6,
            slidesToScroll: 6,
            infinite: false,
            responsive: [{
                breakpoint: 960,
                settings: {
                    infinite: false,
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            }, {
                breakpoint: 745,
                settings: {
                    infinite: false,
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            }, {
                breakpoint: 450,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true
                }
            }]
        })
    }

    //   $(document).ready(_init);
    $(document).ready(function(){
        try {
            _init();
        }catch(err){
        }
    });

    return self;
}
