"use strict";
var Request= require("./lines/request");

var SINGLETON={};


/**
 * append to the style passed in parameter each prop of the object if the 
 * value of the attribue is true. If the value of the attribute is a function 
 * the function is called to text if the attribute should be appended or not.
 */
SINGLETON.appendStyles=function(style,obj){
    var ret=style;
    if ( obj && typeof obj === 'object') {
        for(var propt in obj){
            if (typeof obj[propt] == 'function'){
                if ( (obj[propt])() ){
                    ret+=' '+propt;
                }
            } else {
                if (obj[propt]){
                    ret+=' '+propt;
                }
            }
        }
    }

    return ret;
};

SINGLETON.getLanguage=function(){
    if (globalLanguage){
        return globalLanguage;
    } else {
        return Request.getParameter("l","fr");
    }
};

SINGLETON.getHoraireDecalage=function(){
    if (globalHoraireDecalage){
        return globalHoraireDecalage;
    } else {
        return -4;
    }
};

SINGLETON.getHoraireServiceUri=function(){
    return globalHoraireServiceUri;
};

SINGLETON.getHoraireUri=function(){
    return globalHoraireUri;
};

SINGLETON.getNewsUri=function(){
    return globalNewsUri;
};

SINGLETON.getNewsUrl=function(sonumber,lang){
    // Uri: ?l=fr&news_rid=osppt130223
    // ?_rid=osppt130223&l=fr
    var ret=SINGLETON.getUrlPrefixUri()+
        SINGLETON.getNewsUri()+
        '?_rid=osppt'+sonumber+'&l='+lang;
    
    return ret;
};

SINGLETON.getNetworkMessagesUri=function(){
    return globalNetworkMessagesUri;
};

SINGLETON.getUrlPrefixUri=function(){
    return globalUrlPrefixUri;
};

SINGLETON.getArtigleUri=function(guid){
    return globalUrlPrefixUri+globalArticleUri+"?l="+SINGLETON.getLanguage()+"&_guid="+guid;
};

SINGLETON.getUri=function(uri){
    return globalUrlPrefixUri+uri+"?l="+SINGLETON.getLanguage();
};

/**
 * To render unescaped html: <div dangerouslySetInnerHTML={SINGLETON.renderHTML("<h1>TOTO</h1>")} />
 *
 * Compiled: React.createElement("div", {dangerouslySetInnerHTML: Utils.renderHTML(settings.info)})
 *
 */
SINGLETON.renderHTML=function(content){
    return {__html: content};
}

module.exports = SINGLETON
