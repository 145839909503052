var proto = {
    facebook: function facebook() {
        var o = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        var url = o.url;
        var outputUrl = 'https://facebook.com/sharer.php?s=100';

        url = url ? url : 'http://' + window.location.host;

        outputUrl += '&u=' + encodeURIComponent(url);

        window.open(outputUrl, 'Facebook', 'width=626,height=436');
    },
    linkedin: function linkedin() {
        var o = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        var url = o.url;
        var outputUrl = 'https://www.linkedin.com/shareArticle?mini=true';

        url = url ? url : 'http://' + window.location.host;

        outputUrl += '&url=' + encodeURIComponent(url);

        window.open(outputUrl, 'LinkedIn', 'width=626,height=436');
    },
    twitter: function twitter() {
        var o = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        var url = o.url;
        var text = o.text;
        var hashtags = o.hashtags;
        var outputUrl = 'https://www.twitter.com/share?';

        url = url ? url : 'http://' + window.location.host;

        outputUrl += 'url=' + encodeURIComponent(url);

        if (text) outputUrl += '&text=' + encodeURIComponent(text);

        if (hashtags) {
            var compiledTags = hashtags;

            if (Object.prototype.toString.call(hashtags) === '[object Array]') {
                compiledTags = hashtags.join(',');
            }

            outputUrl += '&hashtags=' + encodeURIComponent(compiledTags);
        }

        outputUrl += '&wrap_links‌​=true';

        window.open(outputUrl, 'Twitter', 'width=626,height=436');
    }
};

$(document).ready(function() {
    var share = Object.create(proto);

    $('.js-share-facebook').on('click', function (e) {
        share.facebook({
            url: $(e.currentTarget).data('share-url')
        });
    });

    $('.js-share-linkedin').on('click', function (e) {
        share.linkedin({
            url: $(e.currentTarget).data('share-url')
        });
    });

    $('.js-share-twitter').on('click', function (e) {
        share.twitter({
            url: $(e.currentTarget).data('share-url'),
            text: $(e.currentTarget).data('share-text')
        });
    });
});
