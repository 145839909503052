
var getI18n = require("./I18n");

module.exports = new function Gallery(){
//++ 20190405 Content gallery

	function StibGallery(parent){
			
		
		var self = this;
		var gallery = [];
		var currentIndex = 0;
		var gallerySize = 0;
		var isInit = false;
		var showBlock = $(parent).find('.content-gallery-show-block');
		var activeImage = $(showBlock).find('img');
		var caption = $(parent).find('.content-gallery-caption');
		var captionContent = $(caption).find('span');
		var activeImageUrl;
		
		$(activeImage).click(function(){
			window.open(activeImageUrl);
		});
		
		this.init = function(){
			if (gallery.length > 0){			
				render();
			}
		}
		
		function render(index){		
			if(!isInit){			
				init();
			}
			
			currentIndex = index === undefined ? currentIndex : index;
			
			activeImageUrl = gallery[currentIndex].image;
			activeImage.attr('src', activeImageUrl);
			if (gallery[currentIndex].caption && gallery[currentIndex].caption !== ""){
				$(caption).addClass('content-gallery-caption-active');
				$(caption).find('.curve').show();
				$(captionContent).text(gallery[currentIndex].caption);		
			} else {
				$(caption).removeClass('content-gallery-caption-active');
				$(caption).find('.curve').hide();
				$(captionContent).text("");
			}
	
		}
		
		/* image
		 * image: "url",
		 * thumbnail: "url",
		 * caption: "content"
		 * 
		*/
		this.add = function(image){
			gallery.push(image);
			image.element.click(function(){
				render(image.index);
			});
			
		}
		
		var next = function(){
			changeImage(true);
		};
		
		var previous = function(){
			changeImage(false);
		}
		
		function init(){
			
			gallerySize = gallery.length;
			
			if (gallery.length > 1){
				var prevButton = document.createElement('button');
				$(prevButton).addClass('slick-prev slick-arrow');
				$(prevButton).attr('aria-label',getI18n('sliderButton.previous'));
				$(prevButton).bind('click', previous);
				var nextButton = document.createElement('button');
				$(nextButton).addClass('slick-next slick-arrow');
				$(nextButton).attr('aria-label',getI18n('sliderButton.next') );
				$(nextButton).bind('click', next);
				$(showBlock).append(prevButton);
				$(showBlock).append(nextButton);
			}
			
			isInit = true;
		}
		
		var changeImage = function(increment){
			changeIndex(increment);
			render();
		}
		
		var changeIndex = function(increment){
			if (increment){
				if (currentIndex === gallerySize - 1){
					currentIndex = 0;
				} else {				
					currentIndex++
				}
			} else {
				if (currentIndex === 0){
					currentIndex = gallerySize - 1;
				} else {
					currentIndex--;
				}
			}
		}	
		
	}

	$(document).ready(function(){
		
		$(".content-gallery-container").each(function(index, container){
			var ctner = $(container);
			var gallery = new StibGallery(ctner);
	
			$(ctner).children(".content-gallery").children('div').each(function(idx, parent){
				
				var image = $(parent).find('img');
				var caption = $(parent).children('div');
				
				gallery.add({
					image:$(image).attr("src"),
					caption: $(caption).text(),
					index: idx,
					element: $(parent)
				});
			});
			gallery.init();
		})
		
	});
}