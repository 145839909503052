"use strict";
var Reflux = require("reflux");
var Actions = Reflux.createActions([
    "showLineList",
    "showLine",
    "showError",
    "showRealTime",
    "sendHttpError"
]);
module.exports = Actions;
