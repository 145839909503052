/**
 * Scroll to an element
 */
"use strict";

var SINGLETON={};

var top,left,bottom,right,width,height;

function init(){
	var win=$(window);
	if (win){
		top = win.scrollTop();
		left = win.scrollLeft();
		
		height=win.height();
		
		width=win.width();
		
		bottom = top + height;
		right = left + width;
        return true;
    } else {
        return false;
	}
}

// returns true if the element is INSIDE the view port
SINGLETON.containsElement=function(el){
	var e=$(el);
	if (e.length>0){
		var offset = e.offset();
		var etop=offset.top;
		var eleft=offset.left;
		
		if ( etop < top ||						 
			 eleft < left ||
			 (etop+e[0].offsetHeight) > bottom ||						 
			 (eleft+e[0].offsetWidth) > right ){
			
			return false;
		} else {
			return true;
		}						 
	} else {
		return false;
	}
};

// returns true if the element is INTERSESCT the view port
SINGLETON.intersectElement=function(el){
	var e=$(el);
	if (e.length>0){
		var offset = e.offset();
		var etop=offset.top;
		var eleft=offset.left;
		// element square (etop,eleft,etop+e[0].offsetHeight,eleft+e[0].offsetWidth)
		// window square (top,left,bottom,right)
		if ( eleft+e[0].offsetWidth < left ||						 
			 right < eleft ||
			 (etop+e[0].offsetHeight) < top ||						 
			 bottom < etop   ){
			
			return false;
		} else {
			return true;
		}						 
	} else {
		return false;
	}
};

SINGLETON.getVerticalCenterScroll=function(el){
	var e=$(el);
	if (e.length>0){
		var offset = e.offset();
		var etop=offset.top;
		var eHight=e[0].offsetHeight;
		if ( etop < top ||						 
			 (etop+eHight) > bottom){
			
			// element center
			var ec=etop+(eHight/2);
			// window Center
			var wc=top+(height/2);
			
			var scrollDistance=(ec-wc);
            
			// log.info( "scrool distance:"+scrollDistance);
            
			return scrollDistance;
		} else {
			return 0;
		}						 
	} else {
		return 0;
	}
};

SINGLETON.getVerticalTopScroll=function(el){
	var e=$(el);
	if (e.length>0){
		var offset = e.offset();
		var etop=offset.top;
		var eHight=e[0].offsetHeight;
		if ( etop < top ||						 
			 (etop+eHight) > bottom){
			
			// element top center
			var ec=etop;
			// window top 
			var wc=top;
			
			var scrollDistance=(ec-wc);
            
			// log.info( "scrool distance:"+scrollDistance);
            
			return scrollDistance;
		} else {
			return 0;
		}						 
	} else {
		return 0;
	}
};


SINGLETON.scrollToElement=function(el,scrollTime){
	var e=$(el);
	if (e.length>0){
        if (init()){

            if (SINGLETON.containsElement(e)){
//            if (SINGLETON.containsElement(e) || SINGLETON.intersectElement(e)){
                return ;
            } else {
//                var distance=SINGLETON.getVerticalCenterScroll(e);
                var distance=SINGLETON.getVerticalTopScroll(e);
                
                if (distance === 0) {
			        return ;
		        } else {
			        // execute the scroll
			        $('html, body').animate({
				        scrollTop: top+distance
			        }, (scrollTime)?scrollTime:200);						            
                }
            }
        }
    }
};


module.exports = SINGLETON;
